import React from 'react';
//import {Dashtop} from './components/dashtop'
import { Dashleft } from '../components/dashleft';
import { Discovery } from '../components/discovery';

export const Discover = () => {
  return (
    <div className="dashboard">
      <Dashleft id={3} />
      <Discovery />
    </div>
  );
};

/*
  Packages
*/
import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

/*
  Assets
*/
import signOut from '../images/logout.svg';
import megaphone1 from '../images/megaphone1.svg';
import messenger from '../images/messenger.svg';
import { ReactComponent as Megaphone} from '../images/megaphone.svg';
import { ReactComponent as Messenger} from '../images/messenger1.svg';
import blueGear from '../images/blue_gear.svg';
import { ReactComponent as Settings} from '../images/black_gear.svg';
import { ReactComponent as Home} from '../images/home.svg';
import homeBlue from '../images/homeBlue.svg';
import tasks from '../images/tasksBlack.svg';
import compassBlack from '../images/compassBlack.svg';
import { ReactComponent as Mac} from '../images/mac.svg';
import macBlack from '../images/macBlack.svg';
import { ReactComponent as Compass} from '../images/compassBlue.svg';
import { ReactComponent as Tasks} from '../images/tasksBlue.svg';
import {LuCompass} from 'react-icons/lu'
import {RiSettings5Fill} from 'react-icons/ri'
import {AiOutlineMessage} from 'react-icons/ai'
export const Dashleft = ({ id }) => {
  // const [megaPhone, setMegaPhone] = React.useState(megaphone);
  // const [messengerImg, setMessengerImg] = React.useState(messenger);
  // const [megaFlag, setMegaFlag] = React.useState(0);
  // const [messengerFlag, setMessengerFlag] = React.useState(0);
  // const [hut, setHut]= React.useState(home);
  // const [hutFlag, setHutFlag] = React.useState(false);

  //   const handleHutFlag = () => {
  //       if(hutFlag=== false){
  //         setHutFlag(true);
  //         setMegaFlag(0);
  //         setMessengerFlag(0);
  //         setMessengerImg(messenger);
  //         setMegaPhone(megaphone);
  //         setHut(homeBlue)
  //       }
  //   }

  //   const handleMessengerFlag = () =>{
  //       if(messengerFlag=== 0 ){
  //           setHutFlag(false);
  //           setMegaFlag(0);
  //           setMessengerFlag(1);
  //           setMessengerImg(messenger1);
  //           setMegaPhone(megaphone);
  //           setHut(home)
  //       }
  //   }

  //   const handleMegaFlag = () =>{
  //     if(megaFlag=== 0 ){
  //         setHutFlag(false);
  //         setMegaFlag(1);
  //         setMessengerFlag(0);
  //         setMessengerImg(messenger);
  //         setMegaPhone(megaphone1);
  //         setHut(home)
  //     }

  // }

  const handleSignOut = () => {
    Cookies.remove('token'); // Clear cookie
    window.location.href = '/'; // Redirect to login page
  };

  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <div className='dashleft'>
      <div className='head-left'>TeleMon</div>
      <div className='div-left-navs'>
        <div className='top-btns'>
          <Link to='/home' style={{ textDecoration: 'none', color: '#000000' }}>
            {' '}
            <div className='options'>
              <button className={`navs ${id === 0 && 'active'}`}>
              <Home stroke={id ===0 ?'#00ACFB':'black'}></Home>
                <span>Analytics</span>
                
              </button>
            </div>{' '}
          </Link>
          <Link
            to='/channels'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            {' '}
            <div className='options'>
              <button className={`navs ${id === 1 && 'active'}`}>
              <Megaphone stroke={id ===1 ?'#00ACFB':'black'} ></Megaphone>
                <span>Channel Gallery</span>
              </button>
            </div>
          </Link>
          <Link
            to='/messages'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            {' '}
            <div className='options'>
              <button className={`navs ${id === 2 && 'active'}`}>
               <AiOutlineMessage  color={id ===2 ?'#00ACFB':'black'} size={24}></AiOutlineMessage>
                <span>Message Center</span>
              </button>
            </div>
          </Link>
          <Link
            to='/discover'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            {' '}
            <div className='options'>
              <button className={`navs ${id === 3 && 'active'}`}>
                <LuCompass color={id ===3 ?'#00ACFB':'black'} size={24}></LuCompass>
                <span>Discover Channels</span>
              </button>
            </div>
          </Link>
          <Link
            to='/message_monitor'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            {' '}
            <div className='options'>
              <button className={`navs ${id === 4 && 'active'}`}>
                <Mac fill={id ===4 ?'#00ACFB':'black'}></Mac>
                <span>Monitor Messages</span>
              </button>
            </div>
          </Link>
          <Link
            to='/notifications'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            {' '}
            <div className='options'>
              <button className={`navs ${id === 5 && 'active'}`}>
                <Tasks stroke={id ===5 ?'#00ACFB':'black'} fill={id ===5 ?'#00ACFB':'black'}></Tasks>
                <span>Task Status</span>
              </button>
            </div>
          </Link>
          <Link
            to='/settings'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            {' '}
            <div className='options'>
              <button className={`navs ${id === 6 && 'active'}`}>
              <RiSettings5Fill color={id ===6 ?'#00ACFB':'black'} size={30}></RiSettings5Fill>
                <span style={{marginTop:'4px'}}>Settings</span>
              </button>
            </div>
          </Link>
        </div>
        <div>
          <h5 className='userName'>
            Hello, {Cookies.get('user_name') ?? 'User'}
          </h5>
          <button
            className='logout-btn'
            onClick={(event) => {
              event.preventDefault();
              handleSignOut();
            }}
          >
            {' '}
            <img alt='sign out' className='signoutSVG' src={signOut} /> Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

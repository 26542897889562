
const digest = '229ae2677d18f2b9e005805dac5fd92def4293312086eb8b587aa18435e26b80';
const css = `._inputElement_jafxa_1,
._inputLabel_jafxa_3 {
	width: 100%;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"inputElement":"_inputElement_jafxa_1","inputLabel":"_inputLabel_jafxa_3"};
export { css, digest };
  
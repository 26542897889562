import axios from 'axios'
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import instance from './interceptor';

export const blacklisting_channel = async(data)=>{
  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  // const secretKey = 'some_secret_key';
  // const ciphertext = Cookies.get('token')
  // const bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), secretKey);    
  // const token = bytes.toString(CryptoJS.enc.Utf8); 
  // apiHeaders["x-access-token"] = token
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders,
  };
 

  let res = []
console.log(data);
console.log('hiting endpoint black listing channel');
    await instance.post(`${apiUrl}/api/channels/updateConcernType`, data, config)
    .then(response=>{
      console.log('hit');
        res = response.data;
        console.log(response.data)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }
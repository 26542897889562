/*
  Packages
*/
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { Collapse } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import Box from '@mui/material/Box';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import copy from 'copy-to-clipboard';
import { Button } from '@mui/material';
/*
  Api
*/
import { message_call } from '../endpoints/endpoint3';
import { tag_update } from '../endpoints/message_tags_update';
import forward from '../images/forwarded.svg';
import Edited from '../images/Edited.svg';

import { multiple_tag_update } from '../endpoints/multiple_message_tags_update';
import { export_messages } from '../endpoints/export_messages';
import { download_media } from '../endpoints/download_media';
import { channel_info } from '../endpoints/channel_info';

/*
  Assets
*/

import pin from '../images/pin.svg';
import download_icon from '../images/download_icon.png';
import noData from '../images/no-search-found.svg';
import loadingImg from '../images/loading.svg';
import tele_link from '../images/teleLink.svg';
import { message_info } from '../endpoints/message_info';
import readIcon from '../images/readIcon.svg';
import unreadIcon from '../images/single_unread.svg';
import { read_unread_update } from '../endpoints/setReadUnread';
import { Forward } from '@mui/icons-material';
import Notes from '../images/notefiles.svg';
import refresh_btn from '../images/refresh.svg';
import InstructionPopUp from '../images/instrucitoneye.svg';
import CLoudDownload from '../images/cloud_download.svg';
import clearFiltersIcon from '../images/clear-filter.png';
import { join_channel } from '../endpoints/channel_join';

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return dateObject.toLocaleString('en-US', options);
};

const popupStyle = {
  width: '35%',
  height: `fit-content`,
  borderRadius: '8px',
  backgroundColor: 'white',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '999',
  padding: '20px',
  boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.75)',
  boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.75)',
};

export const MessageTable = () => {
  const [keywords, setKeywords] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pc, setPc] = React.useState(1);
  const [det, setDet] = React.useState(10);
  const [totCount, setTotCount] = React.useState(4000);
  const [sample_rows, setSample_rows] = React.useState([]);
  const [check, setCheck] = React.useState([]);
  const [fin_tags, setFin_tags] = React.useState([]);
  const [entries, setEntries] = React.useState(10);
  const [openCard, setOpenCard] = React.useState([false, false, false]);
  const [checkboxFilter, setCheckboxFilter] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const defaultFromDate = moment().subtract(122, 'years').startOf('year');
  const initialFromDate = defaultFromDate.format('YYYY-MM-DD');
  const defaultToDate = moment().add(77, 'years').endOf('year');
  const initialToDate = defaultToDate.format('YYYY-MM-DD');
  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [fromDateCopy, setFromDateCopy] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);
  const [toDateCopy, setToDateCopy] = React.useState(initialToDate);
  const [dateFlag, setDateFlag] = React.useState(0);
  const [messageData, setmessageData] = React.useState([]);
  const [metaData, setmetaData] = React.useState([]);
  const [channelCard, setChannelCard] = React.useState(false);
  const [channelData, setChannelData] = React.useState();
  const [checkboxesState, setCheckboxesState] = React.useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const channelId = searchParams.get('channelId');
  const searchQuery = searchParams.get('searchQuery');
  const querySearchFlag = searchParams.get('querySearchFlag');
  const dbRefId = searchParams.get('messageRefId');
  // console.log(channelId, searchQuery, dbRefId, searchParams)
  const [updated_tags, setUpdated_tags] = React.useState();
  const [showEditTagsPopup, setShowEditTagsPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [key, setKey] = React.useState();
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const [selectCount, setSelectCount] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const [or_toggle, setOr_toggle] = React.useState(false);
  const [selectAllPopup, setSelectAllPopup] = React.useState(false);
  const [selectedMessages, setSelectedMessages] = React.useState([]);
  const [multipleMessageTags, setMultipleMessageTags] = React.useState([]);
  const [showEditTagsPopup2, setShowEditTagsPopup2] = React.useState(false);
  // const [checkboxes, setCheckboxes] = React.useState([])
  // const [exportJobId, setExportJobId] = React.useState();
  const scrollref = React.useRef(null);
  const [exportedPop, setExportedPop] = React.useState(false);
  const [downloadPop, setDownloadPop] = React.useState(false);
  const [downloadRowSelected, setDownloadRowSelected] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [notes, setNotes] = React.useState('');
  const [selectedQuery, setSelectedQuery] = React.useState();
  const [forwardedPop, setForwardedPop] = React.useState(false);
  const [forwardedRow, setForwardedRow] = React.useState();
  const [messageDetails, setMessageDetails] = React.useState();
  const [messageDetailsPop, setMessageDetailsPop] = React.useState(false);
  const [exportNote, setExportNote] = React.useState('');
  const [mentionsData, setMentionsData] = React.useState();
  const [mentionsPop, setMentionsPop] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [readUnread, setReadUnread] = React.useState([]);
  const [showMarkReadOption, setShowMarkReadOption] = React.useState(false);
  const [ShowMarkAsReadPop, setShowMarkAsReadPop] = React.useState(false);
  const [exportSuccessfull, setexportSuccessfull] = React.useState(false);
  const [forwardDetailsPopUp, setforwardDetailsPopUp] = React.useState(false);
  const [selectionRange, setSelectionRange] = React.useState({
    starDate: moment().subtract(122, 'years').startOf('year'),
    endDate: moment().add(77, 'years').endOf('year'),
    key: 'selection',
  });
  const [instructionPopUp, setinstructionPopUp] = React.useState(false);
  const [historyPopUp, setHistoryPopUp] = React.useState(false);
  const [historyDialogData, setHistoryDialogData] = React.useState();
  const [downloadPopUp, setDownloadPopUp] = React.useState(false);
  const [selectedJoinChannel, setSelectedJoinChannel] = React.useState();
  const [selectedJoinChannelIndex, setSelectedJoinChannelIndex] =
    React.useState();
  const [importPop, setImportPop] = React.useState(false);
  const [showJoinPop, setShowJoinPop] = React.useState(false);
  const [dateSelectedBool, setDateSelectedBool] = React.useState(false);
  const [displayDate, setDisplayDate] = React.useState('Select Date Range');
  const dropdownRef = React.useRef(null);
  const filterbuttonRef = React.useRef(null);
  const [forwardedData, setFowardedData] = React.useState();
  const [delete_pop, setDelete_pop] = React.useState(false);

  const [downloadMediaCheck, setDownloadMediaCheck] = React.useState(false);
  const [downloadMediaCheckAll, setDownloadMediaCheckAll] =
    React.useState(false);
  const [excelCheck, setExcelCheck] = React.useState(false);

  const [visibleChannel, setVisibleChannel] = React.useState(false);
  const [makeChannelVisible, setMakeChannelVisible] = React.useState('');

  // const [jsonRow, setJsonRow] = React.useState({})

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setDateSelectedBool(!dateSelectedBool);
    console.log('dateSelectedBool: ', dateSelectedBool);
  };
  const handleKeyAdd = (event) => {
    event.preventDefault();
    // setToggleAddKeys(!showEditTagsPopup);
  };

  //function to export messages
  const handleExport = async (event) => {
    console.log(downloadMediaCheck, downloadMediaCheckAll);
    event.preventDefault();
    const arr = selectedMessages;
    setSelectedKeys([]);
    const t = handleFinalKeys();
    setExportedPop(false);
    let message = {};
    if (!selectedQuery) {
      message = {
        messages_db_ref_ids: arr,
        notes: exportNote,
        media_download_image: downloadMediaCheck,
        media_download: downloadMediaCheckAll,
        is_excel: excelCheck,
      };
    } else {
      message = {
        messages_db_ref_ids: arr,
        query: selectedQuery,
        notes: exportNote,
        media_download_image: downloadMediaCheck,
        media_download: downloadMediaCheckAll,
        is_excel: excelCheck,
      };
    }

    // setSelectedQuery();
    setExportNote('');
    setDownloadMediaCheck(false);
    setDownloadMediaCheckAll(false);
    console.log('sending request for exporting: ', message);
    let r;
    r = await export_messages(message);
    //display message in react toastify of the response status
    if (r?.error?.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! job_id: ' + r.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    // window.location.reload();
    console.log('export response in page :', r);

    //re rendering page to update.
    console.log('here is the export response: ', r);
    // let m = {
    //   attributes: metaData,
    //   pageNo: page,
    //   limitPerPage: entries,
    //   searchText: t,
    //   tags: messageData,
    //   // "flag": dateFlag,
    //   fromDate: fromDate,
    //   toDate: toDate,
    //   or_toggle: or_toggle,
    // };

    // let response = {};
    // response = await message_call(m);
    // let chan = response.dbRes.data;
    // // const { chan, pCount, tot } = response;

    // setPc(Math.ceil(response.dbRes.totalCount / entries));
    // setTotCount(response.dbRes.totalCount);
    // setDet(entries);

    // let test_rows = [];
    // for (let i = 0; i < chan.length; i++) {
    //   test_rows.push(
    //     createData(
    //       chan[i].text_content,
    //       chan[i].tags,
    //       chan[i].updated_at,
    //       chan[i].meta_data.sender_id,
    //       chan[i].message_id,
    //       chan[i].entity_name,
    //       chan[i].attributes.mobile_numbers,
    //       chan[i].attributes.mentions,
    //       chan[i].attributes.urls,
    //       chan[i].forwarded_details.is_forwarded,
    //       chan[i].pinned,
    //       chan[i].attached_media,
    //       chan[i].attributes.email_ids,
    //       chan[i].attributes.urls,
    //       chan[i].message_db_ref_id,
    //       chan[i].entity_user_name
    //     )
    //   );
    // }
    // setSample_rows(test_rows);
  };

  // const handleSelectCurrent = (event)=>{

  // 	console.log('sample_row: ', sample_rows)
  // 	for(let i=0;i<sample_rows.length;i++){
  // 		console.log('sample rows ref id', sample_rows[i].ref_id);
  // 		setSelectedMessages([...selectedMessages, sample_rows[i].ref_id]);
  // 	}

  // 	console.log('selectedmessages id', selectedMessages);
  // }

  const handleSelectCurrent = (event) => {
    setShowMarkReadOption(true);
    setSelectedMessages([]);
    // Loop over the sample_rows array
    for (let i = 0; i < sample_rows?.length; i++) {
      // Add the ref_id to the selectedMessages array
      setSelectedMessages((prevSelectedMessages) => [
        ...prevSelectedMessages,
        sample_rows[i].ref_id,
      ]);
    }

    console.log('selectedMessages: ', selectedMessages);
  };

  //select all messages of a query
  const handleSelectThroughAll = () => {
    setShowMarkReadOption(false);
    setSelectedMessages([]);
    let arr = [];
    for (let i = 0; i < selectedOptions?.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    let message = {
      attributes: metaData,
      searchText: arr,
      tags: messageData,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        entity_id: channelId,
        attributes: metaData,
        searchText: arr,
        tags: messageData,
        fromDate: fromDate,
        toDate: toDate,
        or_toggle: or_toggle,
      };
    }
    setSelectedQuery(message);
  };

  //function to upload media to aws

  const handleDownload = async (event) => {
    event.preventDefault();

    const t = handleFinalKeys();
    console.log('here is the media row selected: ', downloadRowSelected);
    const message = {
      message_id: downloadRowSelected._id,
      entity_id: downloadRowSelected.id,
      notes: notes,
    };

    console.log('sending request for exporting: ', message);
    let r;
    r = await download_media(message);
    if (r?.error?.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! job_id: ' + r.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    // window.location.reload();
    console.log('export response in page :', r);

    console.log('here is the export response: ', r);
    // let m = {
    //   attributes: metaData,
    //   pageNo: page,
    //   limitPerPage: entries,
    //   searchText: t,
    //   tags: messageData,
    //   // "flag": dateFlag,
    //   fromDate: fromDate,
    //   toDate: toDate,
    //   or_toggle: or_toggle,
    // };

    // let response = {};
    // response = await message_call(m);
    // let chan = response.dbRes.data;
    // // const { chan, pCount, tot } = response;

    // setPc(Math.ceil(response.dbRes.totalCount / entries));
    // setTotCount(response.dbRes.totalCount);
    // setDet(entries);

    // let test_rows = [];
    // for (let i = 0; i < chan.length; i++) {
    //   test_rows.push(
    //     createData(
    //       chan[i].text_content,
    //       chan[i].tags,
    //       chan[i].updated_at,
    //       chan[i].meta_data.sender_id,
    //       chan[i].message_id,
    //       chan[i].entity_name,
    //       chan[i].attributes.mobile_numbers,
    //       chan[i].attributes.mentions,
    //       chan[i].attributes.urls,
    //       chan[i].forwarded_details.is_forwarded,
    //       chan[i].pinned,
    //       chan[i].attached_media,
    //       chan[i].attributes.email_ids,
    //       chan[i].attributes.urls,
    //       chan[i].message_db_ref_id
    //     )
    //   );
    // }
    // setSample_rows(test_rows);
  };

  const handleCheckboxChange = (event, row) => {
    const { checked } = event.target;
    const messageId = row?.ref_id;

    if (checked) {
      // Add the messageId to the selectedMessages array
      setSelectedMessages([...selectedMessages, messageId]);
    } else {
      // Remove the messageId from the selectedMessages array
      setSelectedMessages(selectedMessages.filter((id) => id !== messageId));
    }

    console.log('selectedMessages', selectedMessages);

    setEdit(true);
  };

  const handleUpdateTagsMultiple = async (event) => {
    event.preventDefault();
    const tags = selectedKeys;

    const arr = selectedMessages;
    setSelectedKeys([]);
    setKey();
    setSelectedMessages([]);
    setShowEditTagsPopup2(false);
    const t = handleFinalKeys();

    let message = {};
    if (!selectedQuery) {
      message = {
        tags: tags,
        messages_db_ref_ids: arr,
      };
    } else {
      message = {
        tags: tags,
        query: selectedQuery,
      };
    }
    setSelectedQuery();

    console.log('sending request for updating multiple tags : ', message);
    let r;
    r = await multiple_tag_update(message);
    // window.location.reload();
    if (r?.error?.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! job_id: ' + r.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }

    let m = {
      attributes: metaData,
      pageNo: page,
      limitPerPage: entries,
      searchText: t,
      tags: messageData,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };

    let response = {};
    response = await message_call(m);

    let chan = response?.dbRes?.data;
    // const { chan, pCount, tot } = response;

    setPc(Math.ceil(response?.dbRes?.totalCount / entries));
    setTotCount(response?.dbRes?.totalCount);
    setDet(entries);

    let test_rows = [];
    for (let i = 0; i < chan?.length; i++) {
      test_rows.push(
        createData(
          chan[i]?.text_content,
          chan[i]?.tags,
          chan[i]?.message_sent_at,
          chan[i]?.meta_data?.sender_id,
          chan[i]?.message_id,
          chan[i]?.entity_name,
          chan[i]?.attributes?.mobile_numbers,
          chan[i]?.attributes?.mentions,
          chan[i]?.attributes?.urls,
          chan[i]?.forwarded_details?.is_forwarded,
          chan[i]?.pinned,
          chan[i]?.attached_media,
          chan[i]?.attributes?.email_ids,
          chan[i]?.attributes?.urls,
          chan[i]?.message_db_ref_id,
          chan[i]?.forwarded_details,
          chan[i]?.entity_user_name,
          chan[i]?.updated_at,
          chan[i]?.is_seen,
          chan[i]?.is_deleted,
          chan[i]?.is_edited,
          chan[i]?.content_history,
          chan[i]?.entity_banned,
          chan[i]
        )
      );
    }
    setSample_rows(test_rows);
  };

  const handleUpdateTags = (event) => {
    event.preventDefault();
    const tags = selectedKeys;
    console.log(selectedRow);
    const row = selectedRow;
    setSelectedKeys([]);
    setKey();
    setSelectedRow(null);
    setShowEditTagsPopup(false);
    const t = handleFinalKeys();

    const message = {
      tags: tags,
      entity_id: row.id,
      message_id: row._id,
    };

    call(message);
  };

  //function to update tag and re render the page
  const call = async (message) => {
    console.log('working', location.pathname);
    let r = {};
    r = await tag_update(message);
    // window.location.reload();

    let c = r.dbRes.data;
    // const { chan, pCount, tot } = response;
    if (dbRefId) {
      window.location.reload();
    } else {
      const t = handleFinalKeys();

      let m = {
        attributes: metaData,
        pageNo: page,
        limitPerPage: entries,
        searchText: t,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        or_toggle: or_toggle,
      };

      if (channelId) {
        m['entity_id'] = channelId;
      }
      let response = {};
      response = await message_call(m);
      let chan = response?.dbRes?.data;
      // const { chan, pCount, tot } = response;

      setPc(Math.ceil(response?.dbRes?.totalCount / entries));
      setTotCount(response?.dbRes?.totalCount);
      setDet(entries);

      let test_rows = [];
      for (let i = 0; i < chan?.length; i++) {
        test_rows.push(
          createData(
            chan[i]?.text_content,
            chan[i]?.tags,
            chan[i]?.message_sent_at,
            chan[i]?.meta_data.sender_id,
            chan[i]?.message_id,
            chan[i]?.entity_name,
            chan[i]?.attributes.mobile_numbers,
            chan[i]?.attributes.mentions,
            chan[i]?.attributes.urls,
            chan[i]?.forwarded_details.is_forwarded,
            chan[i]?.pinned,
            chan[i]?.attached_media,
            chan[i]?.attributes.email_ids,
            chan[i]?.attributes.urls,
            chan[i]?.message_db_ref_id,
            chan[i]?.forwarded_details,
            chan[i]?.entity_user_name,
            chan[i]?.updated_at,
            chan[i]?.is_seen,
            chan[i]?.is_deleted,
            chan[i]?.is_edited,
            chan[i]?.content_history,
            chan[i]?.entity_banned,
            chan[i]
          )
        );
      }
      console.log('working all fine here');
      setSample_rows(test_rows);
    }
  };

  const handleChange = (event) => {
    setKey(event.target.value);
    console.log(event.target.value);
  };

  const handleExportChange = (event) => {
    setExportNote(event.target.value);
    console.log(event.target.value);
  };

  const handleAddKeys = (event, val) => {
    event.preventDefault();
    if (selectedKeys.indexOf(val.trim()) === -1) {
      setSelectedKeys([...selectedKeys, val.trim()]);
      setSelectCount(selectCount + 1);
    }
    setKey('');
  };

  const handleAddKeysMultiple = (event, val) => {
    event.preventDefault();
    if (selectedKeys.indexOf(val.trim()) === -1) {
      setSelectedKeys([...selectedKeys, val.trim()]);
      setSelectCount(selectCount + 1);
    }
    setKey('');
  };

  const handleRemoveKeys = (event, val) => {
    event.preventDefault();
    console.log(val);
    setSelectedKeys(
      selectedKeys.filter((selectedKeys) => selectedKeys !== val.key)
    );
    setSelectCount(selectCount - 1);
  };

  const style = {
    width: 750,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingInline: 8,
    paddingTop: 4,
    paddingBottom: 4,
  };

  const style_select = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingInline: 2,
    paddingTop: 1,
    paddingBottom: 2,
    margin: 'auto',
    height: 'fit-content',
  };

  const handleEditTagsClick = (row) => {
    setSelectedKeys(row?.tags);
    console.log(row?.date);
    setSelectedRow(row);
    setShowEditTagsPopup(true);
  };

  const handleSaveTagsClick = (updatedTags) => {
    // Update the tags for the selectedRow
    const updatedRow = { ...selectedRow, tags: updatedTags };
    // Update the state of the table with the updatedRow
    // ...
    setSelectedRow(null);
    setShowEditTagsPopup(false);
  };

  const [tags, setTags] = React.useState([]);

  const handleTagClick = (sentTag) => {
    if (dbRefId) {
      console.log('working', location.pathname);
      navigate(location.pathname);
    }
    // setSelectedOptions([]);
    console.log('in selected tag selected option : ', selectedOptions);

    let arr = [];
    for (let i = 0; i < selectedOptions?.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    setKeywords([...arr, keywords]);

    setmessageData(sentTag);

    setPage(1);
    setLoading(true);
    console.log('tag selected nw selectedOption : ', selectedOptions);
    let message = {
      attributes: metaData,
      pageNo: 1,
      limitPerPage: entries,
      searchText: arr,
      tags: [sentTag],
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        entity_id: channelId,
        attributes: metaData,
        pageNo: 1,
        limitPerPage: entries,
        searchText: arr,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        or_toggle: or_toggle,
      };
    }
    test_c(message);
  };
  React.useEffect(() => {
    if (scrollref.current) {
      scrollref.current.scrollTop = 0; // px
    }
  }, [scrollref.current]);
  // handle searchbar option select
  const handleSelectChange = (selectedOptions) => {
    if (dbRefId) {
      console.log('working', location.pathname);
      navigate(location.pathname);
    }
    setSelectedOptions(selectedOptions);
    console.log(selectedOptions);

    let arr = [];
    for (let i = 0; i < selectedOptions?.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    setKeywords([...arr, keywords]);
    setPage(1);
    setLoading(true);
    let message = {
      attributes: metaData,
      pageNo: 1,
      limitPerPage: entries,
      searchText: arr,
      tags: messageData,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        entity_id: channelId,
        attributes: metaData,
        pageNo: 1,
        limitPerPage: entries,
        searchText: arr,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        or_toggle: or_toggle,
      };
    }
    test_c(message);
  };
  const downloadJsonFile = (bucketId) => {
    // const cloudfrontLink = `${process.env.REACT_APP_CLOUDFRONT_URL}/${bucketid}`;

    // fetch(cloudfrontLink)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const jsonFile = new Blob([blob], { type: 'application/json' });
    //     FileSaver.saveAs(jsonFile, 'your-file-name.json');
    //   })
    //   .catch(error => console.log(error));ma_update

    const filename = bucketId.split('/')[1];
    const url = `${process.env.REACT_APP_CLOUDFRONT_URL}/${bucketId.replace(
      ' ',
      '%20'
    )}`;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    console.log(url);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleInputChange = (inputValue) => {
    // Remove consecutive whitespace characters within the input value
    inputValue = inputValue.replace(/\s+/g, ' ');

    // Check if the input value is an exact match with an existing tag value
    const isDuplicate = tags.some(
      (tag) => tag.value.toLowerCase() === inputValue.toLowerCase()
    );

    // Add the new option to the tags array if it is not an empty string or a duplicate
    if (inputValue.trim() !== '' && !isDuplicate) {
      const newTag = { value: inputValue.trim(), label: inputValue.trim() };
      setTags([newTag]);
    }
  };

  const handleFromDate = (event) => {
    // event.preventDefault()
    console.log(event.target.value);
    if (event.target.value === '') {
      setFromDate(initialFromDate);
    } else setFromDate(event.target.value);
  };

  const handleToDate = (event) => {
    console.log(event.target.value);
    if (event.target.value === '') {
      setToDate(initialToDate);
    } else setToDate(event.target.value);
  };

  //    function formatDate(dateString) {
  //        const date = new Date(dateString);
  //        const day = date.getDate();
  //        const monthIndex = date.getMonth();
  //        const year = date.getFullYear();
  //        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  //        const monthName = monthNames[monthIndex];
  //        const suffix = day % 10 === 1 ? 'st' : day % 10 === 2 ? 'nd' : day % 10 === 3 ? 'rd' : 'th';
  //        return `${day}${suffix} ${monthName} ${year}`;
  //      }

  // const handleCheck = (event) =>{
  // 	const v= event.target.value;
  // 	if(event.target.checked){
  // 		if(check.indexOf(v)<0){
  // 			const arr= check;
  // 			arr.push(v);
  // 			setCheck(arr);
  // 		}
  // 	}else{
  // 		if(check.indexOf(v)>=0){
  // 			const arr= check.filter(a => a !== v);
  // 			setCheck(arr);
  // 		}
  // 	}
  // 		console.log(check);
  // }

  //show channels information when clicked on mentions present in message

  const handleMentionsNew = async (c_name) => {
    console.log(c_name, 'here');
    const message = {
      query: c_name,
    };

    let response = {};
    response = await channel_info(message);
    console.log('response for your query', response);
    setMentionsData(response?.dbRes);

    // console.log('response for your query', response)
    console.log('mentionsData : ', mentionsData);
    setMentionsPop(true);
  };

  const handleMentions = async (channel_name) => {
    if (channel_name) {
      const message = {
        query: channel_name,
      };

      let response = {};
      response = await channel_info(message);
      console.log('response for your query', response);
      setMentionsData(response?.dbRes);

      // console.log('response for your query', response)
      console.log('mentionsData : ', mentionsData);
      setforwardDetailsPopUp(true);
      setMentionsPop(false);
    } else {
      toast.info('Forwarded details are not attached');
    }
  };

  const handleMentionsModal = async (channel_name) => {
    if (channel_name) {
      const message = {
        query: channel_name,
      };

      let response = {};
      response = await channel_info(message);
      console.log('response for your query', response);
      setMentionsData(response?.dbRes);

      // console.log('response for your query', response)
      console.log('mentionsData : ', mentionsData);
    } else {
      toast.info('Forwarded details are not attached');
    }
  };

  const handleCheck = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    const index = metaData.indexOf(value);
    const newArr = [...metaData];
    if (isChecked) {
      if (index === -1) {
        newArr.push(value);
        setmetaData([...metaData, value]);
      }
    } else {
      if (index !== -1) {
        newArr.splice(index, 1);
        setmetaData(newArr);
      }
    }

    setCheckboxesState({
      ...checkboxesState,
      [value]: isChecked,
    });

    return newArr;
  };

  const handleTagsCheck = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    const index = messageData.indexOf(value);
    const newArr = [...messageData];
    if (isChecked) {
      if (index === -1) {
        newArr.push(value);
        setmessageData([...messageData, value]);
      }
    } else {
      if (index !== -1) {
        newArr.splice(index, 1);
        setmessageData(newArr);
      }
    }

    setCheckboxesState({
      ...checkboxesState,
      [value]: isChecked,
    });

    return newArr;
  };

  const handleFin_tags = () => {
    const mergedArray = check
      .concat(keywords)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
    console.log('merged arr :::::', mergedArray);
    setFin_tags(mergedArray);
    console.log('set_fin_tags : ', fin_tags);
    return mergedArray;
  };

  const handleFinalKeys = () => {
    let arr = [];
    for (let i = 0; i < selectedOptions?.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    setKeywords([...arr, keywords]);

    //after concatenation
    // return [...arr,...messageData];

    return arr;
  };
  function copyToClipboard(text) {
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-9999px';
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
  function formatDateStamp(timestamp) {
    const date = new Date(timestamp);
    const options = {
      timeZone: 'Asia/Kolkata',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleString('en-IN', options);
  }

  function createData(
    content,
    tags,
    sent,
    id,
    _id,
    cname,
    mobile,
    mention,
    url,
    forwarded,
    pinned,
    file,
    emails,
    urls,
    ref_id,
    forwarded_details,
    entity_user_name,
    updated_at,
    is_seen,
    is_deleted,
    is_edited,
    content_history,
    entity_banned,
    entireMessage
  ) {
    const date = sent.$date;
    const storage_date = updated_at.$date;
    // console.log(date, storage_date)
    return {
      content,
      tags,
      date,
      id,
      _id,
      cname,
      mobile,
      mention,
      url,
      forwarded,
      pinned,
      file,
      emails,
      urls,
      ref_id,
      forwarded_details,
      entity_user_name,
      storage_date,
      is_seen,
      is_deleted,
      is_edited,
      content_history,
      entity_banned,
      entireMessage,
    };
  }

  const applyFilterdropdown = () => {
    finalSubmit2();
    setCheckboxFilter(!checkboxFilter);
  };

  const handleCopyClick_json = (variableToCopy) => {
    const copiedObject = JSON.parse(variableToCopy);
    const variables = ['requested_users', 'is_deleted_by_user', 'seen_by'];

    for (let i = 0; i < variables?.length; i++) {
      delete copiedObject[variables[i]];
    }

    const jsonString = JSON.stringify(copiedObject);
    copyToClipboard(jsonString);
    toast.success('Copied to clipboard!');
  };

  const handleCopyClick = (variableToCopy) => {
    copyToClipboard(variableToCopy);
    toast.success('Copied to clipboard!');
  };
  const handleKeywords = (event, value) => {
    setKeywords(value);
  };

  const handlePage = (event, value) => {
    setPage(value);
    const t = handleFinalKeys();
    let message = {
      pageNo: value,
      limitPerPage: entries,
      searchText: t,
      attributes: metaData,
      tags: messageData,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        entity_id: channelId,
        pageNo: value,
        limitPerPage: entries,
        searchText: t,
        attributes: metaData,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        or_toggle: or_toggle,
      };
    }
    test_c(message);
  };

  const handleClearDate = () => {
    setDateFlag(0);
    setFromDate(initialFromDate);
    setToDate(initialToDate);
    setDisplayDate('Select Date Range');
    setSelectionRange({
      starDate: moment().subtract(122, 'years').startOf('year'),
      endDate: moment().add(77, 'years').endOf('year'),
      key: 'selection',
    });
    const t = handleFinalKeys();
    let message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: t,
      attributes: metaData,
      tags: messageData,
      // "flag": dateFlag,
      fromDate: initialFromDate,
      toDate: initialToDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        entity_id: channelId,
        pageNo: 1,
        limitPerPage: entries,
        searchText: t,
        attributes: metaData,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: initialFromDate,
        toDate: initialToDate,
        or_toggle: or_toggle,
      };
    }
    test_c(message);

    // const t = handleFinalKeys();
    // let message = {
    //   index: 0,
    //   tags: t.join(' '),
    //   flag: 0,
    //   tags: messageData,
    //   fromDate: fromDate,
    //   toDate: toDate,
    // };
    // if(channelId){
    // 	message={
    // 		"channelId": channelId,

    // 	}
    // }
    // test_c(message);
  };

  const handleTagClose = () => {
    const date = [];
    setmessageData([]);
    const t = handleFinalKeys();
    setPage(1);
    let message = {
      attributes: metaData,
      pageNo: 1,
      limitPerPage: entries,
      searchText: t,
      tags: [],
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        attributes: metaData,
        pageNo: 1,
        limitPerPage: entries,
        searchText: t,
        tags: [],
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        entity_id: channelId,
        or_toggle: or_toggle,
      };
    }
    setLoading(true);
    test_c(message);
  };

  const finalSubmit = () => {
    if (dbRefId) {
      console.log('working', location.pathname);
      navigate(location.pathname);
    }
    setFromDate(moment(selectionRange.startDate).format('YYYY-MM-DD'));
    setToDate(moment(selectionRange.endDate).format('YYYY-MM-DD'));
    const date = [];
    const t = handleFinalKeys();
    setPage(1);
    let message = {
      attributes: metaData,
      pageNo: 1,
      limitPerPage: entries,
      searchText: t,
      tags: messageData,
      // "flag": dateFlag,
      fromDate: moment(selectionRange.startDate).format('YYYY-MM-DD'),
      toDate: moment(selectionRange.endDate).format('YYYY-MM-DD'),
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        attributes: metaData,
        pageNo: 1,
        limitPerPage: entries,
        searchText: t,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: moment(selectionRange.startDate).format('YYYY-MM-DD'),
        toDate: moment(selectionRange.endDate).format('YYYY-MM-DD'),
        entity_id: channelId,
        or_toggle: or_toggle,
      };
    }
    setLoading(true);
    test_c(message);
  };

  const finalSubmit2 = () => {
    if (dbRefId) {
      console.log('working', location.pathname);
      navigate(location.pathname);
    }
    console.log('hitting correct api');
    // setFromDate(moment(selectionRange.startDate).format('YYYY-MM-DD'));
    // setToDate(moment(selectionRange.endDate).format('YYYY-MM-DD'));
    const date = [];
    const t = handleFinalKeys();
    setPage(1);
    let message = {
      attributes: metaData,
      pageNo: 1,
      limitPerPage: entries,
      searchText: t,
      tags: messageData,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      or_toggle: or_toggle,
    };
    if (channelId) {
      message = {
        attributes: metaData,
        pageNo: 1,
        limitPerPage: entries,
        searchText: t,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        entity_id: channelId,
        or_toggle: or_toggle,
      };
    }
    setLoading(true);
    test_c(message);
  };

  const get_message = async (message) => {
    console.log('step 2');
    let response = {};
    response = await message_info(message);
    console.log(response);
    let chan = response?.dbRes;
    // const { chan, pCount, tot } = response;

    setPc(Math.ceil(1 / entries));
    setTotCount(1);
    setDet(entries);

    let test_rows = [];

    test_rows.push(
      createData(
        chan?.text_content,
        chan?.tags,
        chan?.message_sent_at,
        chan?.meta_data.sender_id,
        chan?.message_id,
        chan?.entity_name,
        chan?.attributes.mobile_numbers,
        chan?.attributes.mentions,
        chan?.attributes.urls,
        chan?.forwarded_details.is_forwarded,
        chan?.pinned,
        chan?.attached_media,
        chan?.attributes.email_ids,
        chan?.attributes.urls,
        chan?.message_db_ref_id,
        chan?.forwarded_details,
        chan?.entity_user_name,
        chan?.updated_at,
        chan?.is_seen,
        chan?.is_deleted,
        chan?.is_edited,
        chan?.content_history,
        chan?.entity_banned,
        chan
      )
    );
    console.log(test_rows);
    setSample_rows(test_rows);
    setLoading(false);
  };

  const test_c_temp = async (a, message) => {
    console.log(message);
    searchParams.set('querySearchFlag', true);
    for (let key in message) {
      const value = message[key];
      if (Array.isArray(value)) {
        console.log('array item');
        searchParams.set(key, JSON.stringify(value));
      } else {
        searchParams.set(key, value);
      }
    }
    const newSearch = searchParams.toString();
    navigate({ search: `?${newSearch}` });

    let response = {};
    response = await message_call(message);
    let chan = response?.dbRes?.data;
    // const { chan, pCount, tot } = response;

    setPc(Math.ceil(response?.dbRes.totalCount / a));
    setTotCount(response?.dbRes.totalCount);
    setDet(entries);

    let test_rows = [];
    for (let i = 0; i < chan?.length; i++) {
      test_rows.push(
        createData(
          chan[i]?.text_content,
          chan[i]?.tags,
          chan[i]?.message_sent_at,
          chan[i]?.meta_data.sender_id,
          chan[i]?.message_id,
          chan[i]?.entity_name,
          chan[i]?.attributes.mobile_numbers,
          chan[i]?.attributes.mentions,
          chan[i]?.attributes.urls,
          chan[i]?.forwarded_details.is_forwarded,
          chan[i]?.pinned,
          chan[i]?.attached_media,
          chan[i]?.attributes.email_ids,
          chan[i]?.attributes.urls,
          chan[i]?.message_db_ref_id,
          chan[i]?.forwarded_details,
          chan[i]?.entity_user_name,
          chan[i]?.updated_at,
          chan[i]?.is_seen,
          chan[i]?.is_deleted,
          chan[i]?.is_edited,
          chan[i]?.content_history,
          chan[i]?.entity_banned,
          chan[i]
        )
      );
    }
    setSelectedQuery(message);
    setSample_rows(test_rows);
    setLoading(false);
  };

  const test_c = async (message) => {
    console.log(message);
    searchParams.set('querySearchFlag', true);
    for (let key in message) {
      const value = message[key];
      if (Array.isArray(value)) {
        console.log('array item');
        searchParams.set(key, JSON.stringify(value));
      } else {
        searchParams.set(key, value);
      }
    }
    const newSearch = searchParams.toString();
    navigate({ search: `?${newSearch}` });

    let response = {};
    response = await message_call(message);
    let chan = response?.dbRes?.data;
    if (
      message.hasOwnProperty('entity_id') &&
      message['entity_id']?.length > 0
    ) {
      if (chan?.length > 0) {
        setVisibleChannel(true);
        if (Array.isArray(chan[0]['entity_name'])) {
          setMakeChannelVisible([
            message['entity_id'],
            chan[0]['entity_name'][0],
          ]);
        } else {
          setMakeChannelVisible([message['entity_id'], chan[0]['entity_name']]);
        }
      }
    }
    // const { chan, pCount, tot } = response;

    setPc(Math.ceil(response?.dbRes?.totalCount / entries));
    setTotCount(response?.dbRes?.totalCount);
    setDet(entries);

    let test_rows = [];
    for (let i = 0; i < chan?.length; i++) {
      test_rows.push(
        createData(
          chan[i]?.text_content,
          chan[i]?.tags,
          chan[i]?.message_sent_at,
          chan[i]?.meta_data?.sender_id,
          chan[i]?.message_id,
          chan[i]?.entity_name,
          chan[i]?.attributes?.mobile_numbers,
          chan[i]?.attributes?.mentions,
          chan[i]?.attributes?.urls,
          chan[i]?.forwarded_details?.is_forwarded,
          chan[i]?.pinned,
          chan[i]?.attached_media,
          chan[i]?.attributes?.email_ids,
          chan[i]?.attributes?.urls,
          chan[i]?.message_db_ref_id,
          chan[i]?.forwarded_details,
          chan[i]?.entity_user_name,
          chan[i]?.updated_at,
          chan[i]?.is_seen,
          chan[i]?.is_deleted,
          chan[i]?.is_edited,
          chan[i]?.content_history,
          chan[i]?.entity_banned,
          chan[i]
        )
      );
    }
    setSelectedQuery(message);
    setSample_rows(test_rows);
    setLoading(false);
  };

  const handleClickOutsideDate = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  const handleClickOutsideFilter = (event) => {
    if (
      filterbuttonRef.current &&
      !filterbuttonRef.current.contains(event.target)
    ) {
      setCheckboxFilter(false);
    }
  };

  const handleTeleCopyLink = (uname, mid, r_id) => {
    console.log(uname, mid);
    if (uname) {
      // navigator.clipboard.writeText("https://t.me/"+uname+"/"+mid)
      copy('https://t.me/' + uname + '/' + mid);
      toast.info('Link Copied to Clipboard');
    } else {
      console.log(window.location.origin, r_id);
      // navigator.clipboard.writeText(window.location.origin+"/messages?messageRefId="+r_id)
      copy(window.location.origin + '/messages?messageRefId=' + r_id);
      toast.info(
        'Selected Message is a PRIVATE CHANNEL. Sharable Link to Telemon Message is Copied to Clipboard'
      );
    }
  };

  const handleDashboardCopyLink = (ref_id) => {
    console.log(window.location.origin, ref_id);
    copy(window.location.origin + '/messages?messageRefId=' + ref_id);
    toast.info('Sharable Link to Telemon Message is Copied to Clipboard');
  };

  const handleReadUnread = async (ref_id) => {
    console.log(ref_id);
    let message = {
      messages_ids: ref_id,
    };
    let res = await read_unread_update(message);
    console.log(res);
    toast.success('Messages Successfully Marked as Read');

    if (dbRefId) {
      console.log('step1');
      let message = {
        message_db_ref_id: ref_id,
      };
      get_message(message);
    } else {
      const t = handleFinalKeys();

      let m = {
        attributes: metaData,
        pageNo: page,
        limitPerPage: entries,
        searchText: t,
        tags: messageData,
        // "flag": dateFlag,
        fromDate: fromDate,
        toDate: toDate,
        or_toggle: or_toggle,
      };

      if (channelId) {
        m['entity_id'] = channelId;
      }
      let response = {};
      response = await message_call(m);
      let chan = response?.dbRes?.data;
      // const { chan, pCount, tot } = response;

      setPc(Math.ceil(response?.dbRes?.totalCount / entries));
      setTotCount(response?.dbRes?.totalCount);
      setDet(entries);

      let test_rows = [];
      for (let i = 0; i < chan?.length; i++) {
        test_rows.push(
          createData(
            chan[i]?.text_content,
            chan[i]?.tags,
            chan[i]?.message_sent_at,
            chan[i]?.meta_data?.sender_id,
            chan[i]?.message_id,
            chan[i]?.entity_name,
            chan[i]?.attributes?.mobile_numbers,
            chan[i]?.attributes?.mentions,
            chan[i]?.attributes?.urls,
            chan[i]?.forwarded_details?.is_forwarded,
            chan[i]?.pinned,
            chan[i]?.attached_media,
            chan[i]?.attributes?.email_ids,
            chan[i]?.attributes?.urls,
            chan[i]?.message_db_ref_id,
            chan[i]?.forwarded_details,
            chan[i]?.entity_user_name,
            chan[i]?.updated_at,
            chan[i]?.is_seen,
            chan[i]?.is_deleted,
            chan[i]?.is_edited,
            chan[i]?.content_history,
            chan[i]?.entity_banned,
            chan[i]
          )
        );
      }
      console.log('working all fine here');
      setSample_rows(test_rows);
    }
  };

  const showJoinConfirmation = async (row, index) => {
    setSelectedJoinChannel(row);
    setSelectedJoinChannelIndex(index);
    setShowJoinPop(true);
    setMentionsPop(false);
    // await changeValue(row, index);
  };

  const changeImportAllValue = async (value, index) => {
    let message = {};
    message = {
      entity_id: value.channel_id,
      entity_user_name: value.channel_username,
      join_source_type: 'MANUAL',
      import_all_messages: true,
    };

    let response = [];
    response = await join_channel(message);
    let chan = response?.dbRes;
    console.log(chan);
    if (response['error'] === '') {
      toast.success('Your Job Id is ', chan['job_id']);
    } else {
      toast.error('Server Error');
    }
  };

  //function to join a channel without importing its existing messages
  const changeValue = async (value, index) => {
    let message = {};
    message = {
      entity_id: value.channel_id,
      join_source_type: 'MANUAL',
      entity_user_name: value.channel_username,
      import_all_messages: false,
    };

    let response = [];
    response = await join_channel(message);
    let chan = response?.dbRes;
    console.log(chan);
    if (response['error'] === '') {
      toast.success('Your Job Id is ', chan['job_id']);
    } else {
      toast.error('Server Error');
    }
  };

  // const handleClearFilter = () =>{
  //   console.log(checkboxesState)
  // }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFilter);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFilter);
    };
  }, []);

  React.useEffect(() => {
    console.log(channelId, querySearchFlag);
    if (dbRefId) {
      console.log('step1');
      let message = {
        message_db_ref_id: dbRefId,
      };
      get_message(message);
    } else if (querySearchFlag) {
      console.log('dskjafnadsjkfhnj');
      let te = {};

      searchParams.forEach((value, key) => {
        if (key === 'querySearchFlag') {
          console.log('do nothing');
        } else if (key == 'channelId') {
          console.log('do nothing');
        } else if (key == 'or_toggle') {
          value === 'true'
            ? (te['or_toggle'] = true)
            : (te['or_toggle'] = false);
        } else if (value.startsWith('[') && value.endsWith(']')) {
          try {
            te[key] = JSON.parse(value); // Parse array values from JSON strings
          } catch (error) {
            console.error(`Error parsing array value for ${key}: ${error}`);
          }
        } else {
          te[key] = value;
        }
      });
      console.log(te);

      let flagFrom = false;
      let flagTo = false;
      Object.entries(te).forEach(([key, value]) => {
        // te.forEach((value, key) => {
        if (key === 'attributes') {
          for (let i = 0; i < value?.length; i++) {
            checkboxesState[value[i]] = true;
          }
          setmetaData(value);
        } else if (key === 'searchText') {
          let temp = [];
          for (let i = 0; i < value?.length; i++) {
            temp.push({ value: value[i], label: value[i] });
          }
          setSelectedOptions(temp);
        } else if (key === 'or_toggle') {
          if (value === 'true') {
            setOr_toggle(true);
          } else {
            setOr_toggle(false);
          }
        } else if (key === 'pageNo') {
          setPage(value);
        } else if (key === 'tags') {
          let temp = [];
          for (let i = 0; i < value?.length; i++) {
            temp.push(value[i]);
          }
          setTags(temp);
        } else if (key === 'limitPerPage') {
          setEntries(parseInt(value));
        } else if (key === 'fromDate') {
          setFromDate(value);
          flagFrom = true;
        } else if (key === 'toDate') {
          setToDate(value);
          flagTo = true;
        } else {
          console.log('look into this unknown field');
        }
      });

      let date_present = false;
      if (flagFrom === true && flagTo === true) {
        console.log('f1');
        setSelectionRange({
          startDate: moment(te['fromDate']).toDate(),
          endDate: moment(te['toDate']).toDate(),
          key: 'selection',
        });
        date_present = true;
      } else if (flagFrom === true) {
        console.log('f2');
        setSelectionRange({
          startDate: moment(te['fromDate']).toDate(),
          endDate: toDate,
          key: 'selection',
        });
      } else if (flagTo === true) {
        console.log('f3');
        setSelectionRange({
          startDate: fromDate,
          endDate: moment(te['toDate']).toDate(),
          key: 'selection',
        });
      } else {
        console.log('have a look at this date case where no date is provided');
      }

      if (date_present === true) {
        setDisplayDate(`${te['fromDate']}` + ' - ' + `${te['toDate']}`);
      }

      // const t = handleFinalKeys();
      // setSelectionRange({
      //   starDate: moment().subtract(122, 'years').startOf('year'),
      //   endDate: moment().add(77, 'years').endOf('year'),
      //   key: 'selection',
      // });
      //   fromDate: fromDate,
      //   toDate: toDate,
      // or_toggle: or_toggle,
      let message = te;
      console.log(message);
      // if(channelId){
      //   message["entity_id"]= channelId,
      // }
      test_c(message);
    } else {
      // setSelectedOptions([])
      // setmetaData([])
      // setOr_toggle(false)
      // setmessageData([])
      // setDateFlag(0);
      // setFromDate(initialFromDate);
      // setToDate(initialToDate);
      // setDisplayDate('Select Date Range');
      setPage(1);
      const t = handleFinalKeys();
      // setSelectionRange({
      //   starDate: moment().subtract(122, 'years').startOf('year'),
      //   endDate: moment().add(77, 'years').endOf('year'),
      //   key: 'selection',
      // });
      //   fromDate: fromDate,
      //   toDate: toDate,
      // or_toggle: or_toggle,
      let message = {
        pageNo: page,
        limitPerPage: entries,
      };
      if (channelId) {
        message = {
          entity_id: channelId,
          pageNo: 1,
          limitPerPage: entries,
          searchText: t,
          attributes: metaData,
          tags: messageData,
          fromDate: fromDate,
          toDate: toDate,
          or_toggle: or_toggle,
        };
      }

      if (searchQuery) {
        setSelectedOptions({ value: searchQuery, label: searchQuery });
        message = {
          pageNo: page,
          searchText: searchQuery,
          limitPerPage: entries,
        };
      }
      test_c(message);
    }
  }, [channelId, dbRefId]);

  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      {/* {console.log(selectedQuery)} */}
      <div className='dashright'>
        <div className='main-area'>
          <div className='top-head'>
            <div className='heading'>Message Center</div>

            <Select
              className='entryBoxMessages'
              isMulti
              options={tags}
              value={selectedOptions}
              onChange={handleSelectChange}
              placeholder='Search or select options'
              isSearchable
              onInputChange={handleInputChange}
            />
            <img />
            <div className='right_filters'>
              <div className='date_filter_box'>
                <div className='table_date_filter_div'>
                  <button
                    style={{ minWidth: 'max-content' }}
                    className='selectAllFilters'
                    onClick={() => setShowCalendar((prev) => !prev)}
                  >
                    {!showCalendar ? displayDate : 'Close Calendar'}
                  </button>
                  {showCalendar ? (
                    <div
                      style={{
                        position: 'absolute',
                        marginTop: '3%',
                        zIndex: '99',
                        marginLeft: '-5%',
                        border: '1px solid #919EAB',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        paddingBlockEnd: '1%',
                        height: 'fit-content',
                      }}
                      ref={dropdownRef}
                    >
                      <DateRangePicker
                        showDropdowns={false}
                        className='datepicker_dropdown'
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                      />
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'row',
                          gap: '2%',
                        }}
                      >
                        <button
                          className='filterSearch'
                          onClick={() => {
                            setDisplayDate(
                              `${moment(selectionRange?.startDate).format(
                                'DD-MM-YYYY'
                              )}` +
                                ' - ' +
                                `${moment(selectionRange?.endDate).format(
                                  'DD-MM-YYYY'
                                )}`
                            );
                            setShowCalendar(!showCalendar);
                            finalSubmit();
                          }}
                        >
                          Apply
                        </button>
                        <button
                          className='filterSearch'
                          style={{
                            minWidth: 'fit-content',
                            height: 'fit-content',
                          }}
                          onClick={() => {
                            setShowCalendar(!showCalendar);
                            handleClearDate();
                          }}
                        >
                          Clear Date
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* DATE FILTER HERE OMFG */}
                {/* <input
                  className='fromDate'
                  value={fromDate}
                  onChange={handleFromDate}
                  type='date'
                />
                <input
                  className='toDate'
                  value={toDate}
                  onChange={handleToDate}
                  type='date'
                /> */}
              </div>
              {/* <button className='btn btn-success btn-sm' onClick={handleClearDate}> Clear Date</button> */}

              <div className='filterButtonDiv'>
                <button
                  className='filter_button'
                  onClick={() => {
                    setCheckboxFilter(!checkboxFilter);
                  }}
                  style={{ width: '130px' }}
                >
                  Filter{metaData?.length > 0 ? ` (${metaData?.length})` : ''}
                </button>

                <Collapse in={checkboxFilter} timeout='auto' unmountOnExit>
                  <div
                    style={{ position: 'absolute' }}
                    className='dropdownContainer'
                    ref={filterbuttonRef}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className='dropdownHead'>Filter</div>
                      <div
                        className='form-check form-switch'
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <label
                          className='form-check-label'
                          htmlFor='message-contained-toggle'
                          style={{ width: '100%' }}
                        >
                          OR
                        </label>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='message-contained-toggle'
                          checked={or_toggle}
                          onChange={() => setOr_toggle(!or_toggle)}
                        />
                        <img
                          src={InstructionPopUp}
                          style={{ height: '2vh' }}
                          onMouseEnter={() => setinstructionPopUp(true)}
                          onMouseLeave={() => setinstructionPopUp(false)}
                        />
                      </div>
                      {instructionPopUp === true ? (
                        <div
                          style={{
                            width: 'fit',
                            position: 'absolute',
                            right: '12%',
                            top: '10%',
                            backgroundColor: '#F7F9FB',
                            padding: '1%',
                            borderRadius: '10px',
                            fontSize: '12px',
                            boxShadow: '0px 0px 20px 6px #00000024',
                          }}
                          onClick={() => {
                            setinstructionPopUp(false);
                          }}
                        >
                          <div
                            className=''
                            style={{
                              borderRadius: '10px',
                              padding: 0,
                              width: 'fit',
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div
                              style={{ width: '200px', textAlign: 'center' }}
                            >
                              Switching on OR will display results that meet any
                              of the selected options, broadening the range of
                              potential matches.
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className='dropdownDescription'>
                      Select tags and filters to display data
                    </div>
                    <div className='dropdownBody'>
                      <div
                        className='dropdownRows'
                        style={{ borderLeft: '2px solid #d4cfcb47' }}
                      >
                        <div className='dropdownSubheads'>Contents</div>
                        <div className='dropdownContent'>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['attached_media']}
                              value='attached_media'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Media</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='mentions'
                              checked={!!checkboxesState['mentions']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Mentions</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='email'
                              checked={!!checkboxesState['email']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Email</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='urls'
                              checked={!!checkboxesState['urls']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>URL</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='mobile_numbers'
                              checked={!!checkboxesState['mobile_numbers']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Ph. Number</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='upi_ids'
                              checked={!!checkboxesState['upi_ids']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Upi Id</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='telegram_links'
                              checked={!!checkboxesState['telegram_links']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Telegram Links</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='whatsapp_links'
                              checked={!!checkboxesState['whatsapp_links']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Whatsapp Links</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              value='wallet_address'
                              checked={!!checkboxesState['wallet_address']}
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Wallet Addresses</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='dropdownRows'
                        style={{ borderLeft: '2px solid #d4cfcb47' }}
                      >
                        <div className='dropdownSubheads'>Type</div>
                        <div className='dropdownContent'>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['pinned']}
                              value='pinned'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Pinned</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['forwarded']}
                              value='forwarded'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Forwarded</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['read']}
                              value='read'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Read</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['unread']}
                              value='unread'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Unread</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['deleted']}
                              value='deleted'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Deleted</div>
                          </div>
                          <div className='checks'>
                            <input
                              type='checkbox'
                              checked={!!checkboxesState['edited']}
                              value='edited'
                              onChange={handleCheck}
                            />
                            <div className='tagItem'>Edited</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='dropdownButtons'>
                      <button className='apply' onClick={applyFilterdropdown}>
                        {' '}
                        Apply
                      </button>
                      <button
                        className='cancel'
                        onClick={() => {
                          setCheckboxFilter(!checkboxFilter);
                        }}
                      >
                        Cancel
                      </button>
                      <img
                        src={clearFiltersIcon}
                        alt='clear filter icon here'
                        title='Clear Filters'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setmetaData([]);
                          setCheckboxesState({});
                          setOr_toggle(false);
                        }}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
              <button className='apply_filter' onClick={finalSubmit2}>
                Apply
              </button>
              {/* <button className='export_button'>Export</button> */}
              {/* <button className='welcome_button'>Welcome User</button> */}
            </div>
          </div>
          {visibleChannel && (
            <div
              className='channel_title_visible'
              style={{ fontWeight: 'bold', margin: 'auto' }}
            >
              {' '}
              {makeChannelVisible[1]}({makeChannelVisible[0]}){' '}
            </div>
          )}
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ height: '10%' }}
                src={loadingImg}
                alt='load immage'
              />
            </div>
          ) : sample_rows?.length > 0 ? (
            <>
              <div
                className='quickFilters'
                style={{
                  display: 'flex',
                  gap: '1%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', gap: '1%' }}>
                  <button
                    className='selectAllFilters'
                    onClick={() => {
                      if (edit === true) {
                        setSelectedMessages([]);
                      }
                      setEdit(!edit);
                      setShowMarkReadOption(true);
                      // setShowMarkAsReadPop(true)
                      setSelectedQuery();
                    }}
                  >
                    {edit ? 'Close' : 'Select Messages'}
                  </button>
                  {edit ? (
                    <>
                      {dbRefId ? (
                        <></>
                      ) : (
                        <button
                          className='selectAllFilters'
                          onClick={() => {
                            setSelectAllPopup(true);
                          }}
                        >
                          Select All
                        </button>
                      )}
                      {selectedMessages?.length > 0 || selectedQuery ? (
                        <>
                          {dbRefId ? (
                            <></>
                          ) : (
                            <button
                              style={{ maxWidth: 'fit-content' }}
                              className='selectAllFilters'
                              onClick={() => {
                                setShowEditTagsPopup2(true);
                                setSelectedKeys([]);
                              }}
                            >
                              Add Tags
                            </button>
                          )}
                          {dbRefId ? (
                            <></>
                          ) : showMarkReadOption === true ? (
                            <button
                              style={{ maxWidth: 'fit-content' }}
                              className='selectAllFilters'
                              onClick={() => {
                                setShowMarkAsReadPop(true);
                                // setSelectedKeys([]);
                                // handleReadUnread(selectedMessages)
                              }}
                            >
                              Mark as Read
                            </button>
                          ) : (
                            <></>
                          )}

                          <button
                            onClick={(event) => {
                              setExportedPop(true);
                            }}
                            className='export_button'
                            style={{ maxWidth: 'fit-content' }}
                          >
                            Export
                          </button>
                          <div
                            style={{
                              alignSelf: 'center',
                              marginLeft: '1%',
                              fontSize: 'medium',
                              color: '#B7B7B7',
                            }}
                          >
                            Selected Messages(
                            {selectedMessages?.length > 0
                              ? selectedMessages?.length
                              : selectedQuery
                              ? totCount
                              : 0}
                            )
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {messageData?.length > 0 ? (
                  <div
                    className='display_pages'
                    style={{
                      paddingRight: '4.5%',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      gap: '3%',
                    }}
                  >
                    Tag:
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        backgroundColor: '#F16767',
                        color: 'white',
                        paddingBlock: '2%',
                        paddingInline: '8%',
                      }}
                    >
                      {messageData}
                      <button
                        style={{
                          color: 'white',
                          blockSize: '3%',
                          fontSize: 'medium',
                          backgroundColor: '#F16767',
                          alignSelf: 'flex-start',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          handleTagClose();
                        }}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    finalSubmit();
                  }}
                >
                  <img src={refresh_btn} style={{ height: '3vh' }} />
                </div>
              </div>
              <div className='message_card_div'>
                {sample_rows.map((row, index) => (
                  <div
                    className={
                      row?.is_seen
                        ? 'message_cards message_cards_seen'
                        : 'message_cards message_cards_unseen'
                    }
                    key={Math.random()}
                    ref={scrollref}
                    onClick={() => {
                      setChannelCard(!channelCard);
                      setChannelData(row);
                    }}
                  >
                    <div className='cardHead'>
                      <div className='cardTopic'>
                        <div
                          className='cardTitle'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Link
                            style={{ fontFamily: 'Roboto' }}
                            className='channel_link'
                            to={`/messages?channelId=${row?.id}`}
                          >
                            {row?.cname}
                          </Link>
                        </div>
                        {edit === true ? (
                          <div>
                            <input
                              className='card_head_check'
                              type={'checkbox'}
                              checked={
                                selectedMessages.includes(row?.ref_id) ||
                                selectedQuery
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                handleCheckboxChange(event, row)
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        <div
                          className=''
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {row?.pinned === true ? (
                            <img className='aboutSVGs' src={pin} alt='pin' />
                          ) : (
                            <></>
                          )}

                          {row?.forwarded === true ? (
                            <button
                              style={{
                                backgroundColor: 'rgba(255, 255, 255, 0)',
                                maxWidth: 'fit-content',
                                maxHeight: '0px',
                                marginBottom: '10px',
                                marginLeft: '10px',
                              }}
                              onClick={(event) => {
                                event.preventDefault();
                                setForwardedRow(row);
                                setFowardedData(row?.forwarded_details);
                                handleMentions(
                                  row?.forwarded_details.fwd_source_id
                                );
                                setForwardedPop(false);
                              }}
                            >
                              <img
                                className='aboutSVGs'
                                style={{ cursor: 'pointer' }}
                                src={forward}
                                alt=''
                              />
                            </button>
                          ) : (
                            <></>
                          )}
                          {row?.entity_banned === true ? (
                            <div
                              style={{
                                marginLeft: '5px',
                                color: 'red',
                                fontSize: '16px',
                              }}
                            >
                              <span>(BANNED / REMOVED)</span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <button
                            className='btn btn-outline-secondary btn'
                            style={{ marginLeft: '5px' }}
                            onClick={(event) => {
                              event.preventDefault();
                              setMessageDetails(row);
                              // setMessageDetailsPop(true);
                              if (row?.forwarded === true) {
                                handleMentionsModal(
                                  row?.forwarded_details?.fwd_source_id
                                );
                                // setForwardedRow(row);
                              }
                              setMessageDetailsPop(true);
                            }}
                          >
                            Actions
                          </button>
                        </div>
                        <div className='cardTeleLink'>
                          <button
                            className={row?.is_seen ? 'cls_seen' : 'cls_unseen'}
                            onClick={() => handleReadUnread([row?.ref_id])}
                          >
                            <img
                              alt='Message Link'
                              style={{ height: '2vh' }}
                              src={row?.is_seen ? readIcon : unreadIcon}
                            />
                          </button>
                        </div>

                        {/* <div className='cardDownArrow'>
									<button className='cardOpenBtn' onClick={()=>{setOpenCard({...openCard, [row._id]:!openCard[row._id]})}}><img className='cardDownArrowSVG' src={ openCard[row._id] ? downArrowGray : sideArrowGrey} /></button>
								</div> */}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className='message_Date'
                          style={{ fontWeight: '500' }}
                        >
                          Message Received at : {formatDate(row?.date)}
                        </div>
                        {row?.is_edited ? (
                          <div
                            style={{
                              marginLeft: '10px',
                              color: '#898F9A',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              setHistoryPopUp(!historyPopUp);
                              setHistoryDialogData(row);
                            }}
                          >
                            <span>(EDITED)</span>
                            <img
                              alt='Message Link'
                              style={{ height: '2vh', marginLeft: '5px' }}
                              src={Edited}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* {console.log(row, 'deleted')} */}
                        {row?.is_deleted ? (
                          <div style={{ marginLeft: '10px', color: 'red' }}>
                            <span>(DELETED)</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className='cardBody'>
                      <div className='main_Card_Details'>
                        {row?.content && row?.content?.length > 0 ? (
                          <div className='cardMessage'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '1%',
                                alignItems: 'center',
                              }}
                            >
                              <div className='detsHead'>Message : </div>
                              {/* <a href={`https://t.me/${row.cname}/${row.id}`} target='_blank' rel='noreferrer' title='Telegram Message Link'><img className='actionsIMG' style={{height:'60%'}} src={messageImg} /></a>
                               */}
                              <div className='cardTeleLink'>
                                <button
                                  className={
                                    row?.is_seen ? 'cls_seen' : 'cls_unseen'
                                  }
                                  onClick={() =>
                                    handleTeleCopyLink(
                                      row?.entity_user_name,
                                      row?._id,
                                      row?.ref_id
                                    )
                                  }
                                >
                                  <img
                                    alt='Message Link'
                                    style={{ height: '2vh' }}
                                    src={tele_link}
                                  />
                                </button>
                              </div>
                            </div>

                            <div
                              onClick={(event) => {
                                event.preventDefault();
                                setMessageDetails(row);
                                // setMessageDetailsPop(true);
                                if (row?.forwarded === true) {
                                  handleMentionsModal(
                                    row?.forwarded_details.fwd_source_id
                                  );
                                  // setForwardedRow(row);
                                }
                                setMessageDetailsPop(true);
                              }}
                              className='MessageText'
                              style={{
                                textDecoration: 'none',
                                cursor: 'default',
                              }}
                              onMouseOver={(e) => {
                                e.currentTarget.style.textDecoration =
                                  'underline';
                                e.currentTarget.style.cursor = 'pointer';
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.textDecoration = 'none';
                                e.currentTarget.style.cursor = 'default';
                              }}
                            >
                              {row?.content}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {row?.mobile?.length > 0 ? (
                          <div className='cardMobile'>
                            <div className='detsHead'>Mobile Number:</div>
                            <div className='mobileDets'>
                              {row?.mobile.map((m, i) => (
                                <div className='phoneBox'>{m}</div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className='cardTags'>
                          <div className='detsHead'>
                            Tags
                            {row?.content == undefined ||
                            row?.content?.length <= 0 ? (
                              <div className='cardTeleLink'>
                                <button
                                  className={
                                    row?.is_seen ? 'cls_seen' : 'cls_unseen'
                                  }
                                  onClick={() =>
                                    handleTeleCopyLink(
                                      row?.entity_user_name,
                                      row?._id,
                                      row?.ref_id
                                    )
                                  }
                                >
                                  <img
                                    alt='Message Link'
                                    style={{ height: '2vh' }}
                                    src={tele_link}
                                  />
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className='Tags'>
                            {row?.tags &&
                              row?.tags?.map((tag, i) => (
                                <div
                                  className='tags'
                                  key={i}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleTagClick(tag);
                                  }}
                                >
                                  {tag}
                                </div>
                              ))}
                            <button
                              onClick={() => {
                                console.log(row?.date);
                                setSelectedRow(row);
                                setShowEditTagsPopup(true);
                                if (row?.tags) {
                                  setSelectedKeys(row?.tags);
                                } else {
                                  setSelectedKeys([]);
                                }
                              }}
                              style={{
                                width: 'fit-content',
                                fontSize: 'small',
                                height: 'min-content',
                                fontWeight: 'bolder',
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                color: '#00ACFB',
                                border: '1px solid #00ACFB',
                                marginLeft: '4px',
                                paddingInline: '6px',
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      {row?.emails?.length > 0 ? (
                        <div className='MailDets'>
                          <div className='detsHead'>Email</div>
                          <div className='cardEmail'>
                            {row?.emails.map((mob, i) => (
                              <div className='emailBox'>{mob}</div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {row?.mention?.length > 0 ? (
                        <div className='cardCrypto'>
                          <div className='detsHead'>Mentions</div>
                          <div className='cryptoBoxes'>
                            {row?.mention.map((mentioned, i) => (
                              <div
                                className='cryptoBox'
                                onClick={(event) => {
                                  // event.preventDefault();
                                  handleMentionsNew(mentioned);
                                }}
                              >
                                <Link
                                  style={{ fontFamily: 'Roboto' }}
                                  className='job_link_internal_page'
                                >
                                  {mentioned}
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {row?.entireMessage?.attributes?.upi_ids?.length > 0 ? (
                        <div className='cardCrypto'>
                          <div className='detsHead'>Upi Ids</div>
                          <div className='cryptoBoxes'>
                            {row?.entireMessage?.attributes?.upi_ids.map(
                              (upi, i) => (
                                <div className='cryptoBox'>{upi}</div>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        {/* {console.log('here is your url object')} */}
                        {row?.urls && Object.keys(row?.urls)?.length != 0 ? (
                          <div className='cardCrypto'>
                            <div className='detsHead'>Urls</div>
                            {Object.keys(row?.urls).map((key) => (
                              <div className='cryptoBoxes' key={key}>
                                <p>{key}:</p>

                                {row?.urls[key]?.map((value) => (
                                  <div className='cryptoBox' key={value}>
                                    <a
                                      href={value}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      {value}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        {/* {console.log('here is your url object')} */}
                        {row?.entireMessage?.attributes?.wallet_address &&
                        Object.keys(row?.entireMessage.attributes.wallet_address)
                          ?.length != 0 ? (
                          <div className='cardCrypto'>
                            <div className='detsHead'>Wallet Addresses</div>
                            {Object.keys(
                              row?.entireMessage?.attributes?.wallet_address
                            ).map((key) => (
                              <div className='cryptoBoxes' key={key}>
                                <p>{key}:</p>

                                {row?.entireMessage?.attributes?.wallet_address[
                                  key
                                ].map((value) => (
                                  <div className='cryptoBox' key={value}>
                                    <a
                                      href={value}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      {value}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {row?.file?.media_present === true ? (
                        <div className='expandableRow2'>
                          <div className='fileHead'>Files</div>
                          <div className='singleFile'>
                            {/* {(row.files).map((file, i) =>( */}
                            <div className='cardFile'>
                              {!row?.file?.storage_link && (
                                <div className='file_s'>
                                  <div className='fileHead'>Name:</div>
                                  <div
                                    className='fileContent'
                                    style={{
                                      backgroundColor: 'white',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <span
                                      style={{ backgroundColor: '#EBF9FF' }}
                                    >
                                      {row?.file?.media_name}
                                    </span>
                                    <button
                                      style={{
                                        width: 'fit-content',
                                        backgroundColor: 'white',
                                        margin: '0px 10px',
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setDownloadRowSelected(row);
                                        setDownloadPopUp(!downloadPopUp);
                                      }}
                                    >
                                      <img
                                        alt='download'
                                        style={{ height: '2vh' }}
                                        src={CLoudDownload}
                                      />
                                    </button>
                                  </div>
                                </div>
                              )}
                              {row?.file?.media_size ? (
                                <div className='file'>
                                  <div className='fileHead'>Size:</div>
                                  <div className='fileContent'>
                                    {row?.file?.media_size}B
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {row?.file?.storage_link ? (
                                <div className='file'>
                                  <div className='fileHead'>S3 Link:</div>
                                  <div className='fileContent'>
                                    <span>{row?.file?.storage_link}</span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {row?.file?.storage_link ? (
                                <div
                                  className='file'
                                  onClick={() =>
                                    downloadJsonFile(row?.file.storage_link)
                                  }
                                >
                                  <img
                                    alt='download'
                                    style={{ height: '2vh', cursor: 'pointer' }}
                                    src={download_icon}
                                  />
                                  <div
                                    className='Downloaded_text'
                                    style={{
                                      background: '#ebf9ff',
                                      paddingInline: '2px',
                                      marginLeft: '2px',
                                    }}
                                  >
                                    Downloaded
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {row?.file?.media_extension ? (
                                <div className='file'>
                                  <div className='fileHead'>Type:</div>
                                  <div className='fileType'>
                                    {row?.file?.media_extension}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* <div className='file'>
													<div className='fileHead'>
														Status:
													</div>
													<div className=''>
														Finished
													</div>
												</div> */}
                              {/* <div className='file'> */}
                              <div className=''></div>
                              {/* </div> */}
                            </div>
                            {/* ))} */}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={noData} alt='No Data' />
            </div>
          )}
          {showEditTagsPopup === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setShowEditTagsPopup(false);
              }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box className='Box' sx={style} id='keywordsAdd'>
                  <div className='keywordsModal'>
                    <div className='centerHead'>Edit Tags</div>
                    <div className='subHeads'>Enter Tags</div>
                    <div className='searchBar'>
                      <input
                        type='text'
                        value={key}
                        onChange={handleChange}
                        className='keySearch'
                        placeholder='Drugs, Data, etc'
                        onKeyPress={(event) =>
                          event.key === 'Enter' && handleAddKeys(event, key)
                        }
                      />
                      <button
                        onClick={(event) => handleAddKeys(event, key)}
                        className='addKeywordBtn'
                      >
                        Add
                      </button>
                    </div>
                    <div className='allKeys'>
                      <div className='subHeads'>Tags</div>
                      <div>
                        Click on the tag boxes to remove the selected tags
                      </div>
                      <div className='keyList'>
                        {selectedKeys.map((key) => (
                          <div
                            onClick={(event) =>
                              handleRemoveKeys(event, { key })
                            }
                            className='keys'
                          >
                            {key}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='btnContainer'>
                      <button
                        onClick={(event) => handleUpdateTags(event)}
                        className='modalBtnConfirm'
                      >
                        Confirm
                      </button>
                      <button
                        onClick={(event) => {
                          setShowEditTagsPopup(!showEditTagsPopup);
                        }}
                        className='modalBtnConfirm'
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}
          {exportSuccessfull === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setexportSuccessfull(false);
              }}
            >
              <div
                className=''
                style={{ borderRadius: '10px', padding: 0 }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box className='' id='keywordsAdd'>
                  <div>
                    <span
                      style={{
                        fontSize: '20px',
                        color: '#223036',
                        fontWeight: 500,
                        marginBottom: '20px',
                      }}
                    >
                      Are you sure you want to blacklist this channel?
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          cursor: 'pointer',
                          margin: '0px 10px',
                          width: 'fit',
                          color: 'white',
                          backgroundColor: '#00ACFB',
                          borderRadius: '10px',
                          padding: '1% 4%',
                        }}
                        onClick={() => {
                          setexportSuccessfull(!exportSuccessfull);
                        }}
                      >
                        Yes
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          cursor: 'pointer',
                          margin: '0px 10px',
                          width: 'fit',
                          backgroundColor: 'white',
                          border: '1px solid #00ACFB',
                          borderRadius: '10px',
                          color: '#00ACFB',
                          padding: '1% 4%',
                        }}
                        onClick={() => {
                          setexportSuccessfull(!exportSuccessfull);
                        }}
                      >
                        No
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {forwardDetailsPopUp === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setforwardDetailsPopUp(false);
              }}
              style={{ zIndex: '99999990' }}
            >
              <div
                className=''
                style={{
                  borderRadius: '10px',
                  padding: 0,
                  backgroundColor: 'white',
                  padding: '2%',
                  width: '35%',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box className='' id='keywordsAdd'>
                  <div>
                    <span
                      style={{
                        fontSize: '24px',
                        color: '#223036',
                        fontWeight: 700,
                        marginBottom: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Forwarded Information
                    </span>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        justifyContent: 'start',
                        alignItems: 'center',
                        marginBottom: '20px',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '5px 8px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            color: '#223036',
                            fontWeight: 600,
                          }}
                        >
                          Source Type
                        </span>
                        <span style={{ margin: '0px 10px' }}>:</span>
                        <span>{forwardedData.fwd_source_type_str}</span>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '5px 8px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            color: '#223036',
                            fontWeight: 600,
                          }}
                        >
                          Source ID
                        </span>
                        <span style={{ margin: '0px 10px' }}>:</span>
                        <span>{forwardedData.fwd_source_id}</span>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '5px 8px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            color: '#223036',
                            fontWeight: 600,
                          }}
                        >
                          Timestamp
                        </span>
                        <span style={{ margin: '0px 10px' }}>:</span>
                        <span>{forwardedData.fwd_timestamp}</span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          cursor: 'pointer',
                          margin: '0px 10px',
                          width: 'fit',
                          color: 'white',
                          backgroundColor: '#00ACFB',
                          borderRadius: '10px',
                          padding: '2% 4%',
                        }}
                        onClick={() => {
                          setforwardDetailsPopUp(!forwardDetailsPopUp);
                          setMentionsPop(true);
                        }}
                      >
                        Fetch Information
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          cursor: 'pointer',
                          margin: '0px 10px',
                          width: 'fit',
                          backgroundColor: 'white',
                          border: '1px solid #00ACFB',
                          borderRadius: '10px',
                          color: '#00ACFB',
                          padding: '2% 4%',
                        }}
                        onClick={() => {
                          setforwardDetailsPopUp(!forwardDetailsPopUp);
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {historyPopUp === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setHistoryPopUp(!historyPopUp);
              }}
            >
              <div className='' onClick={(e) => e.stopPropagation()}>
                <Box
                  className=''
                  sx={style}
                  id='keywordsAdd'
                  style={{ padding: '2% 2%', height: 'fit' }}
                >
                  <div
                    className='scrollbar'
                    style={{
                      // height: 'auto',
                      overflow: 'scroll',
                      overflowX: 'hidden',
                      scrollbarWidth: 'thin',
                      width: '100%',
                      padding: '2% 5%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ fontSize: '28px', fontWeight: 700 }}>
                        {historyDialogData?.cname}
                      </span>
                      {historyDialogData?.forwarded ? (
                        <div
                          style={{
                            marginLeft: '10px',
                            color: '#898F9A',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            alt='Message Link'
                            style={{ height: '2vh', marginLeft: '5px' }}
                            src={forward}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '2px',
                          color: '#898F9A',
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>Sent By:</span>
                        <span style={{ marginLeft: '2px' }}>
                          @{historyDialogData.entity_user_name}{' '}
                          {historyDialogData?.id}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '15px',
                          color: '#898F9A',
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>Entity ID:</span>
                        <span style={{ marginLeft: '2px' }}>
                          {historyDialogData?.id}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',

                        flexDirection: 'row',
                        alignItems: 'start',
                        alignContent: 'start',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',

                          marginRight: '10px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            marginRight: '10px',
                            width: '130px',
                          }}
                        >
                          Last Edited Message
                        </span>
                        <span style={{ fontSize: '12px', color: '#898F9A' }}>
                          {/* {console.log(historyDialogData.entireMessage.edited_timestamp["$date"])} */}
                          {formatDate(
                            historyDialogData.entireMessage.edited_timestamp[
                              '$date'
                            ]
                          )}
                        </span>
                      </div>
                      <span style={{ marginRight: '5px' }}>:</span>
                      <p
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          textAlign: 'justify',
                          fontSize: '14px',
                          marginLeft: '10px',
                        }}
                      >
                        {historyDialogData.content}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',

                        flexDirection: 'row',
                        alignItems: 'start',
                        alignContent: 'start',
                        margin: '10px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            marginRight: '35px',
                          }}
                        >
                          Number of Edits
                        </span>
                      </div>
                      <span style={{ marginRight: '15px' }}>:</span>
                      <p
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {historyDialogData?.content_history?.length}
                      </p>
                    </div>
                    {historyDialogData &&
                      historyDialogData?.content_history?.map((item, index) => (
                        <div
                          style={{
                            display: 'flex',

                            flexDirection: 'row',

                            margin: '10px 0px',
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',

                              marginRight: '10px',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                marginRight: '10px',
                                width: '130px',
                              }}
                            >
                              {index == 0
                                ? 'Original Message'
                                : 'Edited Message'}
                            </span>
                            <span
                              style={{ fontSize: '12px', color: '#898F9A' }}
                            >
                              {formatDate(item.created_at.$date)}
                            </span>
                          </div>
                          <span style={{ marginRight: '5px' }}>:</span>
                          <p
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              textAlign: 'justify',
                              fontSize: '14px',
                              marginLeft: '10px',
                            }}
                          >
                            {item.text_content}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      marginTop: '15px',
                    }}
                    onClick={() => setHistoryPopUp(!historyPopUp)}
                  >
                    <button
                      className='modalBtnConfirm'
                      style={{ width: '10%' }}
                    >
                      Okay
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {showEditTagsPopup2 === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setShowEditTagsPopup2(false);
              }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box className='Box' sx={style} id='keywordsAdd'>
                  <div className='keywordsModal'>
                    <div className='centerHead'>
                      Edit Tags for the selected messages
                    </div>
                    <div className='subHeads'>Enter Tags</div>
                    {selectedMessages?.length > 10 ||
                    (selectedQuery && totCount > 10) ? (
                      <div
                        style={{
                          paddingTop: '1%',
                          color: '#E9D502',
                          fontSize: 'small',
                        }}
                      >
                        Warning: You are about to add tags to{' '}
                        {selectedMessages?.length > 0
                          ? selectedMessages?.length
                          : selectedQuery
                          ? totCount
                          : 0}{' '}
                        messages, and it cant be reversed in bulk!{' '}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className='searchBar'>
                      <input
                        type='text'
                        onChange={handleChange}
                        className='keySearch'
                        placeholder='Drugs, Data, etc'
                        onKeyPress={(event) =>
                          event.key === 'Enter' &&
                          handleAddKeysMultiple(event, key)
                        }
                      />
                      <button
                        onClick={(event) => handleAddKeysMultiple(event, key)}
                        className='addKeywordBtn'
                      >
                        Add
                      </button>
                    </div>
                    <div className='allKeys'>
                      <div className='subHeads'>Tags</div>
                      <div>
                        Click on the tag boxes to remove the selected tags
                      </div>
                      <div className='keyList'>
                        {selectedKeys.map((key) => (
                          <div
                            onClick={(event) =>
                              handleRemoveKeys(event, { key })
                            }
                            className='keys'
                          >
                            {key}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='btnContainer'>
                      <button
                        onClick={(event) => handleUpdateTagsMultiple(event)}
                        className='modalBtnConfirm'
                      >
                        Confirm
                      </button>
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          setShowEditTagsPopup2(!showEditTagsPopup2);
                        }}
                        className='modalBtnConfirm'
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {downloadPopUp === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setDownloadPopUp(false);
              }}
              style={{ zIndex: '99999991' }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box className='Box' sx={style} id='keywordsAdd'>
                  <div
                    style={{
                      margin: '10px 0px',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  >
                    <span style={{ marginRight: '10px' }}>Note:</span>
                    <input
                      style={{
                        border: '1px solid',
                        borderRadius: '4px',
                        width: '100%',
                      }}
                      value={notes}
                      onChange={({ target }) => {
                        setNotes(target.value);
                      }}
                    />
                  </div>
                  <div className='btnContainer'>
                    <button
                      onClick={(event) => {
                        handleDownload(event);
                        setDownloadPopUp(!downloadPopUp);
                      }}
                      className='modalBtnConfirm'
                    >
                      Confirm
                    </button>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setDownloadPopUp(!downloadPopUp);
                      }}
                      className='modalBtnConfirm'
                    >
                      Close
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
            style={{ width: '17%', fontWrap: 'none', textAlign: 'left' }}
          />
          {/* Same as */}
          {/* <ToastContainer /> */}

          {selectAllPopup === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setSelectAllPopup(false);
              }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box
                  className='Box'
                  sx={style_select}
                  id='keywordsAdd'
                  style={{ marginInline: '37%', marginBlock: '20%' }}
                >
                  <div className='keywordsModal'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '3%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h5>Please select one of the following option</h5>
                      <button
                        style={{
                          maxWidth: 'fit-content',
                          background: 'none',
                          color: '#000000',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setSelectAllPopup(false);
                        }}
                      >
                        x
                      </button>
                    </div>

                    <div className='btnContainer'>
                      <button
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          cursor: 'pointer',
                          margin: '0px 10px',
                          width: 'fit',
                          backgroundColor: '#00ACFB',
                          borderRadius: '10px',
                          color: 'white',
                          padding: '1% 4%',
                        }}
                        onClick={(event) => {
                          setSelectAllPopup(false);
                          handleSelectCurrent(event);
                        }}
                        className='modalBtnConfirm'
                      >
                        Current Page
                      </button>
                      <button
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          cursor: 'pointer',
                          margin: '0px 10px',
                          width: 'fit',
                          backgroundColor: 'white',
                          border: '1px solid #00ACFB',
                          borderRadius: '10px',
                          color: '#00ACFB',
                          padding: '1% 4%',
                        }}
                        onClick={(event) => {
                          setSelectAllPopup(false);
                          handleSelectThroughAll(event);
                        }}
                        className='modalBtnConfirm'
                      >
                        All pages
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {exportedPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setExportedPop(false);
              }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box
                  className='Box'
                  sx={style_select}
                  id='keywordsAdd'
                  style={{
                    width: '30%',
                    marginInline: '37%',
                    marginBlock: '20%',
                  }}
                >
                  <div className='keywordsModal'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '3%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h5>
                        Are you sure you want to export the selected{' '}
                        {selectedMessages?.length > 0
                          ? selectedMessages?.length
                          : selectedQuery
                          ? totCount
                          : 0}{' '}
                        messages
                      </h5>
                      <button
                        style={{
                          maxWidth: 'fit-content',
                          background: 'none',
                          color: '#000000',
                        }}
                        onClick={(event) => {
                          event.preventDefault();

                          setExportedPop(false);
                        }}
                      >
                        x
                      </button>
                    </div>
                    <div className='checks'>
                      <input
                        type='checkbox'
                        value='download media check'
                        checked={downloadMediaCheck}
                        onChange={() =>
                          setDownloadMediaCheck(!downloadMediaCheck)
                        }
                      />
                      <div className='tagItem'>
                        Download Media (Image Files)
                      </div>
                      <input
                        type='checkbox'
                        value='download media check'
                        checked={downloadMediaCheckAll}
                        onChange={() =>
                          setDownloadMediaCheckAll(!downloadMediaCheckAll)
                        }
                        style={{ marginLeft: 'auto' }}
                      />
                      <div className='tagItem'>Download Media (All Files)</div>
                    </div>
                    <div className='checks'>
                      <input
                        type='checkbox'
                        value='download media check'
                        checked={excelCheck}
                        onChange={() => setExcelCheck(!excelCheck)}
                      />
                      <div className='tagItem'>as excel</div>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '4%' }}>
                      <input
                        style={{ width: '100%', height: '4vh' }}
                        type='text'
                        value={exportNote}
                        onChange={handleExportChange}
                        className='keySearch'
                        placeholder='Add note to your exported file (Optional)'
                        onKeyPress={(event) =>
                          event.key === 'Enter' && handleExport(event)
                        }
                      />
                    </div>
                    <div className='btnContainer'>
                      <button
                        style={{
                          minWidth: 'fit-content',
                          fontSize: 'large',
                          height: 'fit-content',
                          paddingBlock: '1%',
                        }}
                        onClick={(event) => {
                          setExportedPop(false);
                          handleExport(event);
                        }}
                        className='modalBtnConfirm'
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          minWidth: 'fit-content',
                          fontSize: 'large',
                          height: 'fit-content',
                          paddingBlock: '1%',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setExportedPop(false);
                          setExportNote('');
                        }}
                        className='modalBtnConfirm'
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {ShowMarkAsReadPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setShowMarkAsReadPop(false);
              }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box
                  className='Box'
                  sx={style_select}
                  id='keywordsAdd'
                  style={{
                    width: '30%',
                    marginInline: '37%',
                    marginBlock: '20%',
                  }}
                >
                  <div className='keywordsModal'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '3%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h5>
                        Are you sure you want to mark{' '}
                        {selectedMessages?.length > 0
                          ? selectedMessages?.length
                          : selectedQuery
                          ? totCount
                          : 0}{' '}
                        messages as read
                      </h5>
                      <button
                        style={{
                          maxWidth: 'fit-content',
                          background: 'none',
                          color: '#000000',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setShowMarkAsReadPop(false);
                        }}
                      >
                        x
                      </button>
                    </div>
                    <div className='btnContainer'>
                      <button
                        style={{
                          minWidth: 'fit-content',
                          fontSize: 'large',
                          height: 'fit-content',
                          paddingBlock: '1%',
                        }}
                        onClick={(event) => {
                          setShowMarkAsReadPop(false);
                          handleReadUnread(selectedMessages);
                        }}
                        className='modalBtnConfirm'
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          minWidth: 'fit-content',
                          fontSize: 'large',
                          height: 'fit-content',
                          paddingBlock: '1%',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setShowMarkAsReadPop(false);
                          // setExportNote('');
                        }}
                        className='modalBtnConfirm'
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {downloadPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setDownloadPop(false);
              }}
              style={{ zIndex: '99999991' }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box
                  className='Box'
                  sx={style_select}
                  id='keywordsAdd'
                  style={{
                    width: '30%',
                    marginInline: '37%',
                    marginBlock: '20%',
                  }}
                >
                  <div className='keywordsModal'>
                    <div
                      style={{
                        margin: '10px 0px',
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      <span style={{ marginRight: '10px' }}>Note:</span>
                      <input
                        style={{
                          border: '1px solid',
                          borderRadius: '4px',
                          width: '100%',
                        }}
                        value={notes}
                        onChange={({ target }) => {
                          setNotes(target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '3%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h5>Are you sure you want to download this file?</h5>
                      <button
                        style={{
                          maxWidth: 'fit-content',
                          background: 'none',
                          color: '#000000',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setDownloadPop(false);
                        }}
                      >
                        x
                      </button>
                    </div>

                    <div className='btnContainer'>
                      <button
                        style={{
                          minWidth: 'fit-content',
                          fontSize: 'large',
                          height: 'fit-content',
                          paddingBlock: '1%',
                        }}
                        onClick={(event) => {
                          setDownloadPop(false);
                          handleDownload(event);
                        }}
                        className='modalBtnConfirm'
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          minWidth: 'fit-content',
                          fontSize: 'large',
                          height: 'fit-content',
                          paddingBlock: '1%',
                        }}
                        onClick={(event) => {
                          setDownloadPop(false);
                        }}
                        className='modalBtnConfirm'
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {forwardedPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setForwardedPop(false);
              }}
            >
              <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
                <Box
                  className='Box'
                  sx={style_select}
                  id='keywordsAdd'
                  style={{
                    width: '30%',
                    marginInline: '37%',
                    marginBlock: '20%',
                  }}
                >
                  <div className='keywordsModal'>
                    {console.log(forwardedRow.forwarded_details)}
                    {forwardedRow.forwarded_details.fwd_timestamp ? (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            marginBottom: '3%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: 'bolder',
                              fontSize: 'larger',
                              width: 'fit-content',
                            }}
                          >
                            Sample Name
                          </div>
                          <button
                            style={{
                              maxWidth: 'fit-content',
                              background: 'none',
                              color: '#000000',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setForwardedPop(false);
                            }}
                          >
                            x
                          </button>
                        </div>
                        {forwardedRow.forwarded_details.fwd_source_type ? (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <div
                              style={{
                                fontWeight: 'bold',
                                width: 'fit-content',
                              }}
                            >
                              Forwarded Source Type :
                            </div>
                            <div style={{ width: 'fit-content' }}>
                              {forwardedRow.forwarded_details.fwd_source_type}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {forwardedRow.forwarded_details.fwd_timestamp ? (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <div
                              style={{
                                fontWeight: 'bold',
                                width: 'fit-content',
                              }}
                            >
                              Forwarded Time :
                            </div>
                            <div style={{ width: 'fit-content' }}>
                              {formatDate(
                                forwardedRow.forwarded_details.fwd_timestamp
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          fontWeight: 'bold',
                          marginTop: '3%',
                          textAlign: 'center',
                          fontSize: 'larger',
                        }}
                      >
                        Sorry! No details available
                      </div>
                    )}
                    <div className='btnContainer' style={{ marginTop: '2%' }}>
                      <button
                        style={{
                          maxWidth: 'fit-content',
                          fontSize: 'medium',
                          height: 'fit-content',
                          paddingBlock: '1%',
                          paddingInline: '2%',
                        }}
                        onClick={(event) => {
                          setForwardedPop(false);
                        }}
                        className='modalBtnConfirm'
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {mentionsPop ? (
            <div
              className='backdropModal'
              onClick={() => {
                setMentionsPop(false);
              }}
              style={{ zIndex: '99999991' }}
            >
              {console.log(mentionsData)}
              <div style={popupStyle} onClick={(e) => e.stopPropagation()}>
                {mentionsData ? (
                  mentionsData.query_error_channel ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        {mentionsData.query_error_channel ===
                        "This is not a channel, entity type: <class 'telethon.tl.types.InputPeerUser'>"
                          ? 'This is not a channel. This is a user.'
                          : mentionsData.query_error_channel}
                      </div>
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          setMentionsData();
                          setMentionsPop(false);
                        }}
                        style={{
                          marginTop: '4%',
                          width: 'fit-content',
                          paddingInline: '3%',
                          paddingBlock: '2%',
                          height: 'max-content',
                          borderRadius: '5px',
                          fontSize: 'medium',
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1vh',
                      }}
                    >
                      {console.log(
                        'the detals in mentionds Data : ',
                        mentionsData
                      )}
                      <div style={{ fontSize: 'larger', fontWeight: 'bold' }}>
                        {mentionsData.channel_info.channel_name}
                      </div>
                      <div style={{ width: '100%' }}>
                        <span
                          style={{
                            fontSize: 'medium',
                            fontWeight: 'bold',
                            marginRight: '2%',
                          }}
                        >
                          Created at:
                        </span>

                        {formatDateStamp(
                          mentionsData.channel_info.channel_created_at_timestamp
                        )}
                      </div>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                          Description:
                        </span>

                        {mentionsData.channel_info.channel_description}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '3%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Participant Count:
                          </div>
                          <div>
                            {mentionsData.channel_info.participants_count}
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Type:
                          </div>
                          <div>
                            {mentionsData.channel_info.is_channel
                              ? 'Channel'
                              : mentionsData.channel_info.is_group
                              ? 'Group'
                              : 'Not Clear'}
                          </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Scam:
                          </div>
                          <div>
                            {mentionsData.channel_info.scam ? 'Yes' : 'No'}
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Verified:
                          </div>
                          <div>
                            {mentionsData.channel_info.verified ? 'Yes' : 'No'}
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Fake:
                          </div>
                          <div>
                            {mentionsData.channel_info.fake ? 'Yes' : 'No'}
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Expected Messages Count:
                          </div>
                          <div>
                            {mentionsData.channel_info.expected_messages_count}
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              fontSize: 'large',
                              fontWeight: 'bold',
                              marginRight: '3px',
                            }}
                          >
                            Joined:
                          </div>
                          <div style={{ color: 'black' }}>
                            {
                              <Button
                                disabled={mentionsData.channel_info.is_joined}
                                style={
                                  mentionsData.channel_info.is_joined === false
                                    ? {
                                        border: '1px solid #00ACFB',
                                        width: '100%',
                                      }
                                    : {
                                        border: '1px solid #898F9A',
                                        color: '#898F9A',
                                        width: '100%',
                                      }
                                }
                                onClick={() => {
                                  showJoinConfirmation(
                                    mentionsData.channel_info,
                                    1
                                  );
                                  // setShowJoinPop(true);
                                  // setMentionsPop(false);
                                }}
                              >
                                {' '}
                                {mentionsData.channel_info.is_joined
                                  ? 'Joined'
                                  : 'Join'}
                              </Button>
                            }
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ fontSize: 'large', fontWeight: 'bold' }}
                          >
                            Username:
                          </div>
                          <div>
                            {mentionsData.channel_info.channel_user_name}
                          </div>
                        </div>
                      </div>

                      <div
                        className='finalising_div'
                        style={{ marginTop: '2vh', gap: '3%', width: '100%' }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            setMentionsData();
                            setMentionsPop(false);
                          }}
                          className='btnCreate'
                        >
                          OK
                        </button>
                        <a
                          className='btnCancel'
                          href={`https://t.me/${mentionsData.channel_info.channel_user_name}`}
                          target='_blank'
                          rel='noreferrer'
                          title='Telegram Link'
                          style={{ textDecoration: 'none' }}
                        >
                          Open In Telegram
                        </a>
                      </div>

                      {/* <button onClick={(event)=>{event.preventDefault(); setMentionsData(); setMentionsPop(false)}} style={{width:'fit-content', fontSize:'medium', height:'10%',paddingInline:'2%', borderRadius:'4px'}}>OK</button> */}
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className='tab'>
            {importPop === true ? (
              <div
                className='backdropModal'
                onClick={() => {
                  setImportPop(false);
                }}
              >
                <Box
                  className='Box'
                  style={{ maxWidth: 'fit-content', padding: '20px' }}
                  sx={style}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className='keywordsModal'>
                    <div>
                      Please select one of the following options for the
                      messages of the channel before joining.
                    </div>
                    <div
                      className='finalising_div'
                      style={{ marginTop: '2vh' }}
                    >
                      <button
                        className='btnCreate'
                        onClick={(event) => {
                          changeImportAllValue(
                            selectedJoinChannel,
                            selectedJoinChannelIndex
                          );
                          setImportPop(false);
                        }}
                      >
                        Import All existing
                      </button>
                      <button
                        className='btnCreate'
                        onClick={(event) => {
                          changeValue(
                            selectedJoinChannel,
                            selectedJoinChannelIndex
                          );
                          setImportPop(false);
                        }}
                      >
                        Import future messages only
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='tab'>
            {showJoinPop === true ? (
              <div
                className='backdropModal'
                onClick={() => {
                  setShowJoinPop(false);
                }}
              >
                <Box
                  className='Box'
                  style={{ maxWidth: 'fit-content', padding: '20px' }}
                  sx={style}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className='keywordsModal'>
                    <div>Are you sure you want to join </div>
                    <div
                      className='finalising_div'
                      style={{ marginTop: '2vh' }}
                    >
                      <button
                        className='btnCreate'
                        onClick={(event) => {
                          setImportPop(true);
                          setShowJoinPop(false);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className='btnCreate'
                        onClick={(event) => {
                          setShowJoinPop(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Box>
              </div>
            ) : (
              <></>
            )}
          </div>
          {messageDetailsPop ? (
            <div
              className='backdropModal'
              onClick={() => {
                setMessageDetailsPop(false);
              }}
            >
              <div className='TagsPopup ' onClick={(e) => e.stopPropagation()}>
                <Box
                  className=''
                  sx={style}
                  id='keywordsAdd'
                  style={{ padding: '2% 2%', width: 'fit' }}
                >
                  <div
                    className='scrollbar'
                    style={{
                      // height: 'fit-content',
                      width: '100%',
                      padding: '3%',
                      // overflowX: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        className=''
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyItems: 'start',
                          width: '100%',
                        }}
                      >
                        <span style={{ fontSize: '24px', fontWeight: 700 }}>
                          <Link
                            style={{
                              fontFamily: 'Roboto',
                              width: 'max-content',
                            }}
                            className='channel_link'
                            to={`/channels?channel_Id=${messageDetails?.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <div
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '300px',
                                display: 'block',
                                overflow: 'hidden',
                              }}
                            >
                              {messageDetails?.cname}
                            </div>
                          </Link>
                        </span>
                        {messageDetails?.forwarded ? (
                          <div
                            style={{
                              color: '#898F9A',
                              cursor: 'pointer',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setForwardedRow(messageDetails);
                              handleMentions(
                                messageDetails?.forwarded_details?.fwd_source_id
                              );
                              setForwardedPop(false);
                            }}
                          >
                            <img
                              alt='Message Link'
                              style={{ height: '2vh' }}
                              src={forward}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'end',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: '1px solid #00ACFB',
                            borderRadius: '5px',
                            color: '#00ACFB',
                            padding: '2% 5%',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleCopyClick(messageDetails?.content)
                          }
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              color: '#00ACFB',
                              marginRight: '5px',
                            }}
                          >
                            Message
                          </span>
                          <img src={Notes} />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: '1px solid #00ACFB',
                            borderRadius: '5px',
                            color: '#00ACFB',
                            padding: '2% 5%',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleCopyClick_json(
                              JSON.stringify(messageDetails?.entireMessage)
                            )
                          }
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              color: '#00ACFB',
                              marginRight: '5px',
                            }}
                          >
                            JSON
                          </span>
                          <img src={Notes} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '2px',
                          color: '#898F9A',
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>Sent By:</span>
                        <span style={{ marginLeft: '2px' }}>
                          @{messageDetails?.entity_user_name}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '15px',
                          color: '#898F9A',
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>
                          {formatDate(messageDetails?.date)}
                        </span>
                        {messageDetails?.is_edited ? (
                          <div
                            style={{
                              marginLeft: '10px',
                              color: '#898F9A',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              setMessageDetailsPop(false);
                              setMessageDetails();
                              setHistoryPopUp(!historyPopUp);
                              setHistoryDialogData(messageDetails);
                            }}
                          >
                            <span>(EDITED)</span>
                            <img
                              alt='Message Link'
                              style={{ height: '2vh', marginLeft: '5px' }}
                              src={Edited}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {messageDetails?.is_deleted ? (
                          <div
                            style={{
                              marginLeft: '10px',
                              color: '#898F9A',
                              // cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            // onClick={() => {
                            //   setMessageDetailsPop(false);
                            //   setMessageDetails();
                            //   setHistoryPopUp(!historyPopUp);
                            //   setHistoryDialogData(messageDetails);
                            // }}
                          >
                            <span
                              style={{
                                marginLeft: '5px',
                                color: 'red',
                                fontWeight: 700,
                              }}
                            >
                              (DELETED)
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            width: 'fit',
                            marginRight: '15px',
                          }}
                        >
                          Message
                        </span>
                        <span
                          style={{
                            fontSize: '12px',
                            color: '#898F9A',
                            marginLeft: '15px',
                          }}
                        >
                          :{}
                        </span>
                      </div>
                      <span style={{ marginRight: '15px' }}>:</span>
                      <p
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          textAlign: 'justify',
                          fontSize: '14px',
                          maxHeight: '50vh',
                          overflow: 'auto',
                        }}
                      >
                        {messageDetails?.content}
                      </p>
                    </div>
                    {messageDetails?.tags?.length > 0 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          flexDirection: 'row',
                          alignItems: 'start',
                          alignContent: 'start',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              width: 'fit',
                              marginRight: '15px',
                            }}
                          >
                            Tags
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#898F9A',
                              marginLeft: '15px',
                            }}
                          >
                            :{}
                          </span>
                        </div>
                        <span style={{ marginRight: '15px' }}>:</span>
                        <p
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'justify',
                            fontSize: '14px',
                          }}
                        >
                          {messageDetails?.tags}
                        </p>
                      </div>
                    )}
                    {messageDetails?.mention?.length > 0 && (
                      <div
                        style={{
                          display: 'flex',

                          flexDirection: 'row',

                          margin: '12px 0px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              width: 'fit',
                              marginRight: '15px',
                            }}
                          >
                            Mentions
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#898F9A',
                              marginLeft: '15px',
                            }}
                          >
                            :
                          </span>
                        </div>
                        <span style={{ marginRight: '15px' }}>:</span>
                        <div style={{ width: '100%' }}>
                          {messageDetails?.mention?.length > 0 ? (
                            <div className='' style={{}}>
                              {messageDetails?.mention?.map((mentioned, i) => (
                                <div
                                  className='crypoBox'
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: '5px',
                                  }}
                                  onClick={(event) => {
                                    // event.preventDefault();
                                    handleMentionsNew(mentioned);
                                  }}
                                >
                                  <Link
                                    style={{ fontFamily: 'Roboto' }}
                                    className='job_link_internal_page'
                                  >
                                    {mentioned}
                                  </Link>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                    {messageDetails?.urls > 0 && (
                      <div
                        style={{
                          display: 'flex',

                          flexDirection: 'row',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              width: 'fit',
                              marginRight: '15px',
                            }}
                          >
                            URLs
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#898F9A',
                              marginLeft: '15px',
                            }}
                          >
                            {messageDetails?.urls &&
                            Object.keys(messageDetails?.urls)?.length != 0 ? (
                              <div className='cardCrypto'>
                                <div className='detsHead'>Urls</div>
                                {Object.keys(messageDetails?.urls)?.map((key) => (
                                  <div className='cryptoBoxes' key={key}>
                                    <p>{key}:</p>

                                    {messageDetails?.urls[key]?.map((value) => (
                                      <div className='cryptoBox' key={value}>
                                        <a
                                          href={value}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                        >
                                          {value}
                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                        <span style={{ marginRight: '15px' }}>:</span>
                      </div>
                    )}
                    {messageDetails?.file?.media_present && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          flexDirection: 'row',

                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '14px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              width: 'fit',
                              marginRight: '47px',
                            }}
                          >
                            Files
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#898F9A',
                              marginLeft: '15px',
                            }}
                          >
                            :{}
                          </span>
                        </div>
                        <span style={{ marginRight: '15px' }}>:</span>
                        <p
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'justify',
                            fontSize: '14px',
                          }}
                        >
                          {messageDetails?.file?.media_present === true ? (
                            <div
                              className=''
                              style={{
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span style={{ width: '100%' }}>
                                {messageDetails?.file?.media_name}
                              </span>
                              {console.log(messageDetails)}
                              {messageDetails?.file.storage_link ? (
                                <>
                                  <div
                                    className='file'
                                    onClick={() =>
                                      downloadJsonFile(
                                        messageDetails?.file.storage_link
                                      )
                                    }
                                  >
                                    <img
                                      alt='download'
                                      style={{
                                        height: '2vh',
                                        cursor: 'pointer',
                                      }}
                                      src={download_icon}
                                    />
                                  </div>
                                  <div
                                    className='Downloaded_text'
                                    style={{
                                      background: '#ebf9ff',
                                      paddingInline: '2px',
                                      marginLeft: '2px',
                                    }}
                                  >
                                    Downloaded
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    style={{
                                      backgroundColor: 'white',
                                    }}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      setDownloadRowSelected(messageDetails);
                                      setDownloadPop(true);
                                    }}
                                  >
                                    <img
                                      alt='download'
                                      style={{ height: '2vh' }}
                                      src={CLoudDownload}
                                    />
                                  </button>
                                </>
                              )}
                              {/* <button
                                style={{
                                  backgroundColor: 'white',
                                }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setDownloadRowSelected(messageDetails);
                                  setDownloadPop(true);
                                }}
                              >
                                <img
                                  alt='download'
                                  style={{ height: '2vh' }}
                                  src={download_icon}
                                />
                              </button> */}
                            </div>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      marginTop: '15px',
                    }}
                  >
                    <button
                      style={{
                        width: '25%',
                        borderRadius: '10px',
                        fontSize: '16px',
                      }}
                      onClick={() =>
                        handleDashboardCopyLink(messageDetails?.ref_id)
                      }
                    >
                      Copy Message Link
                    </button>
                    <button
                      style={{
                        width: '10%',
                        borderRadius: '10px',
                        fontSize: '16px',
                        backgroundColor: 'white',
                        color: '#00ACFB',
                        border: '2px solid #00ACFB',
                        marginLeft: '10px',
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        setMessageDetailsPop(false);
                        setMessageDetails();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}

          {loading || sample_rows?.length === 0 ? (
            <></>
          ) : (
            <div
              className='paginateMessages'
              style={{
                display: 'flex',
                // width: '86%',
                justifyContent: 'space-between',
                // marginLeft: '6.8%',
              }}
            >
              <div className='display_pages' style={{ marginLeft: '0px' }}>
                Showing &nbsp;<span>{(page - 1) * det + 1}</span>-
                <span>
                  {(page - 1) * det + parseInt(entries) < totCount
                    ? (page - 1) * det + parseInt(entries)
                    : totCount}
                </span>
                &nbsp;from&nbsp;<span>{totCount.toLocaleString()}</span>
                &nbsp;Message
              </div>
              <div className='nav_container' style={{ marginLeft: '0px' }}>
                <Stack spacing={2}>
                  <Pagination
                    shape='rounded'
                    count={pc}
                    color='primary'
                    value={page}
                    onChange={handlePage}
                    variant='outlined'
                    sx={{ fontFamily: "'Fredoka', sans-serif" }}
                  />
                </Stack>
              </div>
              <div className='entries' style={{ marginLeft: '0px' }}>
                <span style={{ marginRight: '8px' }}>Number of Entries:</span>
                <select
                  style={{
                    backgroundColor: '#F7F9FB',
                    padding: '3px',
                    border: '2px solid #B7B7B7',
                    borderRadius: '5px',
                    outline: 'none',
                    color: '#B7B7B7',
                  }}
                  defaultValue={entries}
                  onChange={(e) => {
                    setEntries(e.target.value);
                    const t = handleFinalKeys();
                    setPage(1);
                    let message = {
                      attributes: metaData,
                      pageNo: 1,
                      limitPerPage: e.target.value,
                      searchText: t,
                      tags: messageData,
                      // "flag": dateFlag,
                      fromDate: fromDate,
                      toDate: toDate,
                    };
                    if (channelId) {
                      message = {
                        entity_id: channelId,
                        attributes: metaData,
                        pageNo: 1,
                        limitPerPage: e.target.value,
                        searchText: t,
                        tags: messageData,
                        // "flag": dateFlag,
                        fromDate: fromDate,
                        toDate: toDate,
                      };
                    }
                    setLoading(true);
                    test_c_temp(e.target.value, message);
                    // test_c(message);
                  }}
                >
                  <option value='10' style={{ color: 'black' }}>
                    10
                  </option>
                  <option value='20' style={{ color: 'black' }}>
                    20
                  </option>
                  <option value='60' style={{ color: 'black' }}>
                    60
                  </option>
                  <option value='80' style={{ color: 'black' }}>
                    80
                  </option>
                  <option value='100' style={{ color: 'black' }}>
                    100
                  </option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import axios from 'axios'

import instance from './interceptor';
export const message_monitor_notifications = async(data)=>{
  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
  let res = []
console.log('message monitor notification payload : ',data);
console.log('hiting endpoint channel notification rule');
    await instance.post(`${apiUrl}/api/message_monitor_notifications`, data, config)
    .then(response=>{
      console.log('hit');
        res = response.data;
        console.log('message monitor notification response',response.data)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }
import axios from 'axios'
// import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const instance = axios.create();

instance.interceptors.request.use((config) => {   
    const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
        const ciphertext = Cookies.get('token');  
        const secretKey = 'some_secret_key';   
        const bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), secretKey); 
        const token = bytes.toString(CryptoJS.enc.Utf8);   
        config.headers= apiHeaders;
        // config.withCredentials = true; 
        config.headers["x-access-token"] = token;
        return config;
    }
);

export default instance;
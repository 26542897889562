import React from 'react'
import "../css/analytics_new.css"
import { useState } from 'react';
import { useEffect } from 'react';
import { CountUpAnimation } from './countUpAnimation';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from "recharts";

import { PieChart, Pie } from "recharts";
import { BarChart, Bar } from 'recharts';
import { fetch_analytics_data } from '../endpoints/analytics';
import CountUp from 'react-countup';


export const AnalyticsNew = () => {
	const [dataA1G1, setDataA1G1] = useState([])
	const [dataA1G2E1, setDataA1G2E1] = useState(0)
	const [dataA1G2E2, setDataA1G2E2] = useState(0)
	const [dataA2G1, setDataA2G1] = useState([])
	const [dataA2G2, setDataA2G2] = useState([])
	const [dataA2G3, setDataA2G3] = useState([])
	const [dataA3G1, setDataA3G1] = useState(0)
	const [dataA3G2, setDataA3G2] = useState(0)
	const [dataA3G3, setDataA3G3] = useState(0)
	const [dataA3G4, setDataA3G4] = useState(0)
	
	
	useEffect(()=>{
		callAnalyticsApi()
	}, []);

	const callAnalyticsApi = async() =>{
		let res = await fetch_analytics_data()
		console.log(res)
		let finalRes = res["dbRes"]

		let channels_joined_7_days = finalRes["channels_joined_7_days"]
		var test_arr = []
		for (let i = channels_joined_7_days.length - 1; i >= 0; i--){
			test_arr.push({"name": channels_joined_7_days[i]["day"], "Channels/Groups": channels_joined_7_days[i]["enitities_count"]})
		}
		console.log(test_arr)
		setDataA1G1(test_arr)

		setDataA1G2E1(finalRes["channels_count_30_days"])

		setDataA1G2E2(finalRes["messages_count_30_days"])

		let most_active_channels_per_day = finalRes["most_active_channels_per_day"]
		var test_arr = []
		let colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF9D9D"]
		for(let i = 0; i < most_active_channels_per_day.length; i++){
			test_arr.push({"name": most_active_channels_per_day[i]["entity_name"], "value": most_active_channels_per_day[i]["messages_count"], "color": colors[i]})
		}
		console.log(test_arr)
		setDataA2G1(test_arr)

		let top_channels_stats = finalRes["top_channels_stats"]
		var test_arr = []
		for(let i = 0; i < top_channels_stats.length; i++){
			test_arr.push({"name": top_channels_stats[i]["entity_name"], "participants_count": top_channels_stats[i]["participants_count"]})
		}
		console.log(test_arr)
		setDataA2G2(test_arr)

		let top_channels_with_forwarded_messages_24_hours = finalRes["top_channels_with_forwarded_messages_24_hours"]
		var test_arr = []
		for(let i = 0; i < top_channels_with_forwarded_messages_24_hours.length; i++){
			test_arr.push({"name": top_channels_with_forwarded_messages_24_hours[i]["entity_name"], "value": top_channels_with_forwarded_messages_24_hours[i]["messages_count"]})
		}
		console.log(test_arr)
		setDataA2G3(test_arr)

		setDataA3G1(finalRes["messages_with_emails_24_hours"])
		
		setDataA3G2(finalRes["messages_with_mobile_numbers_24_hours"])

		setDataA3G3(finalRes["messages_with_urls_24_hours"])

		setDataA3G4(finalRes["channels_removed_banned_last_week"])		
	}
	const COLORS = ['#9CDBFF', '#E59CFF', '#2CE38B', '#9CB8FF', "#FF9D9D"];

	const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

  return (
    <div className='page_new' key={Math.random()}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
        <div className='Head'>Analytics</div>
        <button
                      style={{
                        width: '10%',
                        borderRadius: '10px',
                        fontSize: '16px',
                        backgroundColor: 'white',
                        color: '#00ACFB',
                        border: '2px solid #00ACFB',
                        marginLeft: '10px',
						cursor:'pointer'
                      }}
                      onClick={(event) => {
                        callAnalyticsApi()
                      
                      }}
                    >
                      Refresh Analytics
                    </button>
        </div>
        <div className='analytics_1'>
			<div className='graph_1'>
				<div className='graph_1_title'>Number of Channels joined per day</div>
				<div className = "graph_1_line_chart_container">
				<ResponsiveContainer width="90%" height="100%" key={Math.random()}>
					<LineChart data={dataA1G1} style={{ cursor: 'pointer' }}>
					<CartesianGrid vertical={false}/>
					<XAxis dataKey="name" padding={{ left: 30, right: 30 }} tick={{ fill: '#898F9A' }}/>
					<YAxis tick={{ fill: '#898F9A' }}/>
					<Tooltip />
					{/* <Legend /> */}
					<Line
						// type="monotone"
						dataKey="Channels/Groups"
						stroke="#00ACFB"
						strokeWidth={3}
						activeDot={{ r: 8 ,cursor: 'pointer'}}
						// dot={{ cursor: 'pointer' }}
					/>
					</LineChart>
				</ResponsiveContainer>
				</div>
			</div>
			<div className='graph_2'>
				<div className='graph_2_content_1 graph_2_child'>
				<div className='graph_2_content_1_title'>Total Channels Joined in last 30 days</div>
				<div className='graph_2_content_1_data graph_2_child_data'>
					{/* <CountUpAnimation finalValue={dataA1G2E1} duration={1000} /> */}
					<CountUp end={Number(dataA1G2E1)} separator="," duration={1} />
				</div>
				</div>
				<div className='graph_2_content_2 graph_2_child'>
				<div className='graph_2_content_2_title'>Total Messages in last 30 days</div>
				<div className='graph_2_content_2_data graph_2_child_data'>
					{/* <CountUpAnimation finalValue={dataA1G2E2} duration={1000} /> */}
					<CountUp end={Number(dataA1G2E2)} separator="," duration={1} />
				</div>
				</div>
			</div>
        </div>
		<div className='analytics_2'>
			<div className='analytics_2_graph_1 analytics_2_child'>
				<div className='analytics_2_graph_1_title analytics_2_child_title'>
					Top 5 Channels Per Day with Maximum Messages
				</div>	
				<div className='analytics_2_graph_1_data'>
				<ResponsiveContainer width="100%" height="100%"> 

				<PieChart style={{ cursor: 'pointer' }}>
					<Pie
						// dataKey="value"
						// data={dataA2G1}
						// cx="50%"
						// cy="50%"
						// outerRadius={80}
						// // fill="#82ca9d"
						// fill={data.color}
						// labelLine={false}
						dataKey="value"
						data={dataA2G1}
						cx="50%"
						cy="50%"
						labelLine={false}
						// label={renderCustomizedLabel}
						outerRadius={80}
						innerRadius={20}
						fill="#8884d8"
					>
					{dataA2G1.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
					</Pie>
					<Tooltip
						formatter={(value, name, props) => [
							<div style={{ textAlign: 'center', fontWeight: "bold" }}>
							{name}
							<br />
							<span style={{ color: '#DB95F4' }}>{value} messages</span>
							</div>
						]}
					/>
					{/* <Tooltip /> */}
				</PieChart>	
			</ResponsiveContainer>
				</div>
			</div>
			<div className='analytics_2_graph_2 analytics_2_child'>
				<div className='analytics_2_graph_2_title analytics_2_child_title'>
					Top 10 Channels with Most Participants
				</div>
				<div className='analytics_2_graph_2_data'>
					<ResponsiveContainer width="100%" height="100%">
						<BarChart data={dataA2G2} style={{ cursor: 'pointer' }}>
						<Bar dataKey="participants_count" fill="#9CDBFF" barSize={20} />
						<XAxis dataKey="name" tickLine={false} tick={false}/>
						<YAxis tick={{ fill: '#898F9A' }}  width={90}/>
						<Tooltip />
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
			<div className='analytics_2_graph_3 analytics_2_child'>
				<div className='analytics_2_graph_3_title analytics_2_child_title'>
					Top 5 channels with Forwarded Messages in last 24 hours
				</div>
				<div className='analytics_2_graph_3_data'>
					<ResponsiveContainer width="100%" height="100%"> 
						<PieChart style={{ cursor: 'pointer' }}>
							<Pie
								dataKey="value"
								data={dataA2G3}
								cx="50%"
								cy="50%"
								outerRadius={80}
								innerRadius={20}
								fill="#82ca9d"
								labelLine={false}
								// label={renderCustomizedLabel}
							>
							{dataA2G1.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
							</Pie>
							{/* <Tooltip /> */}
							<Tooltip
								formatter={(value, name, props) => [
									<div style={{ textAlign: 'center', fontWeight: "bold" }}>
									{name}
									<br />
									<span style={{ color: '#DB95F4' }}>{value} forwarded messages</span>
									</div>
								]}
							/>
						</PieChart>	
					</ResponsiveContainer>
				</div>
			</div>
		</div>
		<div className='analytics_3'>
			<div className='analytics_3_graph_1 analytics_3_child'>
				<div className='analytics_3_graph_1_title analytics_3_child_title'>
					Emails Found in the last 24 hours
				</div>
				<div className='analytics_3_graph_1_data analytics_3_child_data'>
					{/* <CountUpAnimation finalValue={dataA3G1} duration={1000} /> */}
					<CountUp end={Number(dataA3G1)} separator="," duration={1} />
				</div>
			</div>
			<div className='analytics_3_graph_2 analytics_3_child'>
				<div className='analytics_3_graph_2_title analytics_3_child_title'>
					Mobile Numbers Found in the last 24 hours
				</div>
				<div className='analytics_3_graph_2_data analytics_3_child_data'>
					{/* <CountUpAnimation finalValue={dataA3G2} duration={1000} />		 */}
					<CountUp end={Number(dataA3G2)} separator="," duration={1} />
				</div>
			</div>
			<div className='analytics_3_graph_3 analytics_3_child'>
				<div className='analytics_3_graph_3_title analytics_3_child_title'>
					URLs Found in the last 24 hours
				</div>
				<div className='analytics_3_graph_3_data analytics_3_child_data'>
					{/* <CountUpAnimation finalValue={dataA3G3} duration={1000} />		 */}
					<CountUp end={Number(dataA3G3)} separator="," duration={1} />
				</div>
			</div>
			<div className='analytics_3_graph_4 analytics_3_child'>
				<div className='analytics_3_graph_4_title analytics_3_child_title'>
					Banned channels in the past week
				</div>
				<div className='analytics_3_graph_4_data analytics_3_child_data'>
					{/* <CountUpAnimation finalValue={dataA3G4} duration={1000} />	 */}
					<CountUp end={Number(dataA3G4)} separator="," duration={1} />
				</div>
			</div>
		</div>
    </div>
  )
}

import axios from 'axios'
import instance from './interceptor';


export const export_discover_rule = async(ruleId,notes)=>{

  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
 
  let res = []
    await instance.post(`${apiUrl}/api/export_discover_rule`, { "keyword_rule_id": ruleId,'notes':notes}, config)
    .then(response=>{
        res = response.data;
        console.log(response.data,"data")
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }
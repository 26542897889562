import React from 'react';
import { Dashleft } from '../components/dashleft';
import { MessageDiscover } from '../components/message_discover';

export const MessageMonitor = () => {
  return (
    <div className='dashboard'>
      <Dashleft id={4} />
      <MessageDiscover />
    </div>
  );
};

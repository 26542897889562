/*
  Packages
*/
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import MultipleValueTextInput from 'react-multivalue-text-input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';


/*
   Api
*/
import { update_rule } from '../endpoints/monitor_rule_update';
import { drop_down } from '../endpoints/channel_name_dropdown';
import { create_rule } from '../endpoints/monitor_rule_create';
import { delete_rule } from '../endpoints/delete_monitor_rule';
import { message_monitor_notifications } from '../endpoints/message_monitor_notifications';
import { keyword_drop_down } from '../endpoints/keyword_name_dropdown';

/*
  Assets
*/
import Filter from '../images/Filter.svg'
import trash from '../images/trash.svg';
import loadingImg from '../images/loading.svg';
import slack from '../images/slack.svg';
import grey_slack from '../images/grey-slack.svg';
import refresh_btn from '../images/refresh.svg';
import noData from '../images/no-search-found.svg';
import emptyData from '../images/empty.jpg';
import Export from '../images/Export.svg';
import ExportChannel from '../images/ExportChannel.svg';
import ViewEye from '../images/ViewEye.svg';
import { message_monitor_notifications_view_messages } from '../endpoints/message_monitor_notifications_view_messages';
import { monitor_notifications_overview } from '../endpoints/monitor_notifications_overview';
import ActiveDot from '../images/activedot.svg';
import { messages_from_monitor_notification } from '../endpoints/messages_from_monitor_notification';
import { export_monitor_rule } from '../endpoints/export_monitor_rule';
import { ToastContainer, toast } from 'react-toastify';
import { export_specific_notification } from '../endpoints/export_specific_notification';
import { mark_key_notification_read } from '../endpoints/mark_key_notification_read';
import { mark_monitor_notification_as_read } from '../endpoints/mark_monitor_notification_as_read';
import { bgBG } from '@mui/x-data-grid';
import moment from 'moment';
import download_icon from '../images/download_icon.png'
import InstructionPopUpImg from '../images/instrucitoneye.svg';

const style = {
  // width: 750,
  // bgcolor: 'background.paper',
  // boxShadow: 24,
  // borderRadius: '12px',
  // paddingInline: 8,
  // paddingTop: 4,
  // paddingBottom: 4,
  // marginTop:15,
  // marginLeft: 65,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  paddingInline: 8,
  paddingTop: 4,
  paddingBottom: 4,
  margin: 'auto',
  height: 'fit-content',
};

const style_delete = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  paddingInline: 8,
  paddingTop: 4,
  paddingBottom: 4,
  margin: 'auto',
  height: 'fit-content',
};

const style_message_view_card = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: 500,
  bgcolor: '#F7F9FB',
  boxShadow: 24,
  borderRadius: '8px',
  paddingInline: 3,
  paddingTop: 2,
  paddingBottom: 2,
  margin: 'auto',
  height: 'fit-content',
};

//   function convertDateTimeToIST(dateTime) {
//     const utcDate = dateTime.toUTCString();
//     const options = {
//       timeZone: 'Asia/Kolkata',
//       day: '2-digit',
//       month: 'long',
//       year: 'numeric',
//       hour: 'numeric',
//       minute: '2-digit',
//       hour12: true
//     };
//     return new Date(utcDate).toLocaleString('en-IN', options);
//   }

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    timeZone: 'Asia/Kolkata',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return date.toLocaleString('en-US', options);
}

function createData(chan) {
  return { chan };
}

export const MessageDiscover = () => {
  const [instructionPopUp, setinstructionPopUp] = React.useState(false)
  const [tab, setTab] = React.useState(1);
  const [key, setKey] = React.useState();
  const [selectedKeys, setSelectedKeys] = React.useState();
  const [selectCount, setSelectCount] = React.useState(0);
  const [name, setName] = React.useState();
  const [description, setDescription] = React.useState();
  const [toggleAddKeys, setToggleAddKeys] = React.useState(false);
  const [toggleNotification, selectToggleNotification] = React.useState(false);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedOptionsKeyword, setSelectedOptionsKeyword] = React.useState(
    []
  );
  const [ruleName,setRuleName] =useState()
  const [exportNotification,setExportNotification] =React.useState(false)
  const [exportNotificationId,setExportNotificationId] =React.useState(false)
  const [filterPopUp,setFilterPopUp]=React.useState(false)
  const [mediaName, setMediaName] = React.useState([]);
  const [mediaContained, setMediaContained] = React.useState(false);
  const [messageContained, setMessageContained] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [row, setRow] = React.useState();
  const [monitorRules, setMonitorRules] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadForm, setLoadForm] = React.useState(false);
  const [entries, setEntries] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [sample_rows, setSample_rows] = React.useState([]);
  const [pc, setPc] = React.useState(1);
  const [det, setDet] = React.useState(10);
  const [totCount, setTotCount] = React.useState(4000);
  const [monitorData, setMonitorData] = React.useState();
  const [notificationData, setnotificationData] = React.useState();
  const [notificationId,setNotificationId]=useState();
  const [messagefromNotification, setMessagefromNotification]= React.useState(false)
  const [join, setJoin] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  let channel_name_dropdown = [];
  let keyword_name_dropdown = [];
  const [notes,setNotes]=useState('')

  const [delete_pop, setDelete_pop] = React.useState(false);
  const [to_delete_rule, setTo_delete_row] = React.useState();
  const [viewFormConfirmation, setViewFormConfirmation] = React.useState(false);
  const [dataToView, setDataToView] = React.useState();
  const [viewDataPop, setViewDataPop] = React.useState(false);
  const [pcR, setPcR] = React.useState(1);
  const [pageR, setPageR] = React.useState(1);
  const [entriesR, setEntriesR] = React.useState(10);
  const [totCountR, setTotCountR] = React.useState(4000);
  const [detR, setDetR] = React.useState(10);
  const [tagsKeyword, setTagsKeyword] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [monitorId, setMonitorId] = React.useState();
  const [exportPopUp,setExportPopUp] =React.useState(false)
  const [ruleId,setRuleId] =React.useState(false)
  const [messageDetailsPop,setMessageDetailsPop]=useState(false)
  const [filters, setFilters] = React.useState({enabled: false, disabled: false, allFilters: false})
  const [pageMessages, setPageMessages] = React.useState(1)

  const sampleRule = {
    name: 'rule 1',
    media: 'WhoMI',
    description: ['hi', 'welcome'],
    mediaContained: false,
    messageContained: true,
    tags: ['none'],
    selectedOptions: [
      { value: 'PAN BAHAR', label: 'PAN BAHAR' },
      { value: 'Fresh Combo', label: 'Fresh Combo' },
    ],
  };
  let notificationIdsArray=[];
  //function to clear all the fields of the form before closing it
  const handleCancelRule = () => {
    setRow();
    setName();
    setMediaName();
    setDescription();
    setMediaContained(false);
    setMessageContained(false);
    setSelectedOptions([]);
    setSelectedOptionsKeyword([]);
    setSelectedKeys([]);
    setEdit(false);
    setLoadForm(false);
  };

  //function to create a new rule
  const handleCreateRule = async () => {
    console.log(edit);
    let channels = [];
    // for(let i=0;i<selectedOptions.length;i++){
    //     channels.push(selectedOptions[i].value);
    // }
    let arr = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    let brr = [];
    for (let i = 0; i < selectedOptionsKeyword.length; i++) {
      brr.push(selectedOptionsKeyword[i].value);
    }

    console.log('message entries', selectedKeys);
    console.log('loopEnded');
    if (edit === false) {
      console.log('its false');
      if (!selectedKeys || !name) {
        setViewFormConfirmation(true);
      } else {
        const uniqueKeys = selectedKeys
          .map((key) => key.trim()) // remove leading and trailing whitespace
          .filter(
            (key, index, array) => key !== '' && array.indexOf(key) === index
          ); // filter out empty strings and duplicates
        console.log(uniqueKeys);
        var uniqueMediaNames = [];
        if (mediaName) {
          uniqueMediaNames =
            mediaName ??
            []
              .map((name) => name.trim()) // remove leading and trailing whitespace
              .filter(
                (name, index, array) =>
                  name !== '' && array.indexOf(name) === index
              ); // filter out empty strings and duplicates
          console.log(uniqueMediaNames);
        }

        const r = {
          name: name,
          description: description,
          media_file_name_contains: uniqueMediaNames ?? [],
          targeted_entity_ids: arr,
          targeted_keyword_rules: brr,
          msg_contains: uniqueKeys,
          case_sensitive_message_contains: messageContained,
          case_sensitive_media_file_name_contains: mediaContained,
        };

        setName();
        setMediaName();
        setDescription();
        setMediaContained(false);
        setMessageContained(false);
        setSelectedOptions([]);
        setSelectedOptionsKeyword([]);
        setSelectedKeys([]);
        setLoadForm(false);
        setEdit(false);
        setRow();
        console.log('sent object from the actual page: ', r);
        let response = [];
        response = await create_rule(r);

        console.log('response in the actual page : ', response);
        const m = {
          limitPerPage: entriesR,
          pageNo: pageR,
        };
        call_data(m);
        // window.location.reload();
      }
    } else {
      const uniqueKeys = selectedKeys
        .map((key) => key.trim()) // remove leading and trailing whitespace
        .filter(
          (key, index, array) => key !== '' && array.indexOf(key) === index
        ); // filter out empty strings and duplicates
      console.log(uniqueKeys);

      const uniqueMediaNames = mediaName
        .map((name) => name.trim()) // remove leading and trailing whitespace
        .filter(
          (name, index, array) => name !== '' && array.indexOf(name) === index
        ); // filter out empty strings and duplicates

      console.log(uniqueMediaNames);

      console.log('its true dude');
      const r = {
        name: name,
        description: description,
        media_file_name_contains: uniqueMediaNames,
        targeted_entity_ids: arr,
        targeted_keyword_rules: brr,
        msg_contains: uniqueKeys,
        id: row._id.$oid,
        enabled: row.enabled,
      };
      console.log('updating 123');
      setName();
      setMediaName();
      setDescription();
      setMediaContained(false);
      setMessageContained(false);
      setSelectedOptions([]);
      setSelectedOptionsKeyword([]);
      setSelectedKeys([]);
      setLoadForm(false);
      setEdit(false);
      setRow();
      console.log(r);
      let response = [];
      response = await delete_rule(r);
      console.log(r);

      const m = {
        limitPerPage: entriesR,
        pageNo: pageR,
      };
      call_data(m);
      // window.location.reload();
    }
  };

  //function to enable or disable slack
  const handleUpdateSlack = async (event, val) => {
    event.preventDefault();
    console.log('row: ', val);
    const r = {
      id: val._id.$oid,
      should_send_slack_notification: !val.should_send_slack_notification,
    };
    console.log('updating 123', r);
    setName();
    setMediaName();
    setDescription();
    setMediaContained(false);
    setMessageContained(false);
    setSelectedOptions([]);
    setSelectedOptionsKeyword([]);
    setSelectedKeys([]);
    setLoadForm(false);
    setEdit(false);
    setRow();

    let response = [];
    response = await delete_rule(r);

    const m = {
      limitPerPage: entriesR,
      pageNo: pageR,
    };
    call_data(m);
    // window.location.reload();
  };

  //function to edit a rule
  const handleEditRule = (event, val) => {
    event.preventDefault();
    setRow(val);
    setName(val.name);
    setMediaName(val.media_file_name_contains);
    setDescription(val.description);
    setMediaContained(val.case_sensitive_media_contains);
    setMessageContained(val.case_sensitive_message_contains);
    setSelectedOptions(val.targeted_entity_ids);
    setSelectedKeys(val.msg_contains);
    setEdit(true);
    setLoadForm(true);
    setSelectedOptionsKeyword(val.targeted_keyword_rules);
  };

  const [tags, setTags] = React.useState([]);
  
  const handleNameAdd = (event) => {
    setName(event.target.value);
    console.log(event.target.value);
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    console.log(selectedOptions);

    let arr = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      arr.push(selectedOptions[i].value);
    }
  };

  const handleSelectChangeKeyword = (selectedOptionsKeyword) => {
    setSelectedOptionsKeyword(selectedOptionsKeyword);
    console.log(selectedOptionsKeyword);

    let arr = [];
    for (let i = 0; i < selectedOptionsKeyword.length; i++) {
      arr.push(selectedOptionsKeyword[i].value);
    }
  };
  const setNotificationIds=async ()=>{
    let data = notificationData.dbRes.data;
    data.forEach(item => {
      notificationIdsArray.push(item._id.$oid)
    });
    const response =  await mark_monitor_notification_as_read(notificationIdsArray)
    toast.success('Notifications marked as All Read', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    console.log(response);
    notificationDataChange();

  }
  const handleInputChange = (inputValue) => {
    // Remove consecutive whitespace characters within the input value
    inputValue = inputValue.replace(/\s+/g, ' ');
    // Add the new option to the tags array if it is not an empty string or a duplicate
    if (tags) {
      // const isDuplicate = tags.some((tag) => tag.value.toLowerCase() === inputValue.toLowerCase());
      if (inputValue.trim() !== '') {
        const newTag = { value: inputValue.trim(), label: inputValue.trim() };
        handleDropdown(newTag.value);
      }
    }
  };

  const handleInputChangeKeyword = (inputValue) => {
    // Remove consecutive whitespace characters within the input value
    inputValue = inputValue.replace(/\s+/g, ' ');

    // Check if the input value is an exact match with an existing tag value

    // Add the new option to the tags array if it is not an empty string or a duplicate
    if (tagsKeyword) {
      // const isDuplicate = tags.some((tag) => tag.value.toLowerCase() === inputValue.toLowerCase());
      if (inputValue.trim() !== '') {
        const newTag = { value: inputValue.trim(), label: inputValue.trim() };
        handleDropdownKeyword(newTag.value);
      }
    }

    // let response = [];
    // let rule={
    //     "keyword": inputValue
    // }
    // response = await drop_down(rule)
    // setTags(response.dbRes);
    // channel_name_dropdown= response.dbRes
    // console.log(tags);
  };

  //function to enable or disable a rule

  const handlePauseRule = async (event, val) => {
    event.preventDefault();
    console.log('in pause rule', val);
    let t = [];
    let p = [];

    for (let i = 0; i < val.targeted_entity_ids.length; i++) {
      t = val.targeted_entity_ids[i].value;
    }

    for (let i = 0; i < val.targeted_keyword_rules.length; i++) {
      p = val.targeted_keyword_rules[i].value;
    }
    const r = {
      id: val._id.$oid.toString(),
      name: val.name,
      description: val.description,
      media_file_name_contains: val.media_file_name_contains ?? [],
      targeted_entity_ids: t,
      msg_contains: val.msg_contains,
      enabled: !val.enabled,
      case_sensitive_media_file_name_contains:
        val.case_sensitive_media_file_name_contains,
      case_sensitive_message_contains: val.case_sensitive_message_contains,
      targeted_keyword_rule: p,
    };

    console.log('handling pause', r);

    let response = [];
    response = await delete_rule(r);
    // window.location.reload();
    const m = {
      pageNo: 1,
      limitPerPage: 10,
    };
    call_data(m);
    console.log(r);
  };

  //function to delete a rule
  const handleDeletePop = async (event) => {
    setDelete_pop(false);
    const val = to_delete_rule;
    let t = [];
    for (let i = 0; i < val.targeted_entity_ids.length; i++) {
      t = val.targeted_entity_ids[i].value;
    }
    event.preventDefault();
    const rule = {
      id: val._id.$oid.toString(),
      name: val.name,
      description: val.description,
      media_file_name_contains: val.media_file_name_contains,
      targeted_entity_ids: t,
      msg_contains: val.msg_contains,
      enabled: val.enabled,
      case_sensitive_media_file_name_contains:
        val.case_sensitive_media_file_name_contains,
      case_sensitive_message_contains: val.case_sensitive_message_contains,
      is_deleted: true,
    };
    console.log('rulemade ');
    let response = [];
    response = await delete_rule(rule);
    // window.location.reload();
    let m = {
      pageNo: 1,
      limitPerPage: 10,
    };
    call_data(m);

    setTab(1);
    console.log(rule);
  };

  const handleDelete = async (event, val) => {
    event.preventDefault();
    setDelete_pop(true);
    setTo_delete_row(val);

    // console.log('clicked')
    // console.log(val);
    // const rule={
    //         "id":(val._id.$oid).toString(),
    //         "name": val.name,
    //         "description": val.description,
    //         "media_file_name_contains": val.media_file_name_contains,
    //         "targeted_entity_ids": val.targeted_entity_ids,
    //         "msg_contains": val.msg_contains,
    //         "enabled": val.enabled,
    //         "case_sensitive_media_file_name_contains": val.case_sensitive_media_file_name_contains,
    //         "case_sensitive_message_contains": val.case_sensitive_message_contains,
    //         "is_deleted": true
    // }
    // console.log('rulemade ')
    // let response = [];
    // response = await delete_rule(rule)
    // // window.location.reload();
    // setTab(1);
    // console.log(rule);
  };

  const handleDescriptionAdd = (event) => {
    setDescription(event.target.value);
    console.log(event.target.value);
  };

  const handleNotificationClick = (event, val) => {
    event.preventDefault();
    navigate(val);
  };

  const handleKeyAdd = (event) => {
    event.preventDefault();
    setToggleAddKeys(!toggleAddKeys);
  };

  const handleChange = (event) => {
    setKey(event.target.value);
    console.log(event.target.value);
  };

  const handleAddKeys = (event, val) => {
    event.preventDefault();
    if (selectedKeys.indexOf(val) === -1) {
      setSelectedKeys([...selectedKeys, val]);
      setSelectCount(selectCount + 1);
    }
  };

  const handleRemoveKeys = (event, val) => {
    event.preventDefault();
    console.log(val);
    setSelectedKeys(
      selectedKeys.filter((selectedKeys) => selectedKeys !== val.key)
    );
    setSelectCount(selectCount - 1);
  };

  //function to handle switching between tabs
  const handleTabChange = async (event, val, id) => {
    event.preventDefault();
    setEdit(false);
    if (val === 'createMonitorRule') {
      setLoadForm(!loadForm);
      return 0;
    } else if (val === 'viewMonitorRule') {
      setTab(1);
      let r = {
        pageNo: 1,
        limitPerPage: 10,
      };
      let response = [];
      response = await update_rule(r);
      setMonitorRules(response.dbRes.data);
    } else if (val === 'monitor') {
      setMessagefromNotification(false)
      setMonitorId(id);
      setTab(3);
      let r = {
        monitor_rule_id: id,
        pageNo: page,
        limitPerPage: entries,
      };
      const response = await message_monitor_notifications_view_messages(r);
      console.log(response);
      setMonitorData(response);
    } else {
      //     const message = { 'pageNo': 0 };
      // //   buttonRef.current.click();
      //     test_c(message);
      setTab(2);
      await notificationDataChange()
      return 2;
    }
  };
  const notificationDataChange = async () => {
    let data = {
      pageNo: page,
      limitPerPage: entriesR,
    };
    console.log(data);
    const response = await monitor_notifications_overview(data);
    setnotificationData(response);
  };
  useEffect(() => {
    notificationDataChange();
  }, [entriesR]);

  const changeValue = (event, data) => {
    event.preventDefault();
    setDataToView(data);

  };

  const formatLastAddedat=(value)=>{
    const date = new Date(value)
    return date.toLocaleString([], { month:'short',year:'numeric',day:'2-digit', hour: '2-digit', minute: '2-digit'});
  }

  const test_c = async (message) => {
    console.log('in test_c sending data : ', message);
    let response = [];
    response = await message_monitor_notifications(message);
    let chan = response.dbRes.data;

    setPc(Math.ceil(response.dbRes.totalCount / entries));
    setTotCount(response.dbRes.totalCount);
    setDet(entries);

    let test_rows = [];
    for (let i = 0; i < chan.length; i++) {
      test_rows.push(createData(chan[i]));
    }
    console.log('test_rows: ', test_rows);
    setSample_rows(test_rows);
    // setTab(2)

    console.log('in test_C recieving data: ', response);
  };
  const handleMonitorEntries=async (value)=>{
    console.log(value);
    setEntries(value);
                    let data ={  
                      "monitor_notification_id": monitorId,
                      "pageNo": pageMessages,
                      "limitPerPage": value
                  }
                    const response = await messages_from_monitor_notification(data)
                    setMonitorData(response)
                    
  }
  const call_data = async (data) => {
    let response = [];
    response = await update_rule(data);
    console.log(
      'in call funtion to call monitor rules the response is : ',
      response
    );
    setMonitorRules(response.dbRes.data);
    setPcR(Math.ceil(response.dbRes.totalCount / entriesR));
    setTotCountR(response.dbRes.totalCount);
    setDetR(entriesR);
    setIsLoading(false);
    console.log(
      'here is the set monitor rules entered value : ',
      response.dbRes.data
    );
  };

  const handlePage = async (event, value) => {
    setPage(value);
    // const t = handleFin_tags();

                            const message = {
                              pageNo: value,
                              limitPerPage: entriesR,
                            };
                            const response = await monitor_notifications_overview(message);
                            setnotificationData(response);
    test_c(message);
  };
  
  const handlePagemonitor = async (event, value) => {
    setPageMessages(value);
    // const t = handleFin_tags();
    console.log(monitorId)
    if(messagefromNotification === false){
      let r = {
        monitor_rule_id: monitorId,
        pageNo: value,
        limitPerPage: entries,
      };
      const response = await message_monitor_notifications_view_messages(r);
      console.log(response);
      setMonitorData(response);
    }
    else{
                            let data ={  
                              "monitor_notification_id": monitorId,
                              "pageNo": value,
                              "limitPerPage": entries
                          }
                            const response = await messages_from_monitor_notification(data)
                            setMonitorData(response)
                            // const response = await message_monitor_notifications(message);
                            // setMonitorData(response);
                        }
  };
  const handleDropdown = async (value) => {
    let response = [];
    let rule = {
      keyword: value,
    };
    response = await drop_down(rule);
    setTags(response.dbRes);
    channel_name_dropdown = response.dbRes;
    console.log(tags);
  };

  //function to display keyword dropdown details from backend
  const handleDropdownKeyword = async (value) => {
    let response = [];
    let rule = {
      keyword: value,
    };
    response = await keyword_drop_down(rule);
    console.log('this is the response of the keywod dropdown', response);
    setTagsKeyword(response.dbRes);
    keyword_name_dropdown = response.dbRes;
    console.log(tagsKeyword);
  };

  React.useEffect(() => {
    let r = {
      pageNo: 1,
      limitPerPage: 10,
    };
    call_data(r);
    let message = {
      pageNo: 1,
      limitPerPage: 10,
    };
    test_c(message);
  }, [selectedKeys, mediaName]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: checked }));
  };

  const handleFilters = () =>{
    console.log(filters)
    const message = {
      pageNo: 1,
      limitPerPage: 10,
      filters: []
    };

    for (const key in filters) {
      if (filters.hasOwnProperty(key) && filters[key]) {
        // If the checkbox is checked (i.e., the value is true), add the field name to the filters array
        message.filters.push(key);
      }
    }

    console.log(message)
    call_data(message)

    setFilterPopUp(!filterPopUp)
  }

  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <div
        className='page'
        style={{
          paddingTop: '2%',
          overflow: 'hidden',
          width: '100%',
          paddingLeft: '15%',
        }}
      >
        <div
          className=''
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '0px',
          }}
        >
          <div className='Head1'>Monitor Messages</div>
          {/* <div className='notificationContainer'>
                                <button onClick={ (event) => selectToggleNotification(!toggleNotification)} className='keywordButton' ><img src={bell} /></button>
                                { toggleNotification == true ? 
                                    <div className='notificationDropdown'>
                                        <div className='notificationHead'>Notifications</div>
                                        <div onClick={(event) => handleNotificationClick(event,'/notifications')} className='notificationBar'>
                                                <div className='notificationHeading'>
                                                    <div className='notificationIcon'>
                                                        <img src={semiCritical}/>
                                                    </div>
                                                    <div className='notificationTitle'>
                                                            Server Health
                                                    </div>
                                                </div>
                                                <div className='notificationContent'>
                                                    Lorem Ipsum 321 server health deprecated
                                                </div>
                                        </div> 
                                        <div onClick={(event) => handleNotificationClick(event, '/discover')} className='notificationBar'>
                                                <div className='notificationHeading'>
                                                    <div className='notificationIcon'>
                                                        <img src={infoNotification}/>
                                                    </div>
                                                    <div className='notificationTitle'>
                                                            100 Channels Found
                                                    </div>
                                                </div>
                                                <div className='notificationContent'>
                                                    999+ channels for drugs discovered
                                                </div>
                                        </div> 
                                        <div className='notificationBar'>
                                                <div className='notificationHeading'>
                                                    <div className='notificationIcon'>
                                                        <img src={success}/>
                                                    </div>
                                                    <div onClick={(event) => handleNotificationClick(event, '/messages')} className='notificationTitle'>
                                                            999+ New Message
                                                    </div>
                                                </div>
                                                <div className='notificationContent'>
                                                    new messages found for the keyword xyz abc 
                                                </div>
                                        </div> 
                                    </div> : <></> }
                            </div> */}
          {/* <button style={{marginLeft}}className='userBtn'>Welcome User</button> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 600,
              color: '00ACFB',
              alignItems:'center',
             
            }}
          >
            <div style={{cursor:'pointer', margin:'0px 10px'}} onClick={()=>{
              setFilterPopUp(!filterPopUp)

            }}>
            {tab == 1 && <img src={Filter} alt='Filter' style={{ height: '3vh' }} />}
            </div>
            {tab !== 1 && (
              <div
                style={{
                 
                  cursor:'pointer'
                }}
                onClick={async ()=>{
                  tab === 2 && await setNotificationIds()
                }}
              >
                {tab === 1 ? (
                  <></>
                ) : tab === 2 ? (
                  <span  className='apply_filter'
                  style={{marginRight:'15px'}}
                  >Mark All Read</span>
                ) : (
                  <span  className='apply_filter' style={{marginRight:'15px'}} >Export All Messages</span>
                )}
              </div>
            )}
            <div
              style={{
                
                cursor: 'pointer',
              }}
              onClick={(event) => handleTabChange(event, 'createMonitorRule')}
            >
              <span  className='apply_filter'>Add Monitor Rule</span>
            </div>
          </div>
        </div>
       
        <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
            style={{ width: '17%', fontWrap: 'none', textAlign: 'left' }}
          />
        <div className='tabSelector tabsContainer'>
          <button
            className='tabBtn'
            onClick={(event) => handleTabChange(event, 'viewMonitorRule')}
            style={
              tab === 1 ? { color: '#00ACFB', backgroundColor: '#EBF9FF' } : {}
            }
          >
            View Monitor Rules
          </button>
          <button
            className='tabBtn'
            onClick={(event) => handleTabChange(event, 'notifications')}
            style={
              tab === 2 ? { color: '#00ACFB', backgroundColor: '#EBF9FF' } : {}
            }
          >
            Notifications
          </button>
          {monitorData && tab == 3 && (
            <>
            <button
              className='tabBtn'
              onClick={(event) => handleTabChange(event, 'monitor')}
              style={
                tab === 3
                  ? { color: '#00ACFB', backgroundColor: '#EBF9FF' }
                  : {}
              }
            >
              Monitored
            </button>
            <button
                className='tabSelectors btn btn-primary'
                style={{ backgroundColor: '#EBF9FF', color: '#00ACFB' }}
                onClick={(event) => handleTabChange(event, 'notifications')}
              >
                Back
          </button>
          </>
          )}
        </div>
        {filterPopUp === true ? (
            <div
              className='filterBackdropModalMonitor'
              onClick={() => {
                setFilterPopUp(false);
              }}
            >
              <div
                className='filterbox'
                onClick={(e) => e.stopPropagation()}
              >
                <span style={{fontSize:'20px',fontWeight:600,color:'#223036',width:'100%',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>Filter</span>
                <div style={{margin:'10px 10px',display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center'}}>
                  <input type='checkbox' style={{marginRight:'5px',border:'2px solid #898F9A',borderRadius:'5px',height:'18px',width:"18px",alignItems:'center',}}
                    name = "allFilters"
                    checked={filters.allFilters}
                    onChange={handleCheckboxChange}
                  />
                  <span >Show All rules</span>
                </div>
                <div style={{margin:'10px 10px',display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center'}}>
                  <input type='checkbox' style={{marginRight:'5px',border:'2px solid #898F9A',borderRadius:'5px',height:'18px',width:"18px",alignItems:'center',}}
                    name = "enabled"
                    checked={filters.enabled}
                    onChange={handleCheckboxChange}
                  />
                  <span >Only enabled rules</span>
                </div>
                <div style={{margin:'10px 10px',display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center'}}>
                  <input type='checkbox' style={{marginRight:'5px',border:'2px solid #898F9A',borderRadius:'5px',height:'18px',width:"18px",alignItems:'center',}}
                    name = "disabled"
                    checked={filters.disabled}
                    onChange={handleCheckboxChange}
                  />
                  <span >Only disabled rules</span>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <div style={{borderRadius:'10px',padding:'4% 8%',color:'white',margin:'10px',fontSize:'16px',backgroundColor:'#00ACFB',fontWeight:500,cursor:'pointer'}}  onClick={()=>handleFilters()}>
                    Apply
                  </div>
                  <div style={{border:'1px solid #00ACFB',padding:'4% 8%',backgroundColor:'white',color:'#00ACFB',borderRadius:'10px',fontSize:'16px',fontWeight:500,cursor:'pointer'}} onClick={()=>setFilterPopUp(!filterPopUp)}>
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        {exportNotification ? (
        <div
          className='backdropModal'
          onClick={() => {
            setExportNotification(false);
            
          }}
        >
          <Box
            style={{ zIndex: '3',padding:'10px' }}
            className='Box'
            sx={style}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='keywordsModal'>
              
        
             
            
            <div style={{margin:'10px 0px',borderRadius:'10px',width:'100%'}}>
                      <span style={{marginRight:'10px'}}>
                        Note:
                      </span>
                      <input style={{border:'1px solid',borderRadius:'4px',width:'100%'}} 
                      value={notes}
                      onChange={({target})=>{
                        setNotes(target.value)
                      }}/>

                    </div>
            {/* <span style={{margin:'10px 0px',fontSize:'20px'}}>
              Export with Profile Picture?
            </span> */}
            
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <button style={{margin:'10px',borderRadius:'10px'}} onClick={async()=>{
                
                const response = await export_specific_notification(exportNotificationId,notes)
                setExportNotification(!exportNotification)
                toast.success('Requested! job_id: ' + response.dbRes.job_id, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              }}>
                Yes
              </button>
              <button style={{borderRadius:'10px'}} onClick={()=>{
                setExportNotification(!exportNotification)
              }}>
                No
              </button>
            </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}
       {exportPopUp ? (
        <div
          className='backdropModal'
          onClick={() => {
            setExportPopUp(false);
          }}
        >
          <Box
            style={{ zIndex: '3',padding:'10px' }}
            className='Box'
            sx={style}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='keywordsModal'>
              
        
             
            
            <div style={{margin:'10px 0px',borderRadius:'10px',width:'100%'}}>
                      <span style={{marginRight:'10px'}}>
                        Note:
                      </span>
                      <input style={{border:'1px solid',borderRadius:'4px',width:'100%'}} 
                      value={notes}
                      onChange={({target})=>{
                        setNotes(target.value)
                      }}/>

                    </div>
                    
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <button style={{margin:'10px',borderRadius:'10px'}} onClick={async()=>{
                const response = await export_monitor_rule(ruleId)
                toast.success('Requested! job_id: ' + response.dbRes.job_id, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                setExportPopUp(!exportPopUp)
               
              }}>
                Yes
              </button>
              <button style={{borderRadius:'10px'}} onClick={()=>{
                setExportPopUp(!exportPopUp)
              }}>
                No
              </button>
            </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '80%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img style={{ height: '10vh' }} src={loadingImg} />
          </div>
        ) : (
          <div className='tab_container'>
            {tab === 1 ? (
              monitorRules.length > 0 ? (
                <div className='tab'>
                  <div
                    className='table-container'
                    style={{
                      marginTop: '0.5%',
                      height: '100%',
                      border: 'none',
                      overflowY: 'auto',
                      overflowX: 'auto',
                    }}
                  >
                    <div className='ruleContainer'>
                      {monitorRules.map((rule) => (
                        <div className='ruleBox'>
                          <div
                            className='ruleHead'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>{rule.name}</div>
                            <div
                              style={{
                                marginLeft: 'auto',
                                marginRight: '1%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <button
                                style={{
                                  width: 'fit-content',
                                  backgroundColor: '#f7f9fb',
                                  color: 'red',
                                  fontSize: 'medium',
                                  marginRight: '10px',
                                }}
                                onClick={(event) => {
                                  handleUpdateSlack(event, rule);
                                }}
                                title={
                                  rule.should_send_slack_notification
                                    ? 'Disable Slack notification'
                                    : 'Enable Slack notification'
                                }
                              >
                                <img
                                  style={{ height: '2vh' }}
                                  src={
                                    rule.should_send_slack_notification
                                      ? slack
                                      : grey_slack
                                  }
                                />
                              </button>
                              <img
                                alt='export'
                                style={{ height: '2vh',cursor:'pointer' }}
                                src={ExportChannel}
                                onClick={async()=>{
                                  setRuleId(rule._id.$oid)
                                  setExportPopUp(!exportPopUp)
                                }}
                              />
                            </div>
                            <button
                              onClick={(event) => handleDelete(event, rule)}
                              style={{
                                backgroundColor: '#f7f9fb',
                                width: 'fit-content',
                                color: 'red',
                              }}
                            >
                              <img src={trash} className='trash-icon' />
                            </button>
                          </div>
                          <div className='' style={{ color: '#898F9A' }}>
                            Updated at : {formatDate(rule.updated_at.$date)}
                          </div>
                          {/* <div className='ruleKeys'>
                                                {rule.msg_contains.map((tag) =>(
                                                    <div className='tags'>{tag}</div>
                                                ))}                           
                                        </div> */}
                          {/* <div style={{marginTop:'1%'}}>
                                                <input type='checkbox' checked={rule.case_sensitive_message_contains} disabled /> Case Sensitive
                                        </div> */}
                          <div className=''>
                            <div className='' style={{ marginTop: '10px' }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Description:{' '}
                              </span>{' '}
                              {rule.description}
                            </div>
                          </div>
                          {/* <div style={{display:'flex', justifyContent:'space-between' , marginTop:'auto', gap:'1%', height:'25%'}}> 
                                        
                                            <div className='ruleMediaBox'>
                                                <div className='ruleSubHead'>
                                                    Media File Names :
                                                </div>
                                                <div className='ruleKeys'>
                                                {rule.media_file_name_contains.map((tag) =>(
                                                    <div className='fileType'>{tag}</div>
                                                ))}                           
                                                </div>
                                                <div style={{marginTop:'1%'}}>
                                                <input type='checkbox' checked={rule.case_sensitive_media_file_name_contains} disabled /> Case Sensitive
                                                </div>
                                            </div>
                                        </div> */}
                          <div className='scrollbar' style={{ width: '100%' }}>
                            {rule?.msg_contains?.length > 0 ? (
                              <div className='ruleSubcard'>
                                <div
                                  className='ruleSubHead'
                                  style={{
                                    margin: '0',
                                  }}
                                >
                                  Keywords :
                                </div>
                                <div className='ruleKeys'>
                                  {rule.msg_contains.map((tag) => (
                                    <div className='tags'>{tag}</div>
                                  ))}
                                </div>
                                {/* <div>
                                                        <input type='checkbox' checked={rule.case_sensitive_message_contains} disabled /> Case Sensitive
                                                    </div> */}
                              </div>
                            ) : (
                              <></>
                            )}
                            {rule.media_file_name_contains &&
                            rule.media_file_name_contains.length > 0 ? (
                              <div className='ruleSubcard'>
                                <div className='ruleSubHead'>
                                  Media File Name :
                                </div>
                                <div className='ruleKeys'>
                                  {rule.media_file_name_contains.map((tag) => (
                                    <div className='fileTags'>{tag}</div>
                                  ))}
                                </div>
                                {/* <div>
                                                        <input type='checkbox' checked={rule.case_sensitive_media_file_name_contains} disabled /> Case Sensitive
                                                    </div> */}
                              </div>
                            ) : (
                              <></>
                            )}
                            {rule.targeted_entity_ids.length > 0 ? (
                              <div className='ruleSubcard'>
                                <div className='ruleSubHead'>
                                  Targeted Channels :
                                </div>
                                <div className='ruleKeys'>
                                  {rule.targeted_entity_ids.map((tag) => (
                                    <div className='channelNameTags'>
                                      {tag.label}
                                    </div>
                                  ))}
                                </div>
                                {/* <div>
                                                        <input type='checkbox' checked={rule.case_sensitive_media_file_name_contains} disabled /> Case Sensitive
                                                    </div> */}
                              </div>
                            ) : (
                              <></>
                            )}
                            {rule.targeted_keyword_rules &&
                            rule.targeted_keyword_rules.length > 0 ? (
                              <div className='ruleSubcard'>
                                <div className='ruleSubHead'>
                                  Targeted Discovey Rule :
                                </div>
                                <div className='ruleKeys'>
                                  {rule.targeted_keyword_rules.map((tag) => (
                                    <div
                                      className='channelNameTags'
                                      style={{
                                        color: '#D5B4B4',
                                        borderColor: '#D5B4B4',
                                      }}
                                    >
                                      {tag.label}
                                    </div>
                                  ))}
                                </div>
                                {/* <div>
                                                        <input type='checkbox' checked={rule.case_sensitive_media_file_name_contains} disabled /> Case Sensitive
                                                    </div> */}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div
                            className=''
                            style={{
                              marginBottom: '0%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingTop: '10px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <button
                                className='ruleBtn1'
                                onClick={(event) => handleEditRule(event, rule)}
                                style={{ marginRight: '20px' }}
                              >
                                Edit
                              </button>
                              <button
                                className='ruleBtn2'
                                onClick={(event) =>
                                  handlePauseRule(event, rule)
                                }
                                style={
                                  rule.enabled
                                    ? {}
                                    : {
                                        borderColor: '#00CE3A',
                                        color: '#00CE3A',
                                      }
                                }
                              >
                                {rule.enabled ? 'Disable' : 'Enable'}
                              </button>
                            </div>

                            {rule.is_processing ? (
                              <img
                                alt='loading'
                                style={{ marginLeft: 'auto', height: '30px' }}
                                src={loadingImg}
                              />
                            ) : (
                              <></>
                            )}
                            <div style={{ marginRight: '1%' }}>
                              <button
                                className='ruleBtn1'
                                onClick={(event) =>{
                                  setRuleName(rule.name);
                                  handleTabChange(
                                    event,
                                    'monitor',
                                    rule._id.$oid
                                  );
                                }}
                                style={{
                                  backgroundColor: '#00ACFB',
                                  border: 0,
                                  color: 'white',
                                }}
                              >
                                View Messages
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='paginateMessages' style={{display:'flex',justifyContent:'flex-end'}}>
                    <div className='nav_container' style={{ marginLeft: '0x',marginRight:'35%' }}>
                      <Stack spacing={2}>
                        <Pagination
                          shape='rounded'
                          count={pcR}
                          color='primary'
                          defaultValue={pageR}
                          onChange={(event, value) => {
                            event.preventDefault();
                            setPageR(value);

                            let message = {
                              pageNo : value,
                              limitPerPage: entriesR,
                              filters: []
                            }
                            for (const key in filters) {
                              if (filters.hasOwnProperty(key) && filters[key]) {
                                // If the checkbox is checked (i.e., the value is true), add the field name to the filters array
                                message.filters.push(key);
                              }
                            }

                            call_data(message);
                          }}
                          variant='outlined'
                          sx={{ fontFamily: "'Fredoka', sans-serif" }}
                        />
                      </Stack>
                    </div>
                    <div className='entries' style={{ marginLeft: '0px' }}>
                <span style={{ marginRight: '8px' }}>Number of Entries:</span>
                <select
                  style={{backgroundColor:'#F7F9FB',padding:'3px',border:'2px solid #B7B7B7',borderRadius:'5px',outline:'none',color:'#B7B7B7'}}
                  defaultValue={entriesR}
                  onChange={async (e) => {
                    setEntriesR(e.target.value)
                    const message = {
                      pageNo: page,
                      limitPerPage:
                        e.target.value > '0' ? e.target.value : 10,
                    };
                    call_data(message);
                  }}
                >
                  <option value='10' style={{color:'black'}}>10</option>
                  <option value='20' style={{color:'black'}}>20</option>
                  <option value='60' style={{color:'black'}}>60</option>
                  <option value='80' style={{color:'black'}}>80</option>
                  <option value='100' style={{color:'black'}}>100</option>
                </select>
              </div>
                    
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingBlock: '10%',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img height='250px' src={emptyData} style = {{maxWidth: "13%"}}/>
                    <div style={{ marginTop: '5%', fontSize: 'x-large' }}>
                      No Rules to display!
                    </div>
                  </div>
                </div>
              )
            ) : tab === 2 && notificationData ? (
              <div className='table-area'>
                <div className='table_container' style={{ marginTop: '20px' }}>
                 
                  {/* <TableContainer component={Paper} sx={{ height: 320  }}> FOR SCROLL THING*/}
                  <TableContainer
                    component={Paper}
                    sx={{
                      height: 688,
                      boxShadow: '0px 20px 50px 0px #0000001A',
                      borderRadius: '8px 8px 0px 0px',
                    }}
                  >
                    {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
                    {/* <Table stickyHeader size="small" aria-label="a dense table"> */}
                    <Table stickyHeader>
                      {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                      {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}
                      <TableHead sx={{ fontFamily: "'Fredoka', sans-serif" }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                           
                          >
                            Rule Name{' '}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                          >
                            Message Found
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                          >
                            Last Updated At
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                          >
                            View Messages
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                          >
                            Export
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ height: '100%' }}>
                        {notificationData?.dbRes?.data.map((row, index) => (
                          <TableRow
                            // key={row.name}
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              background : row.dashboard_seen === false? "#EBF9FF": ""
                            }}
                            className={`${notificationData.dashboard_seen  ? 'red !important' : 'blue'}`}
                          >
                            <TableCell
                              align='center'
                              sx={{
                                fontFamily: "'Fredoka', sans-serif",
                                fontWeight: 'bold',
                                fontSize: 'medium',
                             
                              }}
                              // onClick={(event) => changeValue(event, row)}
                            >
                              { (
                                <div  style={{display:'flex',alignItems:'center',justifyContent:'start'}} >
                                 { !row.dashboard_seen && <img
                                    src={ActiveDot}
                                    alt='active'
                                    style={{ marginRight: '10px' }}
                                  />}
                                  <span>{row.rule.name}</span>
                                </div>
                              )}
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{
                                fontFamily: "'Fredoka', sans-serif",
                                fontWeight: 'bold',
                                fontSize: 'medium',
                              }}
                            >
                              {row.notifications_ids.length}
                            </TableCell>
                            {/* <TableCell align="leMessageft" sx={{ fontFamily: "'Fredoka', sans-serif" , width:'2vw'}}>
                        {(row.chan.matched_text_content).map((tag, i) =>(
                            
                            <div key={i} className='tags'>{tag}</div>
                          ))}
                        </TableCell> */}

                            {/* {console.log('matched media content is here, ' ,row.chan.matched_media_content)} */}
                            <TableCell
                              align='center'
                              sx={{ fontFamily: "'Fredoka', sans-serif" }}
                            >
                              {formatLastAddedat(row.last_added_at.$date)}
                            </TableCell>

                            <TableCell
                              align='center'
                              component='th'
                              scope='row'
                            >
                              {/* {row.article.slice(0, 150)}{row.article.length < 151 ? "" : "..."} */}
                              <img
                                alt='view'
                                src={ViewEye}
                                style={{ margin: 'auto', cursor: 'pointer' }}
                                onClick={async (event) =>{
                                  setPageMessages(1)
                                  const payload = {  
                                    "monitor_notification_id": row._id.$oid,
                                    "pageNo": 1,
                                    "limitPerPage": entriesR
                                }
                                 setRuleName(row.rule.name)
                                  const response =await messages_from_monitor_notification(payload)
                                  setMonitorData(response)
                                  setMonitorId(row._id.$oid);
                                  setMessagefromNotification(true)
                                  setTab(3);
                                }
                                }
                              />
                            </TableCell>
                            <TableCell
                              align='center'
                              component='th'
                              scope='row'
                            >
                              {/* {row.article.slice(0, 150)}{row.article.length < 151 ? "" : "..."} */}
                              <img
                                alt=''
                                src={Export}
                                style={{ margin: 'auto',cursor:'pointer' }}
                                onClick={async()=>{
                                  setExportNotification(!exportNotification)
                                  setExportNotificationId(row._id.$oid)
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    className='pagination_container'
                    style={{ display: 'flex', justifyContent: 'space-between' ,width:'100%'}}
                  >
                    <div
                      className=''
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#898F9A',
                      }}
                    >
                      Showing &nbsp;<span>{(page - 1) * 10 + 1}</span>-
                      <span>{(page - 1) * 10 + det}</span>&nbsp;from&nbsp;
                      <span>{notificationData.dbRes.totalCount.toLocaleString()}</span>&nbsp;Notifications
                    </div>
                    <div className='nav_container' style={{margin:'auto'}}>
                      <Stack spacing={2}>
                        <Pagination
                          shape='rounded'
                          count={Math.ceil(notificationData.dbRes.totalCount / entriesR)}
                          color='primary'
                          value={page}
                          page = {page}
                          onChange={handlePage}
                          variant='outlined'
                          sx={{ fontFamily: "'Fredoka', sans-serif" }}

                        />
                      </Stack>
                    </div>
                    <div className='entries' style={{ marginLeft: '0px' }}>
                <span style={{ marginRight: '8px' }}>Number of Entries:</span>
                <select
                  style={{backgroundColor:'#F7F9FB',padding:'3px',border:'2px solid #B7B7B7',borderRadius:'5px',outline:'none',color:'#B7B7B7'}}
                  defaultValue={entriesR}
                  onChange={async (e) => {
                    setEntriesR(e.target.value);
                    let data = {
                      pageNo: page,
                      limitPerPage: entriesR,
                    };
                    console.log(data);
                    const response = await monitor_notifications_overview(data);
                    setnotificationData(response);
                  }}
                >
                  <option value='10' style={{color:'black'}}>10</option>
                  <option value='20' style={{color:'black'}}>20</option>
                  <option value='60' style={{color:'black'}}>60</option>
                  <option value='80' style={{color:'black'}}>80</option>
                  <option value='100' style={{color:'black'}}>100</option>
                </select>
              </div>
                  </div>
                </div>
              </div>
            ) : tab === 3 ? (
              <div className='table-area'>
                 <div style={{display:'flex',justifyContent:'start',alignItems:'center',marginLeft:'10px'}}>
                    <span style={{ fontWeight: 600, fontSize: '18px' }}>Rule-{""}</span>
                    <span style={{ fontWeight: 600, fontSize: '18px',color:'#00ACFB' }}>{ruleName}</span>

                    </div>
                <div className='table_container' style={{ marginTop: '20px' }}>
                  {/* <TableContainer component={Paper} sx={{ height: 320  }}> FOR SCROLL THING*/}
                  <TableContainer
                    component={Paper}
                    sx={{
                      height: 724,
                      boxShadow: '0px 20px 50px 0px #0000001A',
                      borderRadius: '8px 8px 0px 0px',
                    }}
                  >
                    {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
                    {/* <Table stickyHeader size="small" aria-label="a dense table"> */}
                    <Table stickyHeader style={{ width: "auto", tableLayout: "auto" }}>
                      {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                      {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}
                      <TableHead sx={{ fontFamily: "'Fredoka', sans-serif" }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            
                            }}
                            align='left'
                            width='20%'
                          >
                            Message
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                              width:'20%'
                            }}
                            align='left'
                            width='20%'
                          >
                            Message Keywords
                          </TableCell>

                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                           
                            }}
                            align='left'
                            width='20%'
                          >
                            Channel Name
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                            width='20%'
                          >
                            Sent Time
                          </TableCell>
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              color: '#898F9A',
                              textAlign: 'center',
                            }}
                            align='left'
                            width='20%'
                          >
                            Media Keywords
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ height: 'max-content' }}>
                        {monitorData &&
                          monitorData?.dbRes?.data?.map((row, index) => (
                            <TableRow
                              // key={row.name}
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                background: index % 2 ? '#F5F7FB' : 'FFFFFF',
                                
                              }}
                              onClick={(event) => {
                                changeValue(event, row);
                                setMessageDetailsPop(!messageDetailsPop)
                              }}
                              
                            >
                              <TableCell
                                align='center'
                                sx={{
                                  fontFamily: "'Fredoka', sans-serif",
                                  fontWeight: 'bold',
                                  fontSize: 'medium',
                                }}
                                width='20%'
                                
                              >
                             
                                <div style={{ 
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  width: "300px",
                                  display: "block",
                                  overflow: "hidden"
                              }}>
                                         {row.message_obj.text_content}
                                  </div>
                          
                            
                               
                              </TableCell>

                              {/* <TableCell align="left" sx={{ fontFamily: "'Fredoka', sans-serif" , width:'2vw'}}>
												{(row.chan.matched_text_content).map((tag, i) =>(
														
														<div key={i} className='tags'>{tag}</div>
													))}
												</TableCell> */}
                              <TableCell
                                align='center'
                                sx={{ fontFamily: "'Fredoka', sans-serif" }}
                                width='20%'
                              >
                                {row.matched_text_content.length >= 1 ? (
                                  <div
                                    style={{
                                      width: '100%',
                                      justifyContent: 'center',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      gap: '4px',
                                    }}
                                  >
                                    <div
                                      className='tags'
                                      title={row.matched_text_content.join(
                                        '\n'
                                      )}
                                      style={{
                                        position: 'relative',
                                        width: '100px',
                                      }}
                                    >
                                      {row.matched_text_content[0]}
                                      <span className='tooltip'>
                                        {row.matched_text_content.join('\n')}
                                      </span>
                                    </div>
                                    {/* {row.matched_text_content.length > 1
                                    ? '...'
                                    : ''} */}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <style jsx>{`
                                  .tags:hover .tooltip {
                                    visibility: visible;
                                    opacity: 1;
                                  }

                                  .tags .tooltip {
                                    position: absolute;
                                    z-index: 1;
                                    visibility: hidden;
                                    opacity: 0;
                                    background-color: #000;
                                    color: #fff;
                                    padding: 5px;
                                    border-radius: 5px;
                                    font-size: 12px;
                                    white-space: pre-wrap;
                                    text-align: left;
                                    bottom: 110%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    transition: visibility 0.3s, opacity 0.3s;
                                  }
                                `}</style>
                              </TableCell>
                              {/* {console.log('matched media content is here, ' ,row.chan.matched_media_content)} */}

                              <TableCell
                                align='center'
                                component='th'
                                scope='row'
                                width='20%'
                              >
                                {/* {row.article.slice(0, 150)}{row.article.length < 151 ? "" : "..."} */}
                                {row.matched_entity_name}
                              </TableCell>
                              <TableCell
                                align='center'
                                component='th'
                                create
                                scope='row'
                                width='20%'
                              >
                                {/* {row.article.slice(0, 150)}{row.article.length < 151 ? "" : "..."} */}

                                {formatDate(row.created_at?.$date)}
                              </TableCell>

                              <TableCell
                                align='center'
                                sx={{ fontFamily: "'Fredoka', sans-serif" }}
                                width='20%'
                              >
                                {row.matched_media_content.length >= 1 ? (
                                  <div
                                    style={{
                                      width: '100%',
                                      justifyContent: 'center',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      gap: '4px',
                                    }}
                                  >
                                    <div
                                      className='tags'
                                      title={row.matched_media_content.join(
                                        '\n'
                                      )}
                                      style={{
                                        position: 'relative',
                                        width: '100px',
                                      }}
                                    >
                                      {row.matched_media_content[0]}
                                      <span className='tooltip'>
                                        {row.matched_media_content.join('\n')}
                                      </span>
                                    </div>
                                    {/* {row.matched_text_content.length > 1
                                    ? '...'
                                    : ''} */}
                                  </div>
                                ) : (
                                  <>--</>
                                )}
                                <style jsx>{`
                                  .tags:hover .tooltip {
                                    visibility: visible;
                                    opacity: 1;
                                  }

                                  .tags .tooltip {
                                    position: absolute;
                                    z-index: 1;
                                    visibility: hidden;
                                    opacity: 0;
                                    background-color: #000;
                                    color: #fff;
                                    padding: 5px;
                                    border-radius: 5px;
                                    font-size: 12px;
                                    white-space: pre-wrap;
                                    text-align: left;
                                    bottom: 110%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    transition: visibility 0.3s, opacity 0.3s;
                                  }
                                `}</style>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    className='pagination_container'
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      className=''
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#898F9A',
                      }}
                    >
                      {/* <div>
                <button
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    backgroundColor: 'white',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    test_c({ pageNo: page, limitPerPage: entries });
                  }}
                >
                  <img style={{ height: '3vh' }} src={refresh_btn} />
                </button>
              </div> */}
                      Showing &nbsp;<span>{(pageMessages - 1) * 10 + 1}</span>-
                      <span>{(pageMessages - 1) * 10 + (monitorData?monitorData.dbRes.data.length : 0)}</span>&nbsp;from&nbsp;
                      <span>{monitorData && monitorData.dbRes.totalCount.toLocaleString()}</span>&nbsp;Messages
                    </div>
                    <div className='nav_container' style={{ marginLeft: '0px' }}>
                      <Stack spacing={2}>
                        <Pagination
                          shape='rounded'
                          count={monitorData && Math.ceil(monitorData.dbRes.totalCount / entries)}
                          color='primary'
                          value={pageMessages}
                          // page = {pageMessages}
                          onChange={handlePagemonitor}
                          variant='outlined'
                          sx={{ fontFamily: "'Fredoka', sans-serif" }}
                        />
                      </Stack>
                    </div>
                    <div className='entries' style={{ marginLeft: '0px' }}>
                <span style={{ marginRight: '8px' }}>Number of Entries:</span>
                <select
                  style={{backgroundColor:'#F7F9FB',padding:'3px',border:'2px solid #B7B7B7',borderRadius:'5px',outline:'none',color:'#B7B7B7'}}
                  defaultValue={entriesR}
                  onChange={({target})=>handleMonitorEntries(target.value)}
                >
                  <option value='10' style={{color:'black'}}>10</option>
                  <option value='20' style={{color:'black'}}>20</option>
                  <option value='60' style={{color:'black'}}>60</option>
                  <option value='80' style={{color:'black'}}>80</option>
                  <option value='100' style={{color:'black'}}>100</option>
                </select>
              </div>

                    </div>
                  </div>
                </div>
            
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBlock: '10%',
                }}
              >
                {' '}
                <img src={noData} />
              </div>
            )}
          </div>
        )}
      </div>

     
       {loadForm ? (
        <div
          className='backdropModal'
          onClick={() => {
            setLoadForm(false);
          }}
        >
          <Box
            style={{ zIndex: '3' }}
            className='Box'
            sx={style}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='keywordsModal'>
              <div className='centerHead'>Add Rule to Monitor</div>
              <div className='subHeads'>Name*</div>
              <div className='searchBar'>
                <input
                  type='text'
                  defaultValue={name}
                  onChange={handleNameAdd}
                  className='nameInputBox'
                  placeholder='Drugs, Data, etc'
                />
              </div>
              <div className='subHeads'>Description</div>
              <div className='searchBar'>
                <textarea
                  defaultValue={description}
                  className='descriptionInputBox'
                  placeholder='Add a few lines to describe the monitor rule'
                  onChange={handleDescriptionAdd}
                ></textarea>
                {/* <input type='text' onChange= {handleDescriptionAdd} className='descriptionInputBox' placeholder='Add a few lines to describe the monitor rule' /> */}
              </div>

              <div className='subHeads'>Targeted Channels</div>
              <div>
                <Select
                  className='entryBox'
                  isMulti
                  options={tags}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  placeholder='Search or select options'
                  isSearchable
                  onInputChange={handleInputChange}
                />
              </div>

              <div className='subHeads'>Targeted Channel Discover Rules</div>
              <div>
                <Select
                  className='entryBox'
                  isMulti
                  options={tagsKeyword}
                  value={selectedOptionsKeyword}
                  onChange={handleSelectChangeKeyword}
                  placeholder='Search or select options'
                  isSearchable
                  onInputChange={handleInputChangeKeyword}
                />
              </div>

              <div className='subHeads' style={{display:'flex'}}>Media contained Name
              <img src={InstructionPopUpImg} style={{height:'2vh', marginLeft: "0.5vw"}} onMouseEnter={()=>setinstructionPopUp(true)} onMouseLeave={()=>setinstructionPopUp(false)}/>
              
              </div>
              {instructionPopUp === true ? (
        <div
          style={{width:'fit',position:'absolute',backgroundColor:'#F7F9FB',padding:'1%',borderRadius:'10px',fontSize:'12px',boxShadow: '0px 0px 20px 6px #00000024'}}
          onClick={() => {
            setinstructionPopUp(false);
          }}
        >
          <div
            className=''
            style={{borderRadius:'10px',padding:0,width:'fit'}}
            onClick={(e) => e.stopPropagation()}
          >
              <div style={{width:'200px',textAlign:'center'}}>
                Please Add tags in Upper Case Without Space
              </div>
          </div>
        </div>
      ) : (
        <></>
      )}
              
              <div>
                <MultipleValueTextInput
                  className='multiInputBar'
                  onItemAdded={(item, allItems) => setMediaName(allItems)}
                  onItemDeleted={(item, allItems) => setMediaName(allItems)}
                  submitKeys={['Enter', ',']}
                  name='item-input'
                  placeholder='Enter items; separate them with COMMA or ENTER.'
                  values={mediaName}
                />
              </div>
              <div>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='message-contained-toggle'
                    checked={mediaContained}
                    onChange={() => setMediaContained(!mediaContained)}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='message-contained-toggle'
                  >
                    {mediaContained === true
                      ? 'Case Sensitive'
                      : 'Case Insensitive'}
                  </label>
                </div>
              </div>

              <div className='subHeads'>Message Contains*</div>
              <div>
                {/* <button className='add_KeywordBtn' onClick={(event) => handleKeyAdd(event)}>Add Keywords</button>       */}
                <MultipleValueTextInput
                  className='multiInputBar'
                  onItemAdded={(item, allItems) => {
                    setSelectedKeys(allItems);
                    console.log('key', selectedKeys);
                  }}
                  onItemDeleted={(item, allItems) => setSelectedKeys(allItems)}
                  submitKeys={['Enter', ',']}
                  name='item-input'
                  placeholder='Enter items; separate them with COMMA or ENTER.'
                  values={selectedKeys}
                />
              </div>
              <div>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='message-contained-toggle'
                    checked={messageContained}
                    onChange={() => setMessageContained(!messageContained)}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='message-contained-toggle'
                  >
                    {messageContained === true
                      ? 'Case Sensitive'
                      : 'Case Insensitive'}
                  </label>
                </div>
              </div>

              <div className='finalising_div'>
                <button className='btnCreate' onClick={handleCreateRule}>
                  {row ? 'Update' : 'Create'}
                </button>
                <button className='btnCancel' onClick={handleCancelRule}>
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}


      {viewFormConfirmation === true ? (
        <Box
          className='Box'
          style={{ maxWidth: 'fit-content', padding: '20px', zIndex: '4' }}
          sx={style}
        >
          <div className='keywordsModal'>
            <div>One or more required field left empty.</div>
            <div className='finalising_div' style={{ marginTop: '2vh' }}>
              <button
                className='btnCreate'
                onClick={(event) => {
                  setViewFormConfirmation(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </Box>
      ) : (
        <></>
      )}

      {delete_pop === true ? (
        <Box style={{ zIndex: '3' }} className='Box' sx={style_delete}>
          <div className='keywordsModal'>
            <div>
              Are you sure you want to delete the rule {to_delete_rule.name}?
            </div>
            <div className='finalising_div'>
              <button
                className='btnCreate'
                onClick={(event) => {
                  handleDeletePop(event);
                }}
              >
                Yes
              </button>
              <button
                className='btnCancel'
                onClick={(event) => {
                  setTo_delete_row();
                  setDelete_pop(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </Box>
      ) : (
        <></>
      )}
     
       {messageDetailsPop ? (
            <div
              className='backdropModal'
              onClick={() => {
                setMessageDetailsPop(false);
              }}
            >{console.log(dataToView)}
              <div className='TagsPopup ' onClick={(e) => e.stopPropagation()}>
                <Box
                  className=''
                  sx={style}
                  id='keywordsAdd'
                  style={{ padding: '2% 2%', width: 'fit' }}
                >
                   <div style={{fontSize:'24px',fontWeight:700}}>
                    <span>{dataToView.monitor_rule_name}</span>
                   </div>
                   <div
                      style={{
                        display: 'flex',

                        flexDirection: 'row',
                        alignItems: 'start',
                        alignContent: 'start',
                        margin: '10px 0px',
                        
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            marginRight: '35px',
                          }}
                        >
                          Message
                        </span>
                      </div>
                      <span style={{ marginRight: '15px' }}>:</span>
                      <p
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          maxHeight: "50vh",
                          overflow: "auto"
                        }}
                      >
                        {dataToView.message_obj.text_content}
                      </p>
                    </div>
                    {dataToView.matched_text_content.length > 0 && (
                      <div
                        style={{
                          display: 'flex',

                          flexDirection: 'row',

                          margin: '12px 0px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              width: 'fit',
                              marginRight: '15px',
                            }}
                          >
                            Matched Tags
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#898F9A',
                              marginLeft: '15px',
                            }}
                          >
                            :
                          </span>
                        </div>
                        <span style={{ marginRight: '15px' }}>:</span>
                        <div style={{ width: '100%' }}>
                          {dataToView.matched_text_content?.length > 0 ? (
                            <div className='' style={{}}>
                              {dataToView.matched_text_content.map((mentioned, i) => (
                                <div
                                  className='crypoBox'
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: '5px',
                                    
                                  }}
                                
                                >
                                  <div
                                    style={{ fontFamily: 'Roboto' ,}}
                                    className='job_link_'
                                  >
                                    {mentioned}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                    {dataToView?.message_obj.attached_media.media_present && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          flexDirection: 'row',

                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '14px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              width: 'fit',
                              marginRight: '47px',
                            }}
                          >
                            Files
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#898F9A',
                              marginLeft: '15px',
                            }}
                          >
                            :{}
                          </span>
                        </div>
                        <span style={{ marginRight: '15px' }}>:</span>
                        <p
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'justify',
                            fontSize: '14px',
                          }}
                        >
                          {dataToView?.message_obj.attached_media.media_present === true ? (
                            <div
                              className=''
                              style={{
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span style={{ width: '100%' }}>
                                {dataToView?.message_obj.attached_media.media_name}
                              </span>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  
                                }}
                              >
                                <img
                                  alt='download'
                                  style={{ height: '2vh' }}
                                  src={download_icon}
                                />
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    )}
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      marginTop: '15px',
                    }}
                  >
                    <Link
                    style={{ fontFamily: 'Roboto' }}
                    target='_blank'
                    to={`/messages?messageRefId=${dataToView?.message_obj.message_db_ref_id}`}
                  >
                    <button
                      style={{
                        borderRadius: '10px',
                        fontSize: '16px',
                        padding: "1vh 1vw 1vh 1vw"
                      }}
                      onClick={()=>{
                        setMessageDetailsPop(!messageDetailsPop)
                      }}
                    >
                     View Message
                    </button>
                    </Link>
                    
                    <Link
                    style={{
                      marginLeft: "2vw",
                    }}
                    target='_blank'
                    to={`/channels?channel_Id=${dataToView?.message_obj.meta_data.sender_id}`}
                  >
                    <button
                      style={{
                        borderRadius: '10px',
                        fontSize: '16px',
                        padding: "1vh 1vw 1vh 1vw"
                      }}
                      onClick={()=>{
                        setMessageDetailsPop(!messageDetailsPop)
                      }}
                    >
                     View Channel
                    </button>
                    </Link>
                   
                  </div>
                </Box>
              </div>
            </div>
          ) : (
            <></>
          )}
    </>
  );
};

import React from 'react';
import { user_login } from '../endpoints/login';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import EyeClose from '../components/icons/EyeClose';
import EyeOpen from '../components/icons/EyeOpen';

export const Login = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [credentialsPop, setCredentialsPop] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [usernameFieldCheck, setUsernameFieldCheck] = React.useState(false);
  const [passwordFieldCheck, setPasswordFieldCheck] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleNameAdd = (event) => {
    const value = event.target.value;
    setUsername(value);

    if (value.trim() === '') {
      event.target.style.border = '1px solid red';
    } else {
      event.target.style.border = '';
    }
  };

  const handlepasswordAdd = (event) => {
    const value = event.target.value;
    setPassword(value);

    if (value.trim() === '') {
      event.target.style.border = '1px solid red';
    } else {
      event.target.style.border = '';
    }
  };

  const handleLogin = async () => {
    if (!username || !password || username === '' || password === '') {
      if (!username || username === '') setUsernameFieldCheck(true);
      else setUsernameFieldCheck(false);
      if (!password || password === '') setPasswordFieldCheck(true);
      else setPasswordFieldCheck(false);

      toast.warn('Required field is empty !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      const message = {
        email_id: username,
        password: password,
      };
      // console.log('logging in with this message : ', message);
      let response = {};
      response = await user_login(message);
      // console.log('your response.data : ', response.data);
      if (response.status === 200) {
        const token = response.data.dbRes.token;
        // console.log('here is your token : ', token);
        const secretKey = 'some_secret_key';
        const ciphertext = CryptoJS.AES.encrypt(token, secretKey);
        Cookies.set('token', ciphertext);
        Cookies.set('user_name', response.data.dbRes.name);
        if (response.data.error.length > 0) {
          // console.log('Wrong credentials');
          setCredentialsPop(true);
          toast.error(response.data.error);
        } else {
          setValid(true);
        }
      } else if (response.status === 500) {
        toast.error(response.data.error);
      } else {
        toast.error(response.data.error);
      }
    }
  };

  React.useEffect(() => {
    Cookies.remove('token');
  }, [username, password]);

  if (valid === true) {
    return <Navigate to='/home' />;
  }

  return (
    <div className='Login'>
      <div className='upperCylinder'>
        <div className='upperBean'></div>
      </div>
      <div className='upperCylinder'>
        <div className='lowerBean'></div>
      </div>
      <div className='frame'>
        <div className='frameBox'>
          <div className='frameItem1'>
            <h1>TeleMon</h1>
            <h5 className='subhead'>Login to continue</h5>
          </div>
          <div className='frameItem2'>
            <div className='email'>
              <h6>Email/Mobile Number*</h6>
              <input
                className='inputBox'
                onChange={handleNameAdd}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') handleLogin();
                }}
                style={usernameFieldCheck ? { border: '1px solid red' } : {}}
                type='text'
                placeholder='Enter your Email or Mobile Number'
              />
            </div>
            <div className='password'>
              <div>
                <h6>Password*</h6>
                <div className='inputPasswordContainer'>
                  <input
                    className='inputBox'
                    onChange={handlepasswordAdd}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') handleLogin();
                    }}
                    style={
                      passwordFieldCheck ? { border: '1px solid red' } : {}
                    }
                    type={`${showPassword ? 'text' : 'password'}`}
                    placeholder='Enter your Password'
                  />
                  {showPassword ? (
                    <EyeOpen
                      className='eyeLogin'
                      onClick={(e) => {
                        setShowPassword((prev) => !prev);
                      }}
                    />
                  ) : (
                    <EyeClose
                      className='eyeLogin'
                      onClick={(e) => {
                        setShowPassword((prev) => !prev);
                      }}
                    />
                  )}
                </div>
                <div className='remember'>
                  <input className='form-check-input' type='checkbox' />
                  Remember Me
                </div>
              </div>
              <div>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    handleLogin();
                  }}
                >
                  Login{' '}
                </button>
              </div>
              {/* <p className='forgotPassword'>Forgot Password?</p> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        toastStyle={{ maxWidth: 'none', whiteSpace: 'nowrap' }}
      />
    </div>
  );
};

const EyeClose = ({ fill = 'black', ...props }) => {
  return (
    <svg
      {...props}
      width='22'
      height='8'
      viewBox='0 0 22 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 5.5C7.2 5.5 3.8 3.4 2.2 0H0C1.7 4.4 6 7.5 11 7.5C16 7.5 20.3 4.4 22 0H19.8C18.2 3.4 14.8 5.5 11 5.5Z'
        fill={fill}
      />
    </svg>
  );
};

export default EyeClose;

/*
  Packages
*/
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

/*
  Api
*/
import { get_user_details } from '../endpoints/user_details';
import { reset_password } from '../endpoints/reset_password';
import { slack_details } from '../endpoints/slack_details';
import { slack_details_update } from '../endpoints/slack_details_update';
import InstructionPopUp from '../images/instrucitoneye.svg';
import CopyToClipboard from '../images/clipboard.svg';

const style_pop = {
  position: 'fixed',
  top: '50%',
  left: '25%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  paddingInline: 8,
  paddingTop: 4,
  paddingBottom: 4,
  zIndex: 4,
};

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return dateObject.toLocaleString('en-US', options);
};

export const Settings = () => {
  const [confirmationPop, setConfirmationPop] = React.useState(false);
  const [previousPassword, setPreviousPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPassword2, setNewPassword2] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [userDetails, setUserDetails] = React.useState([]);
  const [slackDetails, setSlackDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [discoverChannel, setDiscoverChannel] = React.useState('');
  const [monitorChannel, setMonitorChannel] = React.useState('');
  const [tasksChannel, setTasksChannel] = React.useState('');
  const [appToken, setAppToken] = React.useState('');
  const [slackLoad, setSlackLoad] = React.useState(true);
  const [confirmationPopSlack, setConfirmationPopSlack] = React.useState(false);
  const [instructionPopUp, setinstructionPopUp] = React.useState(false);
  const [channelId, setChannelId] = React.useState('');
  const [userId, setuserId] = React.useState();
  const [apiKey, setApikey] = React.useState();
  //function to send the updated slack channels required by the user to the backend

  const addUnattendeChannelHandler = () => {
    let userIds = [userId];
    let Data = {
      entity_id: channelId,
      user_ids: userIds,
    };

    if (channelId.length > 1) {
      const apiUrl = process.env.REACT_APP_API_URL;
      const Headers = {
        'x-access-token': apiKey,
        'Content-Type': 'application/json',
      };
      axios
        .post(`${apiUrl}/api/add_entity_users`, Data, { headers: Headers })
        .then((res) => {
          if (res && res.data.processed) {
            // Handle Endpoint success
            toast.success('Joined ', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
            // After the success resetting the InputFiled to ""
            setChannelId('');
          } else {
            toast.error('Provide Valid ID  ', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err) {
            // Handle Endpoint errors
            toast.error('Something went worng ', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        });
    } else {
      // Handle Null values in input Filed
      toast.error('Please provide Channel Id ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const handleSlackUpdate = async () => {
    if (
      appToken === '' ||
      discoverChannel === '' ||
      monitorChannel === '' ||
      tasksChannel === ''
    ) {
      setErrorMessage('All the fields cannot be left empty!');
      setPasswordError(true);
    } else {
      let message = {
        organization_token: appToken,
        channel_discovery_slack_channel: discoverChannel,
        monitor_messages_slack_channel: monitorChannel,
        task_status_slack_channel: tasksChannel,
      };

      console.log('message sent: ', message);
      const response = await slack_details_update(message);
      setAppToken('');
      setDiscoverChannel('');
      setTasksChannel('');
      setMonitorChannel('');
      if (response.error.length > 0) {
        toast.error('Oops! something went wrong.');
        setErrorMessage(response.error);
        setPasswordError(true);
      } else {
        toast.success('Updated', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }

      fetchSlackData({});
    }
  };

  //function to update user password as required by the user
  const handleUpdate = async () => {
    let message = {};
    if (newPassword === '' || previousPassword === '' || newPassword2 === '') {
      setErrorMessage(
        'Please enter both the previous and the new password respectively!'
      );
      setPasswordError(true);
    } else if (newPassword === previousPassword) {
      setErrorMessage('New password cannot be the same as previous password!');
      setPasswordError(true);
    } else if (newPassword != newPassword2) {
      setErrorMessage('The new passwords entered do not match!');
      setPasswordError(true);
    } else {
      message = {
        current_password: previousPassword,
        new_password: newPassword,
      };

      console.log('message sent: ', message);
      const response = await reset_password(message);
      setNewPassword('');
      setPreviousPassword('');
      setErrorMessage('');
      setNewPassword2('');
      if (response.error.length > 0) {
        toast.error('Oops! something went wrong.');
        setErrorMessage(response.error);
        setPasswordError(true);
      } else {
        toast.success('Updated', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    }
  };

  // const fetchUserData = async() =>{
  //      const message={};
  //      const response = await get_user_details(message);
  //      console.log('here is your user details : ', response);
  //      setUserDetails(response.dbRes);
  //      setLoading(false);
  // }

  function createData(
    access_level,
    all_requested_entity_ids,
    assigned_clients,
    created_at,
    email_id,
    is_demo_only_user,
    last_login_at,
    last_login_ip,
    name,
    total_uploads,
    user_type,
    updated_at,
    _id,
    api_config
  ) {
    return {
      access_level,
      all_requested_entity_ids,
      assigned_clients,
      created_at,
      email_id,
      is_demo_only_user,
      last_login_at,
      last_login_ip,
      name,
      total_uploads,
      user_type,
      updated_at,
      _id,
      api_config,
    };
  }

  function createSlackData(
    app_token,
    discovery_channels,
    monitor_channels,
    tasks_channels
  ) {
    return { app_token, discovery_channels, monitor_channels, tasks_channels };
  }

  const fetchSlackData = async (message) => {
    let response = {};
    console.log('message: ', message);
    response = await slack_details(message);
    let chan = response.dbRes;
    // const { chan, pCount, tot } = response;
    let test_rows = [];

    console.log('response : ', response);

    test_rows.push(
      createSlackData(
        chan.organization_token,
        chan.channel_discovery_slack_channel,
        chan.monitor_messages_slack_channel,
        chan.task_status_slack_channel
      )
    );
    setSlackDetails(test_rows);
    setSlackLoad(false);
  };

  const fetchUserData = async (message) => {
    let response = {};
    console.log('message: ', message);
    response = await get_user_details(message);
    let chan = response.dbRes;
    // const { chan, pCount, tot } = response;
    let test_rows = [];

    console.log('response : ', response);

    test_rows.push(
      createData(
        chan.access_level,
        chan.all_requested_entity_ids,
        chan.assigned_clients,
        chan.created_at.$date,
        chan.email_id,
        chan.is_demo_only_user,
        chan.last_login_at.$date,
        chan.last_login_ip,
        chan.name,
        chan.total_uploads,
        chan.user_type,
        chan.updated_at.$date,
        chan._id,
        chan.api_config
      )
    );
    setUserDetails(test_rows);
    setuserId(test_rows[0]._id.$oid);
    setApikey(test_rows[0].api_config.api_key);
    setLoading(false);
  };

  React.useEffect(() => {
    const message = {};
    fetchUserData(message);

    fetchSlackData(message);
  }, []);

  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      {loading ? (
        <div>Load</div>
      ) : (
        <div className='page_container_jobs' style={{ position: 'relative' }}>
          <div className=''>
            <div className='Head1_jobs'>Settings</div>
            {/* <div className='search_form_container'>
                    <form className='search_form' onSubmit={handleSearch}>
                        <select className='form_dropdown' value={selectedOption} onChange={handleOptionChange}>
                            {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                            ))}
                        </select>
                        <input
                            className='form_input_bar'
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(event) => setSearchQuery(event.target.value)}
                        />
                        <button className='formSearchBtn' type="submit">Search</button>
                    </form>
                </div>       */}
          </div>

          {instructionPopUp === true ? (
            <div
              style={{
                width: 'fit',

                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 5,
                top: '25%',
                left: '25%',
                backgroundColor: 'white',
                padding: '2%',
                borderRadius: '10px',
                fontSize: '12px',
                boxShadow: '0px 0px 20px 6px #00000024',
              }}
              onClick={() => {
                setinstructionPopUp(false);
              }}
            >
              <div
                className=''
                style={{ borderRadius: '10px', padding: 0, width: 'fit' }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  style={{
                    width: 'fit',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '24px',
                      fontWeight: 700,
                      marginBottom: '10px',
                    }}
                  >
                    How to Set Up Slack Alerts
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      fontSize: '14px',
                      fontWeight: 400,
                      gap: '2%',
                    }}
                  >
                    <span>
                      1.Log in to Slack using your organization's email ID in
                      your browser.
                    </span>
                    <span>
                      2.Create a new private or public channel in Slack.
                    </span>

                    <span>
                      {' '}
                      3.Visit the Slack API website at
                      https://api.slack.com/apps.
                    </span>

                    <span>
                      4.Click on "Create New App" to create a new app.
                    </span>
                    <span>
                      5.Provide a name for your app and select the newly created
                      channel to install it on. Then, go to the "OAuth &
                      Permissions" tab.
                    </span>
                    <span>
                      6.Under the "Bot Token Scopes" section, add the necessary
                      scopes for your app.
                    </span>
                    <span>
                      7.Go back to the private or public channel you created
                      earlier and use the "/add" command to add the new app.
                    </span>
                    <span>
                      8.Go back to the private or public channel you created
                      earlier and use the "/add" command to add the new app.
                    </span>
                    <span>
                      9.Retrieve the token from the "OAuth Tokens for Your
                      Workspace" section under the "OAuth & Permissions" tab of
                      your app settings.
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '10px 0px',
                    }}
                  >
                    <button
                      onClick={(event) => {
                        setinstructionPopUp(!instructionPopUp);
                      }}
                      className='modalBtnConfirm'
                      style={{ width: '10%' }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {!slackLoad ? (
            <>
              <div
                style={{
                  fontSize: 'x-large',
                  fontFamily: "'Roboto', 'sans-serif'",
                  margin: '1% 0%',
                  width: '100%',

                  fontWeight: 'bold',
                }}
              >
                Profile Details
              </div>
              <div className=''>
                <div
                  className=''
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 0px',
                  }}
                >
                  <div className='field_head'>Username </div>
                  <div className='field_value'>: {userDetails[0].name}</div>
                </div>

                <div
                  className=''
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 0px',
                  }}
                >
                  <div className='field_head'>Email ID</div>
                  <div className='field_value'>: {userDetails[0].email_id}</div>
                </div>
                <div
                  className=''
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 0px',
                  }}
                >
                  <div className='field_head'>User Privileges </div>
                  <div className='field_value'>
                    :{' '}
                    {userDetails[0].access_level.can_launch_jobs
                      ? 'Launch jobs, '
                      : ''}
                    {userDetails[0].access_level.can_request_join_channel
                      ? 'Join Channels, '
                      : ''}
                    {userDetails[0].access_level.can_request_report_export
                      ? 'Request report export, '
                      : ''}
                    {userDetails[0].access_level.can_upload_files_with_id
                      ? 'Upload files, '
                      : ''}
                    {userDetails[0].access_level.can_write_monitor_rules
                      ? 'Write monitor rules, '
                      : ''}
                    {userDetails[0].access_level.restricted ? 'Restricted' : ''}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 0px',
                  }}
                >
                  <div className='field_head'>Account Type </div>
                  <div className='field_value'>
                    : {userDetails[0].user_type}
                  </div>
                </div>
                {/* <div className='field_container'>
                <div className='field_head'>Account Name  </div>
                <div className='field_value'>: Sample</div>
            </div> */}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 0px',
                  }}
                >
                  <div className='field_head'>API key </div>
                  <div className='field_value'>
                    : {userDetails[0].api_config.api_key}
                  </div>
                </div>
                <div
                  className='multi_field_column'
                  style={{ margin: '5px 0px' }}
                >
                  <div className='field_container'>
                    <div className='field_head_multi_first'>Total API hits</div>
                    <div className='field_value'>
                      : {userDetails[0].api_config.request_total_count}
                    </div>
                  </div>
                  <div className='field_container'>
                    <div className='field_head_multi'>API hits today </div>
                    <div className='field_value'>
                      : {userDetails[0].api_config.request_today_count}
                    </div>
                  </div>
                </div>
                <div
                  className='multi_field_column'
                  style={{ margin: '5px 0px' }}
                >
                  <div className='field_container'>
                    <div className='field_head_multi_first'>Last Active IP</div>
                    <div className='field_value'>
                      : {userDetails[0].last_login_ip}
                    </div>
                  </div>
                  <div className='field_container'>
                    <div className='field_head_multi'>
                      Last login timestamp :
                    </div>
                    <div className='field_value'>
                      {formatDate(userDetails[0].updated_at)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginTop: '2%',
                  }}
                >
                  <div className='field_head' style={{ width: '14%' }}>
                    Change Password
                  </div>
                  <div className='multi_field_column' style={{ gap: '2%' }}>
                    <TextField
                      style={{ width: '100%' }}
                      id='password'
                      label='Previous Password'
                      type='password'
                      autoComplete='current-password'
                      variant='outlined'
                      value={previousPassword}
                      onChange={(event) =>
                        setPreviousPassword(event.target.value)
                      }
                    />

                    <TextField
                      style={{ width: '100%' }}
                      id='password'
                      label='New Password'
                      type='password'
                      autoComplete='current-password'
                      variant='outlined'
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                    />

                    <TextField
                      style={{ width: '100%' }}
                      id='password'
                      label='Retype New Password'
                      type='password'
                      autoComplete='current-password'
                      variant='outlined'
                      value={newPassword2}
                      onChange={(event) => setNewPassword2(event.target.value)}
                    />
                  </div>
                </div>

                <div
                  className='field_container'
                  style={{ alignItems: 'center', justifyContent: 'end' }}
                >
                  <button
                    style={{
                      marginTop: '2%',
                      width: 'fit-content',
                      borderRadius: '8px',
                      fontSize: 'medium',
                      paddingInline: '3%',
                      marginBottom: '10px',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setConfirmationPop(true);
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
              <div
                style={{
                  paddingTop: '1%',
                  width: '100%',
                  textAlign: 'left',
                  borderTop: '1px solid #8C8C8C',

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <span
                    style={{
                      fontSize: 'x-large',
                      fontFamily: "'Roboto', 'sans-serif'",
                      fontWeight: 'bold',
                    }}
                  >
                    Slack Settings
                  </span>
                  <img
                    src={InstructionPopUp}
                    style={{
                      height: '2vh',
                      margin: '0px 10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setinstructionPopUp(!instructionPopUp);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px', margin: '0px 8px' }}
                  />
                  <span>Allow all Notifications</span>
                </div>
              </div>

              <div className='' style={{ marginTop: '-2%' }}>
                {/* <div className='field_container' style={{paddingTop:'2%'}}>
                <div className='field_head' style={{width:'22%'}}>App Token  </div>
                <div className='field_value'>: {userDetails[0].api_config.api_key}</div>
            </div> */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2%',
                    paddingTop: '2%',
                  }}
                >
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px', margin: '0px 8px' }}
                  />
                  <div className='field_head' style={{ width: '30%' }}>
                    Organisation Token
                  </div>
                  <div
                    className='multi_field_column'
                    style={{ gap: '2%', justifyContent: 'left' }}
                  >
                    <div
                      className='field_value'
                      style={{ minWidth: 'max-content', width: '30%' }}
                    >
                      : {slackDetails[0].app_token}
                    </div>
                    <TextField
                      style={{ width: '50%' }}
                      id='standard-basic'
                      label='Add your organisations slack token'
                      // type="text"
                      // autoComplete="current-password"
                      variant='outlined'
                      value={appToken}
                      onChange={(event) => setAppToken(event.target.value)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2%',
                  }}
                >
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px', margin: '0px 8px' }}
                  />
                  <div className='field_head' style={{ width: '30%' }}>
                    Channel Discovery Slack Channel
                  </div>
                  <div
                    className='multi_field_column'
                    style={{ gap: '2%', justifyContent: 'left' }}
                  >
                    <div
                      className='field_value'
                      style={{ minWidth: 'max-content', width: '30%' }}
                    >
                      : {slackDetails[0].discovery_channels}
                    </div>
                    <TextField
                      style={{ width: '50%' }}
                      id='standard-basic'
                      label='Channel Discovery Slack Channel'
                      // type="text"
                      // autoComplete="current-password"
                      variant='outlined'
                      value={discoverChannel}
                      onChange={(event) =>
                        setDiscoverChannel(event.target.value)
                      }
                    />
                  </div>
                  {console.log(slackDetails)}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2%',
                  }}
                >
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px', margin: '0px 8px' }}
                  />
                  <div className='field_head' style={{ width: '30%' }}>
                    Monitor Messages Slack Channel
                  </div>
                  <div
                    className='multi_field_column'
                    style={{ gap: '2%', justifyContent: 'left' }}
                  >
                    <div
                      className='field_value'
                      style={{ minWidth: 'max-content', width: '30%' }}
                    >
                      : {slackDetails[0].monitor_channels}
                    </div>
                    <TextField
                      style={{ width: '50%' }}
                      id='standard-basic'
                      label='Monitor Messages Slack Channel'
                      // type="text"
                      // autoComplete="current-password"
                      variant='outlined'
                      value={monitorChannel}
                      onChange={(event) =>
                        setMonitorChannel(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2%',
                  }}
                >
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px', margin: '0px 8px' }}
                  />
                  <div className='field_head' style={{ width: '30%' }}>
                    Task Status Slack Channel
                  </div>
                  <div
                    className='multi_field_column'
                    style={{ gap: '2%', justifyContent: 'left' }}
                  >
                    <div
                      className='field_value'
                      style={{ minWidth: 'max-content', width: '30%' }}
                    >
                      : {slackDetails[0].tasks_channels}
                    </div>
                    <TextField
                      style={{ width: '50%' }}
                      id='standard-basic'
                      label='Task Status Slack Channel'
                      // type="text"
                      // autoComplete="current-password"
                      variant='outlined'
                      value={tasksChannel}
                      onChange={(event) => setTasksChannel(event.target.value)}
                    />
                  </div>
                </div>

                <div
                  className='field_container'
                  style={{ alignItems: 'center', justifyContent: 'end' }}
                >
                  <button
                    style={{
                      marginTop: '2%',
                      marginBottom: '3%',
                      width: 'fit-content',
                      borderRadius: '8px',
                      fontSize: 'medium',
                      paddingInline: '3%',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setConfirmationPopSlack(true);
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
              <hr />
              <div>
                {' '}
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: 750,
                    borderRadius: '50px',
                    padding: '0 0 20px 0',
                  }}
                >
                  Join Unattended Channel
                </h1>
                <div
                  className=''
                  style={{
                    flex: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    id='standard-basic'
                    label='Provide the channel ID'
                    variant='outlined'
                    value={channelId}
                    fullWidth
                    style={{ width: '400px' }}
                    onChange={(e) => setChannelId(e.target.value)}
                  />
                  <div
                    style={{
                      height: '5vh',
                      backgroundColor: '#00acfb',
                      color: '#ffffff',
                      border: 'none',
                      fontSize: '20px',
                      paddingTop: '6px',
                      fontWeight: 500,
                      width: '8vw',
                      textAlign: 'center',
                      position: 'absolute',

                      borderRadius: '8px',
                      cursor: 'pointer',
                      marginTop: '10px',
                    }}
                    onClick={addUnattendeChannelHandler}
                  >
                    Join
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {confirmationPop === true ? (
            <Box
              className='Box'
              style={{ width: '30%', padding: '20px' }}
              sx={style_pop}
            >
              <div className='keywordsModal'>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 'x-large',
                  }}
                >
                  Are you sure you want to update ?
                </div>
                <div
                  className='finalising_div'
                  style={{ marginTop: '2vh', gap: '3%' }}
                >
                  <button
                    className='btnCreate'
                    onClick={(event) => {
                      event.preventDefault();
                      setConfirmationPop(false);
                      handleUpdate();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className='btnCancel'
                    onClick={(event) => {
                      event.preventDefault();
                      setConfirmationPop(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </Box>
          ) : (
            <></>
          )}

          {confirmationPopSlack === true ? (
            <Box
              className='Box'
              style={{ width: '30%', padding: '20px' }}
              sx={style_pop}
            >
              <div className='keywordsModal'>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 'x-large',
                  }}
                >
                  Are you sure you want to update ?
                </div>
                <div
                  className='finalising_div'
                  style={{ marginTop: '2vh', gap: '3%' }}
                >
                  <button
                    className='btnCreate'
                    onClick={(event) => {
                      event.preventDefault();
                      setConfirmationPopSlack(false);
                      handleSlackUpdate();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className='btnCancel'
                    onClick={(event) => {
                      event.preventDefault();
                      setConfirmationPopSlack(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </Box>
          ) : (
            <></>
          )}

          {passwordError === true ? (
            <Box
              className='Box'
              style={{ width: '30%', padding: '20px' }}
              sx={style_pop}
            >
              <div className='keywordsModal'>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'red',
                    fontSize: 'x-large',
                  }}
                >
                  {errorMessage}
                </div>
                <div
                  className='finalising_div'
                  style={{ marginTop: '2vh', gap: '3%' }}
                >
                  <button
                    className='btnCreate'
                    onClick={(event) => {
                      event.preventDefault();
                      setPasswordError(false);
                      setErrorMessage('');
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </Box>
          ) : (
            <></>
          )}

          <ToastContainer
            position='top-center'
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            toastStyle={{ maxWidth: 'none', whiteSpace: 'nowrap' }}
          />
        </div>
      )}
    </>
  );
};

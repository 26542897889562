import axios from 'axios'
import instance from './interceptor';

export const read_unread_update = async(data)=>{

  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
 
  let res = []
console.log(data);
console.log('hiting endpoint tag update');
    await instance.post(`${apiUrl}/api/mark_read_unread`, data, config)
    .then(response=>{
      console.log('hit');
        res = response.data;
        console.log(response.data)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }




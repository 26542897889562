import React from 'react';
import { Dashleft } from '../components/dashleft';
import { Notify } from '../components/notification';

export const Notification = () => {
  return (
    <div className='dashboard'>
      <Dashleft id={5} />
      <Notify />
    </div>
  );
};

import axios from 'axios'
import instance from './interceptor';
export const run_indiviual_rule = async(data)=>{

  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
  let res = []
  console.log(data);
 
  await instance.post(`${apiUrl}/api/keyword_discover_rule/run_rule`, data, config)
  
    .then(response=>{
        res = response.data;
        console.log(res);
    })
    .catch(error=>{
      console.log(error)
    }) 
    return res;

  }

import React from 'react';
//import {Dashtop} from './components/dashtop'
import { Dashleft } from '../components/dashleft';
import { Tables } from '../components/table';

export const Dashboard = () => {
  return (
    <div className='dashboard'>
      <Dashleft id={1} />
      <Tables />
    </div>
  );
};

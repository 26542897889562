import React from 'react';
import { Dashleft } from '../components/dashleft';
import { Settings } from '../components/settings';

export const Setting = () => {
  return (
    <div className='dashboard'>
      <Dashleft id={6} />
      <Settings />
    </div>
  );
};

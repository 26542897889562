import axios from 'axios'

import instance from './interceptor';
export const update_rule = async(data)=>{

  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
  let res = []
console.log(data);
console.log('hiting endpoint monitor Rule update');
    await instance.post(`${apiUrl}/api/query_monitor_rules`, data, config)
    .then(response=>{
      console.log('hit');
        res = response.data;
        console.log(response.data)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }

//   export const update_rule = async(data)=>{
//     const config = {
//       headers:{
//         'Access-Control-Allow-Origin': '*',
//         'x-access-token': '1ffd3355-b2bf-4b1f-a5d4-a5fe4a46e3c3',
//         'Content_Type': 'application/json'
//       },
//       params: data
//     }
   
//     console.log(data);
//     console.log('hitting endpoint monitor Rule update');
  
//     let res = await instance.get('http://localhost:5000/api/query_monitor_rules', config)
//       .then(response=>{
//         console.log('hit');
//         console.log(response.data);
//         return response.data;
//       })
//       .catch(error=>{
//         console.log(error)
//       });
      
//     console.log(res);
//     return res;
//   }
  

/*
  Packages
*/
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
/*
  Api
*/
import { tasks } from '../endpoints/tasks';

/*
  Assets
*/
import info from '../images/info.svg';
import sortIcon from '../images/sort.svg';
import loadingImg from '../images/loading.svg';
import Edited from '../images/Edited.svg';
import { jobs_retry } from '../endpoints/jobs_retry';
import { render } from 'react-dom';

const options = [
  { label: 'Select an option', value: '' },
  { label: 'Join channel request', value: 'JOIN_CHANNEL_REQUEST' },
  { label: 'Join channel and export', value: 'JOIN_CHANNEL_AND_EXPORT_REQUEST'},
  { label: 'Channel Export', value: 'QUERY_CHANNELS_EXPORT' },
  { label: 'Messages Export', value: 'QUERY_MESSAGES_EXPORT' },
  { label: 'Messages Export', value: 'QUERY_MESSAGES_EXPORT_WITH_MEDIA' },
  { label: 'Media File', value: 'FILE_UPLOAD_S3' },
  { value: 'UPDATE_MESSAGES_TAGS', label: 'Messages tags update' },
  { value: 'UPDATE_CHANNELS_TAGS', label: 'Channels tags update' },
  { label: 'keyword discover notification', value: 'EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION'},
  { label: 'keyword discover rule', value: 'EXPORT_KEYWORD_DISCOVER_RULE'},
  { label: 'message monitor rule', value: 'EXPORT_MESSAGE_MONITOR_RULE'},
  { label: 'message monitor notifications', value: 'EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION'}
];

const style_pop = {
  position: 'absolute',
  top: '50%',
  left: '25%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  paddingInline: 8,
  paddingTop: 4,
  paddingBottom: 4,
  zIndex: 4,
};

export const Notify = () => {
  const [keywords, setKeywords] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [sample_rows, setSample_rows] = React.useState([]);
  const [pc, setPc] = React.useState(1);
  const [det, setDet] = React.useState(10);
  const [totCount, setTotCount] = React.useState(4000);
  //    const buttonRef = React.useRef(null);
  const [check, setCheck] = React.useState([]);
  const [fin_tags, setFin_tags] = React.useState([]);
  const [entries, setEntries] = React.useState(15);
  const [sortField, setSortField] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState(0);
  const d = moment();
  const defaultFromDate = moment().subtract(122, 'years').startOf('year');
  const initialFromDate = defaultFromDate.format('YYYY-MM-DD');
  const defaultToDate = moment().add(77, 'years').endOf('year');
  const initialToDate = defaultToDate.format('YYYY-MM-DD');
  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [fromDateCopy, setFromDateCopy] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);
  const [toDateCopy, setToDateCopy] = React.useState(initialToDate);
  const [dateFlag, setDateFlag] = React.useState(0);
  const [checkboxFilter, setCheckboxFilter] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [successBool, setSuccessBool] = React.useState(false);
  const [failureBool, setFailureBool] = React.useState(false);
  const [pendingBool, setPendingBool] = React.useState(false);
  const [revokedBool, setRevokedBool] = React.useState(false);
  const [startedBool, setStartedBool] = React.useState(false);
  const [retryBool, setRetryBool] = React.useState(false);
  const [historyPop, setHistoryPop] = React.useState([])
  const [historyBool, setHistoryBool] = React.useState(false)
  const [popRow, setPopRow] = React.useState();
  
  const [loading, setLoading] = React.useState(true);

  const handleClick = (bucketId) => {
    console.log(bucketId)
    const filename = bucketId.split('/')[1];
    const url = `${process.env.REACT_APP_CLOUDFRONT_URL}/${bucketId.replace(
      ' ',
      '%20'
    )}`;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    console.log(url);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const getTimeElapsed = (start_time, end_time) => {
    const start = new Date(start_time);
    const end = new Date(end_time);
    const elapsed_ms = end - start;

    const elapsed_minutes = Math.floor(elapsed_ms / 1000 / 60);
    const hours = Math.floor(elapsed_minutes / 60);
    const minutes = elapsed_minutes % 60;

    const timeElapsedString =
      hours > 0 ? `${hours} hrs ${minutes} mins` : `${minutes} mins`;

    return timeElapsedString;
  };

  const successPop = (event, row) => {
    event.preventDefault();
    console.log(row)
    setPopRow(row);
    setSuccessBool(true);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
  };

  const failurePop = (event, row) => {
    event.preventDefault();
    setPopRow(row);
    setFailureBool(true);
    setSuccessBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
  };

  const retryPop = (event, row) => {
    event.preventDefault();
    setPopRow(row);
    setRetryBool(true);
    setSuccessBool(false);
    setFailureBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
  };

  const revokedPop = (event, row) => {
    event.preventDefault();
    setPopRow(row);
    setRevokedBool(true);
    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setStartedBool(false);
    setPendingBool(false);
  };

  const startedPop = (event, row) => {
    event.preventDefault();
    setPopRow(row);
    setStartedBool(true);
    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setPendingBool(false);
  };

  const pendingPop = (event, row) => {
    event.preventDefault();
    setPopRow(row);
    setPendingBool(true);
    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
    setSortField('');
    setSortDirection(0);
    setPopRow();
    setLoading(true);

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      filterField: event.target.value,
    };

    test_c(message);
  };

  const handleSort = (event, field) => {
    let message = {};
    if (sortField === field) {
      if (sortDirection === 1) {
        setSortDirection(-1);
        message = {
          pageNo: 1,
          limitPerPage: entries,
          filterField: selectedOption,
          sortField: field,
          sortDirection: -1,
        };
      } else {
        setSortDirection(1);
        message = {
          pageNo: 1,
          limitPerPage: entries,
          filterField: selectedOption,
          sortField: field,
          sortDirection: 1,
        };
      }
    } else {
      setSortDirection(1);
      message = {
        pageNo: 1,
        limitPerPage: entries,
        filterField: selectedOption,
        sortField: field,
        sortDirection: 1,
      };
    }
    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
    setSortField(field);
    setPopRow();
    setLoading(true);

    test_c(message);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    // perform search based on selected option and search query
    setLoading(true);
    console.log(
      'selectedOption: ',
      selectedOption,
      'selectedQuery: ',
      searchQuery
    );

    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
    setSortDirection(0);
    setSortField('');
    setPopRow();

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      filterField: selectedOption,
      searchText: searchQuery
    };

    test_c(message);
  };

  const handleFinalKeys = () => {
    let arr = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    setKeywords([...arr, keywords]);
    return arr;
  };

  const formatDate = (dateString) => {
    if ((dateString === undefined) | (dateString === null)) {
      return false;
    }
    const dateObject = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return dateObject.toLocaleString('en-US', options);
  };

  const handlePage = (event, value) => {
    setPage(value);
    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
    setPopRow();
    console.log(fromDate);
    const t = handleFinalKeys();

    const message = {
      pageNo: value,
      limitPerPage: entries,
      filterField: selectedOption,
      sortField: sortField,
      sortDirection: sortDirection,
    };

    test_c(message);
  };

  const handleRetry = async(job_id) => {
    let message = {"job_id": job_id}
    let res = await jobs_retry(message)
    toast.success("Retry Request Initiated")
    setSuccessBool(false);
    setFailureBool(false);
    setRetryBool(false);
    setRevokedBool(false);
    setStartedBool(false);
    setPendingBool(false);
    setPopRow();
    console.log(fromDate);
    const t = handleFinalKeys();

    message = {
      pageNo: page,
      limitPerPage: entries,
      filterField: selectedOption,
      sortField: sortField,
      sortDirection: sortDirection,
    };

    test_c(message);
  }

  const handleHistoryPop = (event, data) => {
    setHistoryPop(data)
    setHistoryBool(true)
  }

  const renderValue = (value) => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        // return value.join(', ');
        return value.length === 0 ? '--' : value.join(', ');
      } else {
        return Object.keys(value).length === 0 ? '--' : (
          <ul>
            {Object.entries(value).map(([key, nestedValue]) => (
              <li key={key}>
                <strong>{key}:</strong> {renderValue(nestedValue)}
              </li>
            ))}
          </ul>
        );
      }
    } else {
      if (value === true || value === false){
        return value === true ? "Yes": "No";
      }
      else{
        return (value === "") ? "--" : value;
      }
    }
  };

  const test_c = async (message) => {
    console.log(
      'here your message reached from your updated function: ',
      message
    );
    let response = {};
    response = await tasks(message);
    let chan = response.dbRes.data;
    // const { chan, pCount, tot } = response;

    console.log('here is the response you got for this time: ', response);
    setPc(Math.ceil(response.dbRes.totalCount / entries));
    console.log(
      'updated response.totalcount/entries, entries , updated pc: ',
      response.dbRes.totalCount,
      entries,
      pc
    );
    setTotCount(response.dbRes.totalCount);
    setDet(entries);

    console.log('chan object: ', chan);
    setSample_rows(chan);
    setLoading(false);
  };

  React.useEffect(() => {
    const message = { pageNo: 1, limitPerPage: 15 };
    //   buttonRef.current.click();
    test_c(message);
  }, []);

  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <div className='page_container_jobs'>
      <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
            style={{ width: '17%', fontWrap: 'none', textAlign: 'left' }}/>
      <div className='main_heading_container_jobs'>
        <div className='Head1_jobs'>Task Status</div>
        <div className='search_form_container' >
          <form className='search_form' onSubmit={handleSearch} style={{width:'100%'}}>
            <select
              className='form_dropdown'
              value={selectedOption}
              onChange={handleOptionChange}
              style={{padding:'10px',border:'1px solid ',width:'500px'}}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              className='form_input_bar'
              type='text'
              placeholder='Search...'
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              style={{border:'1px solid #B7B7B7',borderRadius:'5px',margin:'0 10px',paddingLeft:'5px'}}
            />
            <button className='formSearchBtn' type='submit' style={{backgroundColor:'#00ACFB'}}>
              Search
            </button>
          </form>
        </div>
      </div>

      <div className='job_body_jobs'>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '90vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ height: '10%' }} src={loadingImg} />
          </div>
        ) : (
          <div className='table_container_jobs'>
            {/* <TableContainer component={Paper} sx={{ height: 320  }}> FOR SCROLL THING*/}
            <TableContainer
              component={Paper}
              sx={{
                height: '74vh',
                boxShadow: '0px 20px 50px 0px #0000001A',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
              {/* <Table stickyHeader size="small" aria-label="a dense table"> */}
              <Table stickyHeader>
                {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}
                <TableHead>
                  <TableRow>
                  
                    <TableCell
                      className='hoverable-cell'
                      sx={{
                        color: '#313131',
                        fontWeight: 'bold',
                        // fontFamily: "'Fredoka', sans-serif",
                        fontSize: '2vh',
                        backgroundColor: '#F7F9FB',
                        color: '#898F9A',
                        textAlign: 'center',
                        width: '20%',
                      }}
                      // align='left'
                      onClick={(event) => {
                        handleSort(event, 'job_type');
                      }}
                    >
                     Job Type 
                      <img src={sortIcon} 
                     style={{  display: "initial", marginLeft: "2%"}}
                      />
                    </TableCell>
                    <TableCell
                      className='hoverable-cell'
                      sx={{
                        color: '#313131',
                        fontWeight: 'bold',
                        // fontFamily: "'Fredoka', sans-serif",
                        fontSize: '2vh',
                        backgroundColor: '#F7F9FB',
                        color: '#898F9A',
                        textAlign: 'center',
                        width: '20%',
                      }}
                      align='left'
                    >
                     Description
                      {/* <img src={sortIcon} style={{  display: "initial", marginLeft: "2%"}}/> */}
                    </TableCell>
                    <TableCell
                      className='hoverable-cell'
                      sx={{
                        color: '#313131',
                        fontWeight: 'bold',
                        // fontFamily: "'Fredoka', sans-serif",
                        fontSize: '2vh',
                        backgroundColor: '#F7F9FB',
                        color: '#898F9A',
                        textAlign: 'center',
                        width: '20%',
                      }}
                      align='left'
                      onClick={(event) => {
                        handleSort(event, 'state');
                      }}
                    >
                      Status
                      <img src={sortIcon} style={{  display: "initial", marginLeft: "2%"}}/>
                    </TableCell>

                    <TableCell
                      className='hoverable-cell'
                      sx={{
                        color: '#313131',
                        fontWeight: 'bold',
                        // fontFamily: "'Fredoka', sans-serif",
                        fontSize: '2vh',
                        backgroundColor: '#F7F9FB',
                        color: '#898F9A',
                        textAlign: 'center',
                        width: '20%',
                      }}
                      align='left'
                      onClick={(event) => {
                        handleSort(event, 'created_at');
                      }}
                    >
                      Created At
                      <img src={sortIcon} style={{  display: "initial", marginLeft: "2%"}}/>
                    </TableCell>
                    <TableCell
                      className='hoverable-cell'
                      sx={{
                        color: '#313131',
                        fontWeight: 'bold',
                        // fontFamily: "'Fredoka', sans-serif",
                        fontSize: '2vh',
                        backgroundColor: '#F7F9FB',
                        color: '#898F9A',
                        textAlign: 'center',
                        width: '20%',
                      }}
                      align='left'
                      onClick={(event) => {
                        handleSort(event, 'start_time');
                      }}
                    >
                      Started At
                      <img src={sortIcon} style={{  display: "initial", marginLeft: "2%"}}/>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: '#313131',
                        fontWeight: 'bold',
                        // fontFamily: "'Fredoka', sans-serif",
                        fontSize: '2vh',
                        backgroundColor: '#F7F9FB',
                        color: '#898F9A',
                        textAlign: 'center',
                        width: '20%',
                      }}
                      align='left'
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ height: 'max-content' }}>
                  {sample_rows.map((row, index) => (
                    <TableRow
                      // key={row.name}
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        background: index % 2 ? '#F5F7FB' : 'FFFFFF',
                      }}
                    >
                      <TableCell
                        align='center'
                        style={{ fontFamily: "'Open Sans','sans-serif'" }}
                      >
                        {/* {row.job_type === 'LINK_DOWNLOAD'?"Download through link": row.job_type === 'ALARM_TRIGGER'? "Alarm trigger": row.job_type === 'FILE_UPLOAD_S3'? "Download file in database": row.job_type === 'TESTING'? "Testing": row.job_type === 'QUERY_MESSAGES_EXPORT'? "Export Messages": row.job_type === 'QUERY_CHANNELS_EXPORT'?"Export Channels": row.job_type === 'JOIN_CHANNEL_AND_EXPORT_REQUEST'? "Join Channel and Import messages": row.job_type === 'JOIN_CHANNEL_REQUEST'? "Join Channel": row.job_type === 'UPDATE_MESSAGES_TAGS'? "Update Message tags":''} */}
                        {row.job_type}
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ fontFamily: "'Open Sans','sans-serif'" ,
                        color: row.job_type === 'FILE_UPLOAD_S3' || row.attributes?.notes ? '#00acfb' : 'black',
                        fontWeight: row.job_type === 'FILE_UPLOAD_S3' || row.attributes?.notes ? 'bold' : 'normal'}}
                      >
                        {/* {row.job_type === 'LINK_DOWNLOAD'?"Download through link": row.job_type === 'ALARM_TRIGGER'? "Alarm trigger": row.job_type === 'FILE_UPLOAD_S3'? "Download file in database": row.job_type === 'TESTING'? "Testing": row.job_type === 'QUERY_MESSAGES_EXPORT'? "Export Messages": row.job_type === 'QUERY_CHANNELS_EXPORT'?"Export Channels": row.job_type === 'JOIN_CHANNEL_AND_EXPORT_REQUEST'? "Join Channel and Import messages": row.job_type === 'JOIN_CHANNEL_REQUEST'? "Join Channel": row.job_type === 'UPDATE_MESSAGES_TAGS'? "Update Message tags":''} */}
                        {row.job_type === 'FILE_UPLOAD_S3'
                            ? <>{row.attributes.download_media_bucket_id}</>
                            : row.attributes?.notes ? <>{row.attributes?.notes}</> : <>Specific Description Not Provided</>}
                        
                      </TableCell>
                      <TableCell
                        align='center'
                        style={
                          row.state === 'SUCCESS'
                            ? {
                                fontFamily: "'Fredoka', 'sans-serif'",
                                fontWeight: 'bold',
                                color: '#2CE38B',
                              }
                            : row.state === 'FAILURE'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#FF5858',
                              }
                            : row.state === 'RETRYING'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#919EAB',
                              }
                            : row.state === 'REVOKED'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#FF5858',
                              }
                            : row.state === 'STARTED'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#00ACFB',
                              }
                            : {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#FF9458',
                              }
                        }
                      >
                        {row.state}
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ fontFamily: "'Open Sans','sans-serif'" }}
                      >
                        {row?.created_at?.$date
                          ? formatDate(row.created_at.$date)
                          : '-'}
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ fontFamily: "'Open Sans','sans-serif'" }}
                        title={
                          row.end_time
                            ? `End Time: ${
                                row.end_time?.$date
                                  ? formatDate(row.end_time.$date)
                                  : '-'
                              }`
                            : ''
                        }
                      >
                        {row?.start_time?.$date
                          ? formatDate(row.start_time.$date): '-'}
                      </TableCell>
                      <TableCell align='center'>
                        {/* {row.state=== 'SUCCESS'? "View":row.state === 'FAILURE'? "View": row.state === 'RETRYING'? "View": row.state==='REVOKED'? "View": row.state === 'STARTED'? "View": "View"} */}
                        <div style={{display: "flex", gap: "0.5vw", paddingLeft: "42%"}}>
                          <button
                            className='jobActionBtn'
                            style={
                              row.state === null
                                ? { color: '#919EAB', borderColor: '#919EAB' }
                                : {}
                            }
                            disabled={
                              row.state === null
                            }
                            onClick={(event) =>
                              row.state === 'SUCCESS'
                                ? successPop(event, row)
                                : row.state === 'FAILURE'
                                ? failurePop(event, row)
                                : row.state === 'RETRYING'
                                ? retryPop(event, row)
                                : row.state === 'REVOKED'
                                ? revokedPop(event, row)
                                : row.state === 'STARTED'
                                ? startedPop(event, row)
                                : pendingPop(event, row)
                            }
                          >
                            View
                          

                          </button>
                          {row.history && row.history.length !== 0? <img src={Edited} alt='history' style={{cursor: "pointer"}} onClick={(event) => handleHistoryPop(event, row.history)}/> : ""}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='pagination_container_jobs'>
              <div className='nav_container'>
                <Stack spacing={2}>
                  <Pagination
                    count={pc}
                    color='primary'
                    shape='rounded'
                    value={page}
                    onChange={handlePage}
                    variant='outlined'
                    // sx={{ fontFamily: "'Fredoka', sans-serif" }}
                  />
                </Stack>
              </div>
              <div className='entries'>
                No. of Entries:
                <input
                  className='numberBox'
                  type='number'
                  defaultValue={entries}
                  onChange={(e) => setEntries(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      const t = handleFinalKeys();
                      setPage(1);
                      const message = {
                        pageNo: 1,
                        limitPerPage: e.target.value,
                      };
                      setLoading(true);
                      test_c(message);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {
        historyBool && (
          <div
          className='backdropModal'
          onClick={() => {
            setHistoryBool(false);
          }}
        >
          <div className='' onClick={(e) => e.stopPropagation()} style={{backgroundColor:'white',padding:'1%',borderRadius:'10px',width:'30%'}}>
            <div className=''>
              <div className='' style={{fontSize:'20px',width:'100%',textAlign:'center',marginBottom:"15px"}} >
                {"Job History"}
                <div className="history Content" style={{textAlign: "left"}}>
                  {Object.entries(historyPop).map(([key, value]) => (
                      <>
                  { console.log(value)}

                        <div style={{fontWeight: "bold"}}>History {(parseInt(key)+1).toString()}.</div>
                        <div key={key} style={{ marginBottom: '2%', fontFamily: "Open Sans", paddingLeft: "3%"}}>
                          <div > <span style={{fontWeight : "600", marginRight : "5px"}}>status: </span> {value["state"]}</div>
                          <div > <span style={{fontWeight : "600", marginRight : "5px"}}>start_time: </span>{value["start_time"]?formatDate(value["start_time"]["$date"]): "-"}</div>
                          <div > <span style={{fontWeight : "600", marginRight : "5px"}}>end time: </span>{value["end_time"]? formatDate(value["end_time"]["$date"]) :"-"}</div>
                        </div>
                        {/* <br/> */}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }
      {successBool === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setSuccessBool(false);
          }}
        >
          <div className='' onClick={(e) => e.stopPropagation()} style={{backgroundColor:'white',padding:'1%',borderRadius:'10px',width:'30%'}}>
            <div className=''>
              <div className='' style={{fontSize:'24px',fontWeight:700,width:'100%',textAlign:'center',marginBottom:"15px"}} >
                { 
                  popRow.job_type == "QUERY_CHANNELS_EXPORT" ? "Exported Channels": 
                  popRow.job_type == "QUERY_MESSAGES_EXPORT" ? "Exported Messages":
                  popRow.job_type == "QUERY_MESSAGES_EXPORT_WITH_MEDIA" ? "Exported Messages":
                  popRow.job_type == "JOIN_CHANNEL_REQUEST" ? "Joined Channel":
                  popRow.job_type == "JOIN_CHANNEL_AND_EXPORT_REQUEST" ? "Joined Channel and Exported Messages":
                  popRow.job_type == "FILE_UPLOAD_S3" ? "Downloaded Media":
                  popRow.job_type == "UPDATE_MESSAGES_TAGS" ? "Updated Message Tags":
                  popRow.job_type == "UPDATE_CHANNELS_TAGS" ? "Updated Channels Tags":
                  popRow.job_type == "FORCE_EXPORT_MESSAGES" ? "Exported Messages":
                  popRow.job_type == "EXPORT_KEYWORD_DISCOVER_RULE" ? "Exported Discover Rule":
                  popRow.job_type == "EXPORT_MESSAGE_MONITOR_RULE" ? "Exported Monitor Rule":
                  popRow.job_type == "EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION" ? "Exported Keyword Discover Notification":
                  popRow.job_type == "EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION" ? "Exported Monitor Messages Notification":
                  popRow.attributes.success_message
                }
              </div>
             
            </div>
            <div
              style={{
                display: '',
                alignItems: 'center',
                gap: '2%',
                marginBottom: '2%',
              }}
            >
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <div>
                  <span className=' text-black' style={{fontWeight:600,marginRight:'63px'}}>{`Job ID${' '}`}</span>
                  <span style={{marginRight:'10px'}}>:</span>
                  <span>{popRow._id.$oid}</span>
                  </div>
                  <div>
                  <span className=' text-black' style={{fontWeight:600,marginRight:'10px'}}>{`Status${' '}:`}</span>
                  <span style={
                          popRow.state === 'SUCCESS'
                            ? {
                                fontFamily: "'Fredoka', 'sans-serif'",
                                fontWeight: 'bold',
                                color: '#2CE38B',
                              }
                            : popRow.state === 'FAILURE'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#FF5858',
                              }
                            : popRow.state === 'RETRYING'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#919EAB',
                              }
                            : popRow.state === 'REVOKED'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#FF5858',
                              }
                            : popRow.state === 'STARTED'
                            ? {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#00ACFB',
                              }
                            : {
                                fontFamily: 'Fredoka, sans-serif',
                                fontWeight: 'bold',
                                color: '#FF9458',
                              }
                        }>{popRow.state}</span>
                  </div>
                </div>
              <div style={{ display:'flex',
                  alignItems:'center',marginTop:'10px'}}>
               <div
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: 'bold',
                  marginRight:'10px',
                  
                }}
              >
                Time Elapsed
              </div>
              <span style={{marginRight:'10px'}}>:</span>
              <div className=''>
                {getTimeElapsed(
                  popRow.created_at?.$date,
                  popRow.end_time ? popRow.end_time.$date : new Date()
                )> 0?'Less Than a Minute':getTimeElapsed(
                  popRow.created_at?.$date,
                  popRow.end_time ? popRow.end_time.$date : new Date()
                )}
              </div>
        </div>
                  
            
            
            </div>
            {
              <div className='attributes se'>
                  <div className='attributes_children'>
                  {Object.entries(popRow.attributes).map(([key, value]) => (
                    <>
                      <div key={key} style={{ marginBottom: '2%', fontFamily: "Open Sans"}}>
                        <strong style={{fontWeight : "600", marginRight : "5px"}}>{key}</strong>: {renderValue(value)}
                      </div>
                      {/* <br/> */}
                    </>
                  ))}
                  </div>
              </div>
            }

            {popRow.job_type === 'FILE_UPLOAD_S3' ? (
              <div className='jobs_file_container_body'>
                <div
                  className='jobs_media_file_name_container'
                  // style={{ minWidth: 'max-content' }}
                >
                  {' '}
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 'bold',
                      color: '#919EAB',
                    }}
                  >
                    {' '}
                    File name :
                  </div>{' '}
                  <div className='job_file_name'>
                    {popRow.attributes.download_media_bucket_id}{' '}
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin:'10px 0px'
                  }}
                >
                  <button
                     style={{
                      width: '25%',
                      borderRadius: '10px',
                      fontSize: '16px',
                    }}
                   
                    onClick={(event) => {
                      event.preventDefault();
                      handleClick(popRow.attributes.download_media_bucket_id);
                    }}
                  >
                    Download
                  </button>
                   
              <button
                style={{
                  width: '20%',
                  borderRadius: '10px',
                  fontSize: '16px',
                  backgroundColor: 'white',
                  color: '#00ACFB',
                  border: '2px solid #00ACFB',
                  marginLeft: '10px',
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setSuccessBool(false);
                  setPopRow();
                }}
              >
                cancel
              </button>
                </div>
              </div>
            ) : (
              <></>
            )}
       
         
{popRow.job_type === 'EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION'||popRow.job_type === 'EXPORT_KEYWORD_DISCOVER_RULE'|| popRow.job_type === 'EXPORT_MESSAGE_MONITOR_RULE'? (
              <div className='jobs_file_container_body'>
                <div
                  className='jobs_media_file_name_container'
                  // style={{ minWidth: 'max-content' }}
                >
                  {' '}
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 'bold',
                      color: '#919EAB',
                    }}
                  >
                    {' '}
                    File name :
                  </div>{' '}
                  <div className='job_file_name'>
                    {popRow.attributes.query_export_channels_bucked_id}{' '}
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    style={{ backgroundColor: '#2CE38B' }}
                    className='job_media_downloadBtn'
                    onClick={(event) => {
                      event.preventDefault();
                      handleClick(popRow.attributes.query_export_channels_bucked_id
                        );
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
           

            {popRow.job_type === 'EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION' ? (
              <div className='jobs_file_container_body'>
                <div
                  className='jobs_media_file_name_container'
                  // style={{ minWidth: 'max-content' }}
                >
                  {' '}
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 'bold',
                      color: '#919EAB',
                    }}
                  >
                    {' '}
                    File name :
                  </div>{' '}
                  <div className='job_file_name'>
                    {popRow.attributes.query_export_channels_bucked_id
}{' '}
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    style={{ backgroundColor: '#2CE38B' }}
                    className='job_media_downloadBtn'
                    onClick={(event) => {
                      event.preventDefault();
                      handleClick(popRow.attributes.query_export_channels_bucked_id
                        );
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
           
           {popRow.job_type === 'QUERY_CHANNELS_EXPORT'|| popRow.job_type=='QUERY_MESSAGES_EXPORT'|| popRow.job_type == "QUERY_MESSAGES_EXPORT_WITH_MEDIA" ? (
            
            <div className='jobs_file_container_body'>
            <div
              className='jobs_media_file_name_container'
              // style={{ minWidth: 'max-content' }}
            >
              {' '}
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: 'bold',
                  color: '#919EAB',
                }}
              >
                {' '}
                File name :
              </div>{' '}
              <div className='job_file_name'>
                {popRow.attributes.query_export_channels_bucked_id}{' '}
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                style={{ backgroundColor: '#2CE38B' }}
                className='job_media_downloadBtn'
                onClick={(event) => {
                  event.preventDefault();
                  handleClick(
                    popRow.job_type === 'QUERY_CHANNELS_EXPORT' ? popRow.attributes.query_export_channels_bucked_id : popRow.attributes.query_export_messages_bucked_id
                    );
                }}
              >
                Download
              </button>
            </div>
          </div>
            ) : (
              <></>
            )}
          

            {popRow.job_type === 'JOIN_CHANNEL_AND_EXPORT_REQUEST' ? (
              <div className='jobs_file_container_body'>
                <div
                  style={{
                    width: 'max-content',
                    display: 'flex',
                    gap: '2%',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 'bold',
                      color: '#919EAB',
                    }}
                  >
                    {' '}
                    Channel:
                  </div>{' '}
                  <div
                    style={{
                      backgroundColor: '#EBF9FF',
                      fontFamily: 'Open Sans',
                      paddingInline: '4%',
                    }}
                  >
                    {popRow.attributes.channel_query_id}{' '}
                  </div>
                </div>
                {/* <div style={{width:'100%', display:'flex',justifyContent:'center'}}><button className='job_media_downloadBtn' onClick={(event) => {event.preventDefault(); handleClick(popRow.attributes.download_media_bucket_id)}} >Download</button></div> */}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '4%',
                  }}
                >
                  <Link
                    style={{ fontFamily: 'Roboto' }}
                    target='_blank'
                    className='job_link_internal_page'
                    to={`/channels?channel_Id=${popRow.attributes.channel_query_id}`}
                  >
                    View Channel
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}

            {popRow.job_type === 'JOIN_CHANNEL_REQUEST' ? (
              <div className='jobs_file_container_body'>
                <div
                  style={{
                    width: 'max-content',
                    display: 'flex',
                    gap: '2%',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: 'bold',
                      color: '#919EAB',
                    }}
                  >
                    {' '}
                    Channel:
                  </div>{' '}
                  <div
                    style={{
                      backgroundColor: '#EBF9FF',
                      fontFamily: 'Open Sans',
                      paddingInline: '4%',
                    }}
                  >
                    {popRow.attributes.channel_query_id}{' '}
                  </div>
                </div>
                {/* <div style={{width:'100%', display:'flex',justifyContent:'center'}}><button className='job_media_downloadBtn' onClick={(event) => {event.preventDefault(); handleClick(popRow.attributes.download_media_bucket_id)}} >Download</button></div> */}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '4%',
                  }}
                >
                  <Link
                    style={{ fontFamily: 'Roboto' }}
                    target='_blank'
                    className='job_link_internal_page'
                    to={`/channels?channel_Id=${popRow.attributes.channel_query_id}`}
                  >
                    View Channel
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {failureBool === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setFailureBool(false);
          }}
        >
          <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            <div className='jobsPopHeader'>
              <div className='jobsPopHeaderMessage'>
              { 
                  popRow.job_type == "QUERY_CHANNELS_EXPORT" ? "Exporting Channels Failure": 
                  popRow.job_type == "QUERY_MESSAGES_EXPORT" ? "Exporting Messages Failure":
                  popRow.job_type == "QUERY_MESSAGES_EXPORT_WITH_MEDIA" ? "Exporting Messages Failure":
                  popRow.job_type == "JOIN_CHANNEL_REQUEST" ? "Joining Channel Failure":
                  popRow.job_type == "JOIN_CHANNEL_AND_EXPORT_REQUEST" ? "Joining Channel and Exporting Messages Failure":
                  popRow.job_type == "FILE_UPLOAD_S3" ? "Downloading Media Failure":
                  popRow.job_type == "UPDATE_MESSAGES_TAGS" ? "Updating Message Tags Failure":
                  popRow.job_type == "UPDATE_CHANNELS_TAGS" ? "Updating Channels Tags Failure":
                  popRow.job_type == "FORCE_EXPORT_MESSAGES" ? "Exporting Messages Failure":
                  popRow.job_type == "EXPORT_KEYWORD_DISCOVER_RULE" ? "Exporting Discover Rule Failure":
                  popRow.job_type == "EXPORT_MESSAGE_MONITOR_RULE" ? "Exporting Monitor Rule Failure":
                  popRow.job_type == "EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION" ? "Exporting Keyword Discover Notification Failure":
                  popRow.job_type == "EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION" ? "Exporting Monitor Messages Notification Failure":
                  popRow.attributes.failure_error
                }
                {/* {popRow.attributes
                  ? popRow.attributes.failure_error
                  : 'Failure'} */}
              </div>
              {/* <div className='job_detail_time_elapsed'>{popRow.attributes.end_time - popRow.attributes.start_time}</div> */}
              <button
                className='jobsHeaderCloseBtn'
                onClick={(event) => {
                  event.preventDefault();
                  setFailureBool(false);
                  setPopRow();
                }}
              >
                x
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2%',
                marginBottom: '2%',
              }}
            >
              <div
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: 'bold',
                  color: '#919EAB',
                }}
              >
                Time Elapsed:
              </div>
              <div className='job_detail_time_elapsed'>
                {getTimeElapsed(
                  popRow.created_at?.$date,
                  popRow.end_time ? popRow.end_time.$date : new Date()
                )}
              </div>
            </div>
            {
              <div className='attributes se'>
                  <div className='attributes_children'>
                  {Object.entries(popRow.attributes).map(([key, value]) => (
                    <>
                      <div key={key} style={{ marginBottom: '2%', fontFamily: "Open Sans"}}>
                        <strong style={{fontWeight : "600", marginRight : "5px"}}>{key}</strong>: {renderValue(value)}
                      </div>
                      {/* <br/> */}
                    </>
                  ))}
                  </div>
              </div>
            }
            
            <div className='jobs_file_container_body'>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  className='job_media_downloadBtn'
                  style={{ backgroundColor: '#FF5858' }}
                  onClick={(event) => {
                    event.preventDefault();
                    // console.log(popRow)
                    handleRetry(popRow["_id"]["$oid"]);
                  }}
                >
                  Retry
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {retryBool === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setRetryBool(false);
          }}
        >
          <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            {/* <div style={{textAlign: "center"}}>PENGING</div> */}
            { 
                  popRow.job_type == "QUERY_CHANNELS_EXPORT" ? "Exporting Channels Retrying": 
                  popRow.job_type == "QUERY_MESSAGES_EXPORT" ? "Exporting Messages Retrying":
                  popRow.job_type == "QUERY_MESSAGES_EXPORT_WITH_MEDIA" ? "Exporting Messages Retrying":
                  popRow.job_type == "JOIN_CHANNEL_REQUEST" ? "Joining Channel Retrying":
                  popRow.job_type == "JOIN_CHANNEL_AND_EXPORT_REQUEST" ? "Joining Channel and Exporting Messages Retrying":
                  popRow.job_type == "FILE_UPLOAD_S3" ? "Downloading Media Retrying":
                  popRow.job_type == "UPDATE_MESSAGES_TAGS" ? "Updating Message Tags Retrying":
                  popRow.job_type == "UPDATE_CHANNELS_TAGS" ? "Updating Channels Tags Retrying":
                  popRow.job_type == "FORCE_EXPORT_MESSAGES" ? "Exporting Messages Retrying":
                  popRow.job_type == "EXPORT_KEYWORD_DISCOVER_RULE" ? "Exporting Discover Rule Retrying":
                  popRow.job_type == "EXPORT_MESSAGE_MONITOR_RULE" ? "Exporting Monitor Rule Retrying":
                  popRow.job_type == "EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION" ? "Exporting Keyword Discover Notification Retrying":
                  popRow.job_type == "EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION" ? "Exporting Monitor Messages Notification Retrying":
                  "RETRYING"
                }
            {
              <div className='attributes se'>
                  <div className='attributes_children'>
                  {Object.entries(popRow.attributes).map(([key, value]) => (
                    <>
                      <div key={key} style={{ marginBottom: '2%', fontFamily: "Open Sans"}}>
                        <strong style={{fontWeight : "600", marginRight : "5px"}}>{key}</strong>: {renderValue(value)}
                      </div>
                      {/* <br/> */}
                    </>
                  ))}
                  </div>
              </div>
            }
          </div>
          {/* <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            retry
          </div> */}
        </div>
      ) : (
        <></>
      )}
      {pendingBool === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setPendingBool(false);
          }}
        >
          <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            {/* <div style={{textAlign: "center"}}>PENGING</div> */}
            { 
                  popRow.job_type == "QUERY_CHANNELS_EXPORT" ? "Exporting Channels Pending": 
                  popRow.job_type == "QUERY_CHANNELS_EXPORT" ? "Exporting Channels Pending": 
                  popRow.job_type == "QUERY_MESSAGES_EXPORT_WITH_MEDIA" ? "Exporting Messages Pending":
                  popRow.job_type == "JOIN_CHANNEL_REQUEST" ? "Joining Channel Pending":
                  popRow.job_type == "JOIN_CHANNEL_AND_EXPORT_REQUEST" ? "Joining Channel and Exporting Messages Pending":
                  popRow.job_type == "FILE_UPLOAD_S3" ? "Downloading Media Pending":
                  popRow.job_type == "UPDATE_MESSAGES_TAGS" ? "Updating Message Tags Pending":
                  popRow.job_type == "UPDATE_CHANNELS_TAGS" ? "Updating Channels Tags Pending":
                  popRow.job_type == "FORCE_EXPORT_MESSAGES" ? "Exporting Messages Pending":
                  popRow.job_type == "EXPORT_KEYWORD_DISCOVER_RULE" ? "Exporting Discover Rule Pending":
                  popRow.job_type == "EXPORT_MESSAGE_MONITOR_RULE" ? "Exporting Monitor Rule Pending":
                  popRow.job_type == "EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION" ? "Exporting Keyword Discover Notification Pending":
                  popRow.job_type == "EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION" ? "Exporting Monitor Messages Notification Pending":
                  "PENDING"
                }
            {
              <div className='attributes se'>
                  <div className='attributes_children'>
                  {Object.entries(popRow.attributes).map(([key, value]) => (
                    <>
                      <div key={key} style={{ marginBottom: '2%', fontFamily: "Open Sans"}}>
                        <strong style={{fontWeight : "600", marginRight : "5px"}}>{key}</strong>: {renderValue(value)}
                      </div>
                      {/* <br/> */}
                    </>
                  ))}
                  </div>
              </div>
            }
          </div>
        </div>
      ) : (
        <></>
      )}
      {startedBool === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setStartedBool(false);
          }}
        >
          <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            {/* <div style={{textAlign: "center"}}>PENGING</div> */}
            { 
                  popRow.job_type == "QUERY_CHANNELS_EXPORT" ? "Exporting Channels Started": 
                  popRow.job_type == "QUERY_MESSAGES_EXPORT" ? "Exporting Messages Started":
                  popRow.job_type == "QUERY_MESSAGES_EXPORT_WITH_MEDIA" ? "Exporting Messages Started":
                  popRow.job_type == "JOIN_CHANNEL_REQUEST" ? "Joining Channel Started":
                  popRow.job_type == "JOIN_CHANNEL_AND_EXPORT_REQUEST" ? "Joining Channel and Exporting Messages Started":
                  popRow.job_type == "FILE_UPLOAD_S3" ? "Downloading Media Started":
                  popRow.job_type == "UPDATE_MESSAGES_TAGS" ? "Updating Message Tags Started":
                  popRow.job_type == "UPDATE_CHANNELS_TAGS" ? "Updating Channels Tags Started":
                  popRow.job_type == "FORCE_EXPORT_MESSAGES" ? "Exporting Messages Started":
                  popRow.job_type == "EXPORT_KEYWORD_DISCOVER_RULE" ? "Exporting Discover Rule Started":
                  popRow.job_type == "EXPORT_MESSAGE_MONITOR_RULE" ? "Exporting Monitor Rule Started":
                  popRow.job_type == "EXPORT_SPECIFIC_KEYWORD_DISCOVER_NOTIFICATION" ? "Exporting Keyword Discover Notification Started":
                  popRow.job_type == "EXPORT_SPECIFIC_MESSAGE_MONITOR_NOTIFICATION" ? "Exporting Monitor Messages Notification Started":
                  "STARTED"
                }
            {
              <div className='attributes se'>
                  <div className='attributes_children'>
                  {Object.entries(popRow.attributes).map(([key, value]) => (
                    <>
                      <div key={key} style={{ marginBottom: '2%', fontFamily: "Open Sans"}}>
                        <strong style={{fontWeight : "600", marginRight : "5px"}}>{key}</strong>: {renderValue(value)}
                      </div>
                      {/* <br/> */}
                    </>
                  ))}
                  </div>
              </div>
            }
          </div>
          {/* <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            started
          </div> */}
        </div>
      ) : (
        <></>
      )}
      {revokedBool === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setRevokedBool(false);
          }}
        >
          <div className='JobDetailsPopup' onClick={(e) => e.stopPropagation()}>
            revoked
          </div>
        </div>
      ) : (
        <></>
      )}
      
    </div>
  );
};

import instance from './interceptor';


export const slack_details = async (data) => {
  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
  let res = [];
  console.log(data);
  console.log('hitting endpoint tag update');
  await instance.post(`${apiUrl}/api/slack_details`, data, config)
    .then(response => {
      console.log('hit');
      res = response.data;
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  console.log(res);
  return res;
};

import * as React from 'react';
import Table from '@mui/material/Table';
import emptyData from '../images/empty.jpg';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import teleIcon from '../images/teleIcon.svg';
import { test_call } from '../endpoints/channel_discovery';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { rule } from '../endpoints/query_keywords_rule';
import { edit_rule } from '../endpoints/update_query_keywords_rule';
import trash from '../images/trash.svg';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { notifications } from '../endpoints/channel_discovery_notifications';
import { view_channels } from '../endpoints/channel_discovery_view_channels';
import { view_rule_channels } from '../endpoints/channel_discovery_rule_channels';
import { join_channel } from '../endpoints/channel_join';
import { live_search } from '../endpoints/live_search';
import loadingImg from '../images/loading.svg';
import slack from '../images/slack.svg';
import error_img from '../images/404-error.png';
import grey_slack from '../images/grey-slack.svg';
import refresh_btn from '../images/refresh.svg';
import noData from '../images/no-search-found.svg';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { keyword_drop_down } from '../endpoints/keyword_name_dropdown';
import ExportChannel from '../images/ExportChannel.svg';
import ViewEye from '../images/ViewEye.svg';
import Export from '../images/Export.svg';
import Filter from '../images/Filter.svg';
import ActiveDot from '../images/activedot.svg';
import { export_specific_discover_notification } from '../endpoints/export_specific_discover_notification';
import { export_discover_rule } from '../endpoints/export_discover_rule';
import { ToastContainer, toast } from 'react-toastify';
import { mark_key_notification_read } from '../endpoints/mark_key_notification_read';
import { size } from 'underscore';
import { export_specific_notification } from '../endpoints/export_specific_notification';
import { run_indiviual_rule } from '../endpoints/run_individual_keyword_discover_rule';
const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return dateObject.toLocaleString('en-US', options);
};

const style = {
  width: 750,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  paddingInline: 8,
  paddingTop: 4,
  paddingBottom: 4,
  zIndex: 4,
};

export const Discovery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchedMention = searchParams.get('mention');
  const [keywords, setKeywords] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [sample_rows, setSample_rows] = React.useState([]);
  const [pc, setPc] = React.useState(1);
  const [det, setDet] = React.useState(10);
  const [totCount, setTotCount] = React.useState(4000);
  //    const buttonRef = React.useRef(null);
  const [check, setCheck] = React.useState([]);
  const [fin_tags, setFin_tags] = React.useState([]);
  const [entries, setEntries] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openbox, setOpenbox] = React.useState(false);
  //change it later according to backend initially all the value of unjoined channels should be false then the index of all the joined channels or id would be sent so that those can be joined
  const [join, setJoin] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [exportPopUp,setExportPopUp] =React.useState(false)
  const [ruleId,setRuleId] =React.useState(false)
  const [filterPopUp,setFilterPopUp] = React.useState(false)
  const[notes,setNotes]=React.useState('')
  const [discoveredDate,setDiscoveredDate]=React.useState()
  const [viewMonitorRule, setViewMonitorRule] = React.useState(true);
  const [category, setCategory] = React.useState();
  const [update, setUpdate] = React.useState(false);
  const [channels, setChannels] = React.useState();
  var all_dets = [];
  const [showDetails, setShowDetails] = React.useState(false);
  const [delete_pop, setDelete_pop] = React.useState(false);
  const [to_delete_rule, setTo_delete_row] = React.useState();
  const [showSearchResults, setShowsearchResults] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [selectedJoinChannel, setSelectedJoinChannel] = React.useState();
  const [importPop, setImportPop] = React.useState(false);
  const [pcCh, setPcCh] = React.useState(1);
  const [pcR, setPcR] = React.useState(1);
  const [pageR, setPageR] = React.useState(1);
  const [entriesR, setEntriesR] = React.useState(10);
  const [totCountR, setTotCountR] = React.useState(4000);
  const [detR, setDetR] = React.useState(10);
  const [showSlackPop, setShowSlackPop] = React.useState(false);
  const [pageCh, setPageCh] = React.useState(1);
  const [entriesCh, setEntriesCh] = React.useState(10);
  const [totCountCh, setTotCountCh] = React.useState(4000);
  const [detCh, setDetCh] = React.useState(10);
  const [tableSelectedId, setTableSelectedId] = React.useState();
  const [stateTester, setStateTester] = React.useState(false);
  const [lastSearched, setLastSearched] = React.useState();
  const [searchError, setSearchError] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  let channel_name_dropdown = [];
  const [loading, setLoading] = React.useState(true);
  const [exportNotification,setExportNotification]= React.useState(false)
  const [notificationId,setExportNotificationId]=React.useState()
  const [filters, setFilters] = React.useState({enabled: false, disabled: false, allFilters: false})
  const [finalFilters, setFinalFilters] = React.useState({enabled: false, disabled: false, allFilters: false})
  const [showRuleName, setShowRuleName] = React.useState("")

  const handleDropdown = async (value) => {
    let response = [];
    let rule = {
      keyword: value,
    };
    response = await keyword_drop_down(rule);
    setTags(response.dbRes);
    channel_name_dropdown = response.dbRes;
    console.log(tags);
  };

  const handleInputChange = (inputValue) => {
    // Remove consecutive whitespace characters within the input value
    inputValue = inputValue.replace(/\s+/g, ' ');

    // Check if the input value is an exact match with an existing tag value

    // Add the new option to the tags array if it is not an empty string or a duplicate
    if (tags) {
      // const isDuplicate = tags.some((tag) => tag.value.toLowerCase() === inputValue.toLowerCase());
      if (inputValue.trim() !== '') {
        const newTag = { value: inputValue.trim(), label: inputValue.trim() };
        handleDropdown(newTag.value);
      }
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    console.log(selectedOptions);

    let arr = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      arr.push(selectedOptions[i].value);
    }
  };

  const value = {
    name: 'Drug',
    category: 'wouldnt tell',
    keys: ['hi', 'there'],
    description: 'hello my nme is no name',
    id: '331',
  };

  //function to search live channels from telegram
  const handleSearchClick = async () => {
    if (!searchChannel || searchChannel === '') {
      setShowError(true);
    } else {
      let message = {
        search_word: searchChannel,
      };

      setLastSearched(searchChannel);
      let response = [];
      response = await live_search(message);

      if (response.processed === false) {
        console.log('error');
        setSearchError(true);
      } else {
        setSearchError(false);

        let chan = response.channel_id_results;
        setChannels(chan);
        all_dets = chan;
        console.log('channels state: ', channels);
        console.log('al_dets variable: ', all_dets);
        setShowsearchResults(true);
      }
    }
  };

  const handleSearchMentionClick = async (word) => {
    let message = {
      search_word: word,
    };

    setLastSearched(word);
    let response = [];
    response = await live_search(message);
    if (response.processed === false) {
      console.log('error');
      setSearchError(true);
    } else {
      console.log('lets check the error', response);
      let chan = response.channel_id_results;
      setChannels(chan);
      all_dets = chan;
      console.log('channels state: ', channels);
      console.log('al_dets variable: ', all_dets);
      setShowsearchResults(true);
    }
  };

  //function to refresh the live search data to enquire about the job status
  const handleSearchRefresh = async () => {
    if (!searchChannel || searchChannel === '') {
      setShowError(true);
    } else {
      let message = {
        search_word: lastSearched,
      };

      let response = [];
      response = await live_search(message);

      if (response.processed === false) {
        console.log('error');
        setSearchError(true);
      } else {
        setSearchError(false);

        let chan = response.channel_id_results;
        setChannels(chan);
        all_dets = chan;
        console.log('channels state: ', channels);
        console.log('al_dets variable: ', all_dets);
        setShowsearchResults(true);
      }
    }
  };

  const [tab, setTab] = React.useState(0);
  const [key, setKey] = React.useState();
  const [selectedKeys, setSelectedKeys] = React.useState();
  const [selectCount, setSelectCount] = React.useState(0);
  const [name, setName] = React.useState();
  const [description, setDescription] = React.useState();
  const [toggleAddKeys, setToggleAddKeys] = React.useState(false);
  const [toggleNotification, selectToggleNotification] = React.useState(false);
  const [id, setId] = React.useState();
  const [searchChannel, setSearchChannel] = React.useState();
  const [selectedNotificationId, setSelectedNotificationId] = React.useState();
  const [showJoinPop, setShowJoinPop] = React.useState(false);
  const [selectedJoinChannelIndex, setSelectedJoinChannelIndex] =
    React.useState();
  const [viewFormConfirmation, setViewFormConfirmation] = React.useState(false);

  const handleSearchChannelAdd = (event) => {
    setSearchChannel(event.target.value);
    console.log(event.target.value);
  };

  const [notify, setNotify] = React.useState();
  const navigate = useNavigate();
  let ping = [];

  const handleNameAdd = (event) => {
    setName(event.target.value);
    console.log(event.target.value);
  };

  const handleCategoryAdd = (event) => {
    setCategory(event.target.value);
    console.log(event.target.value);
  };

  const handleDescriptionAdd = (event) => {
    setDescription(event.target.value);
    console.log(event.target.value);
  };

  //function to display the rule's discovered channel based on the notification clicked
  const handleNotificationClick = async (event, val) => {
    event.preventDefault();
    let message = {
      id: val,
    };

    let response = [];
    response = await view_channels(message);
    let chan = response.dbRes.data;

    console.log('notification clicked with response now: ', response);

    setChannels(chan);
    setSelectedNotificationId(response.dbRes.notification_id);
    setShowDetails(true);
    setPageCh(1);
    setEntriesCh(10);
    setPcCh(Math.ceil(response.dbRes.totalCount / entriesCh));
    setTotCountCh(response.dbRes.totalCount);
    setDetCh(entriesCh);
    setStateTester(true);

    console.log(
      'pagecount: ',
      pcCh,
      'detCh',
      detCh,
      'total count :',
      totCountCh,
      'channels : ',
      channels
    );

    all_dets = chan;
    // console.log('channels state: ',channels)
    console.log('al_dets variable: ', all_dets);
  };
  //function to refresh the notification displayed
  const handleNotificationClickRefresh = async (event) => {
    event.preventDefault();
    let message = {
      id: tableSelectedId,
      limitPerPage: entriesCh,
      pageNo: pageCh,
    };

    console.log('sent messages : ', message);
    let response = [];
    response = await view_channels(message);
    let chan = response.dbRes.data;

    console.log('notification clicked with response now: ', response);

    setChannels(chan);
    setSelectedNotificationId(response.dbRes.notification_id);
    setShowDetails(true);

    setPcCh(Math.ceil(response.dbRes.totalCount / entriesCh));
    setTotCountCh(response.dbRes.totalCount);
    setDetCh(entriesCh);
    setStateTester(true);

    console.log(
      'pagecount:1214 ',
      pcCh,
      'detCh',
      detCh,
      'total count :',
      totCountCh,
      'channels : ',
      channels
    );

    all_dets = chan;
    // console.log('channels state: ',channels)
    console.log('al_dets variable: ', all_dets);
  };

  //function to display all the chanels discovered by a particular rule when clicked on the "view channels" button on the rules tab
  const handleViewRuleChannelClick = async (event, val) => {
    event.preventDefault();
    let message = {
      keyword_rule_id: val,
      pageNo: 1,
      limitPerPage: 10,
    };

    let response = [];
    response = await view_rule_channels(message);
    let chan = response.dbRes.data;
    setChannels(chan);
    all_dets = chan;
    console.log('channels state: ', channels);
    console.log('al_dets variable: ', all_dets);
    setPageCh(1);
    setEntriesCh(10);
    setPcCh(Math.ceil(response.dbRes.totalCount / entriesCh));
    setTotCountCh(response.dbRes.totalCount);
    setDetCh(entriesCh);
    setShowDetails(true);

    setStateTester(true);

    console.log(
      'for view channel button on rules pagecount pcCh: ',
      pcCh,
      'vs pcCh: ',
      Math.ceil(response.dbRes.totalCount / entriesCh),
      'detCh',
      detCh,
      'vs detCh: ',
      entriesCh,
      'total count :',
      totCountCh,
      'vs totCount',
      response.dbRes.totalCount,
      'channels : ',
      response
    );
  };

  //function to refresh the view selected rules channels
  const handleViewRuleChannelRefresh = async (event) => {
    event.preventDefault();
    let message = {
      keyword_rule_id: tableSelectedId,
      pageNo: pageCh,
      limitPerPage: entriesCh,
    };

    let response = [];
    response = await view_rule_channels(message);
    let chan = response.dbRes.data;
    setChannels(chan);
    all_dets = chan;
    console.log('channels state: ', channels);
    console.log('al_dets variable: ', all_dets);

    setPcCh(Math.ceil(response.dbRes.totalCount / entriesCh));
    setTotCountCh(response.dbRes.totalCount);
    setDetCh(entriesCh);
    setShowDetails(true);

    setStateTester(true);

    console.log(
      'for view channel button on rules pagecount pcCh: ',
      pcCh,
      'vs pcCh: ',
      Math.ceil(response.dbRes.totalCount / entriesCh),
      'detCh',
      detCh,
      'vs detCh: ',
      entriesCh,
      'total count :',
      totCountCh,
      'vs totCount',
      response.dbRes.totalCount,
      'channels : ',
      response
    );
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
      timeZone: 'Asia/Kolkata',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return date.toLocaleString('en-IN', options);
  }

  const handleKeyAdd = (event) => {
    event.preventDefault();
    setToggleAddKeys(!toggleAddKeys);
  };

  // const handleChange = event =>{
  //     setKey(event.target.value) ;
  //     console.log(event.target.value)
  //    }

  // const handleAddKeys = (event, val) =>{
  //     event.preventDefault()
  //     if(selectedKeys.indexOf(val) === -1){
  //         setSelectedKeys([...selectedKeys, val]);
  //         setSelectCount(selectCount+1);
  //     }
  // }

  // const handleRemoveKeys = (event, val) =>{
  //     event.preventDefault()
  //     console.log(val);
  //     setSelectedKeys(selectedKeys.filter(selectedKeys => selectedKeys !== val.key));
  //     setSelectCount(selectCount-1);
  // }

  //function to handle switching between tabs
  const handleTabChange = async (event, val) => {
    event.preventDefault();
    if (val === 'createMonitorRule') {
      setTab(0);
      setShowDetails(false);
      return 0;
    } else if (val === 'viewMonitorRule') {
      setTab(1);
      setShowDetails(false);
      return 1;
    } else {
      setTab(2);
      setShowDetails(false);
      return 2;
    }
  };

  //function to clear existing input values and close the form
  const closeRule = (event) => {
    event.preventDefault();
    setName();
    setCategory();
    setSelectedKeys([]);
    setDescription();
    setId();
    setSelectedOptions([]);
  };

  //function to pre fill the form from the existing rule before allowing edit
  const handleEditRule = (event, value) => {
    console.log(value);
    event.preventDefault();
    setCategory(value.category);
    setName(value.name);
    setSelectedKeys(value.selectedKeys);
    setDescription(value.description);
    setUpdate(true);
    setOpenbox(!openbox);
    setId(value.id);
    setSelectedOptions(value.targeted_keyword_rules);
  };

  //function to enable and disable rule
  const handlePauseRule = async (event, val) => {
    event.preventDefault();
    console.log('in pause rule: ', val);
    const rule = {
      id: val.id.$oid.toString(),
      name: val.name,
      keyword_categories: val.category,
      search_keywords: val.selectedKeys,
      keyword_description_note: val.description,
      rule_enabled: !val.rule_enabled,
      // "targeted_keyword_rules": val.targeted_keyword_rules
    };
    console.log('sending this message in pause rule now', rule);
    let response = [];
    response = await edit_rule(rule);

    // window.location.reload();
    setViewMonitorRule(true);
    const message = {
      pageNo: pageR,
      limitPerPage: entriesR,
    };
    test_c(message);
    console.log(rule);
  };

  const handleRunRule = async(event, val) =>{
    event.preventDefault();
    console.log('in pause rule: ', val);
    const rule = {
      keyword_rule_id: val.id.$oid.toString()
    };
    console.log('sending this message in pause rule now', rule);
    let response = [];
    response = await run_indiviual_rule(rule);
    console.log(response)
    const message = {
      pageNo: pageR,
      limitPerPage: entriesR,
    };
    test_c(message);
  }

  //function to create a new rule and the render the page to display it instantly
  const sendRule = async (event) => {
    event.preventDefault();
    let rule;
    console.log(update);
    let arr = [];
    // for(let i=0;i<selectedOptions.length;i++){
    //     arr.push(selectedOptions[i].value);
    // }
    if (update === false) {
      if (!selectedKeys || !name) {
        setViewFormConfirmation(true);
      } else {
        setOpenbox(!openbox);
        const uniqueKeys = selectedKeys
          .map((key) => key.trim()) // remove leading and trailing whitespace
          .filter(
            (key, index, array) => key !== '' && array.indexOf(key) === index
          ); // filter out empty strings and duplicates

        console.log(uniqueKeys);

        rule = {
          name: name,
          keyword_categories: category,
          search_keywords: uniqueKeys,
          keyword_description_note: description,
          rule_enabled: true,
          // "targeted_keyword_rules": arr
        };
        setName();
        setCategory();
        setSelectedKeys([]);
        setDescription();
        setId();

        let response = [];
        response = await test_call(rule);
        console.log(rule);
        // window.location.reload();
        const message = {
          pageNo: pageR,
          limitPerPage: entriesR,
        };
        test_c(message);
      }
    } else {
      setOpenbox(!openbox);
      const uniqueKeys = selectedKeys
        .map((key) => key.trim()) // remove leading and trailing whitespace
        .filter(
          (key, index, array) => key !== '' && array.indexOf(key) === index
        ); // filter out empty strings and duplicates

      console.log(uniqueKeys);
      rule = {
        name: name,
        keyword_categories: category,
        search_keywords: uniqueKeys,
        keyword_description_note: description,
        rule_enabled: true,
        id: id.$oid,
        // "targeted_keyword_rules": arr
      };

      setName();
      setCategory();
      setSelectedKeys([]);
      setDescription();
      setId();
      setUpdate(false);

      let response = [];
      response = await edit_rule(rule);
      console.log(rule);
      // window.location.reload();
      const message = {
        pageNo: pageR,
        limitPerPage: entriesR,
      };
      test_c(message);
    }
  };

  //function to delete an existing rule
  const handleDeletePop = async (event) => {
    setDelete_pop(false);
    const val = to_delete_rule;
    event.preventDefault();
    const rule = {
      id: val.id.$oid.toString(),
      name: val.name,
      keyword_categories: val.category,
      search_keywords: val.selectedKeys,
      keyword_description_note: val.description,
      rule_enabled: value.enabled,
      deleted: true,
    };
    let response = [];
    response = await edit_rule(rule);
    setTo_delete_row();
    // window.location.reload();
    setViewMonitorRule(true);
    const message = {
      pageNo: pageR,
      limitPerPage: entriesR,
    };
    test_c(message);
    console.log(rule);
  };

  const handleDelete = async (event, val) => {
    event.preventDefault();
    setDelete_pop(true);
    setTo_delete_row(val);

    // const rule={
    //         "id":(val.id.$oid).toString(),
    //         "name": val.name,
    //         "keyword_categories": val.category,
    //         "search_keywords": val.selectedKeys,
    //         "keyword_description_note": val.description,
    //         "rule_enabled": value.enabled,
    //         "deleted": true
    // }
    // let response = [];
    // response = await edit_rule(rule)
    // window.location.reload();
    // setViewMonitorRule(true);
    // console.log(rule);
  };

  //function to enable or disable slack notifications
  const handleUpdateSlack = async (event, val) => {
    event.preventDefault();
    console.log(val);
    let rule = {
      should_send_slack_notification: !val.slack_enabled,
      id: val.id.$oid,
    };
    console.log('here is the sent message : ', rule);

    setName();
    setCategory();
    setSelectedKeys([]);
    setDescription();
    setId();
    setSelectedOptions([]);
    setUpdate(false);

    let response = [];
    response = await edit_rule(rule);
    console.log(rule);
    // window.location.reload();
    const message = {
      pageNo: pageR,
      limitPerPage: entriesR,
    };
    test_c(message);
  };

  const handleAddKeys = (event, val) => {
    event.preventDefault();
    if (selectedKeys.indexOf(val) === -1) {
      setSelectedKeys([...selectedKeys, val]);
      setSelectCount(selectCount + 1);
    }
  };

  const handleRemoveKeys = (event, val) => {
    event.preventDefault();
    console.log(val);
    setSelectedKeys(
      selectedKeys.filter((selectedKeys) => selectedKeys !== val.key)
    );
    setSelectCount(selectCount - 1);
  };

  const handleCheck = (event) => {
    const v = event.target.value;
    if (event.target.checked) {
      if (check.indexOf(v) < 0) {
        const arr = check;
        arr.push(v);
        setCheck(arr);
      }
    } else {
      if (check.indexOf(v) >= 0) {
        const arr = check.filter((a) => a !== v);
        setCheck(arr);
      }
    }
    console.log(check);
  };

  const handleFin_tags = () => {
    const mergedArray = check
      .concat(keywords)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
    console.log('merged arr :::::', mergedArray);
    setFin_tags(mergedArray);
    console.log('set_fin_tags : ', fin_tags);
    return mergedArray;
  };

  function createData(
    name,
    description,
    category,
    created_at,
    rule_enabled,
    selectedKeys,
    id,
    is_processing,
    updated_at,
    slack_enabled,
    targeted_keyword_rules
  ) {
    return {
      name,
      description,
      category,
      created_at,
      rule_enabled,
      selectedKeys,
      id,
      is_processing,
      updated_at,
      slack_enabled,
      targeted_keyword_rules,
    };
  }

  const handleKeywords = (event, value) => {
    setKeywords(value);
  };

  //function to handle page change of notifications tab
  const handlePage = (event, value) => {
    event.preventDefault();
    setPage(value);
    const message = {
      pageNo: value,
      limitPerPage: entries,
    };
    handleNotifications(message);
  };

  //function to handle page change of the channels viewed through clicking on a particular notification or through view channels on the rule card
  const handlePageCh = async (event, value) => {
    event.preventDefault();
    setPageCh(value);
    // const message = {
    //     "pageNo": value,
    //     "limitPerPage": entriesCh,
    // }

    // handleNotifications(message);
    let message = {};
    if (tab === 0) {
      let message = {
        keyword_rule_id: tableSelectedId,
        pageNo: value,
        limitPerPage: entriesCh,
      };

      let response = [];
      response = await view_rule_channels(message);
      let chan = response.dbRes.data;
      setChannels(chan);
      all_dets = chan;
      console.log('channels state: ', channels);
      console.log('al_dets variable: ', all_dets);
      setShowDetails(true);
    } else if (tab === 1) {
      let message = {
        id: tableSelectedId,
        pageNo: value,
        limitPerPage: entriesCh,
      };

      let response = [];
      response = await view_channels(message);
      let chan = response.dbRes.data;
      setChannels(chan);
      setSelectedNotificationId(response.dbRes.notification_id);
      setShowDetails(true);
      all_dets = chan;
      // console.log('channels state: ',channels)
      console.log('al_dets variable: ', all_dets);
    }
  };

  const showJoinConfirmation = async (row, index) => {
    setSelectedJoinChannel(row);
    setSelectedJoinChannelIndex(index);
    setShowJoinPop(true);
    // await changeValue(row, index);
  };

  //function to import all messages of a given channel
  const changeImportAllValue = async (value, index) => {
    let message = {};
    if (tab === 1) {
      message = {
        entity_id: value.channel_id,
        notification_id: selectedNotificationId,
        entity_user_name: value.channel_username,
        join_source_type: 'CHANNEL_DISCOVERED',
        import_all_messages: true,
      };
    } else if (tab === 0) {
      message = {
        entity_id: value.channel_id,
        notification_id: value.notification_id,
        entity_user_name: value.channel_username,
        join_source_type: 'CHANNEL_DISCOVERED',
        import_all_messages: true,
      };
    } else {
      message = {
        entity_id: value.channel_id,
        join_source_type: 'MANUAL',
        entity_user_name: value.channel_username,
        import_all_messages: true,
      };
    }

    let response = [];
    response = await join_channel(message);
    let chan = response.dbRes;
    console.log(chan);
    let updatedChannels = [...channels];
    updatedChannels[index].jobState = chan.job_state;
    updatedChannels[index].isDisabled = chan.job_state === 'joined';
    setChannels(updatedChannels);
  };

  //function to join a channel without importing its existing messages
  const changeValue = async (value, index) => {
    let message = {};
    if (tab === 1) {
      message = {
        entity_id: value.channel_id,
        notification_id: selectedNotificationId,
        entity_user_name: value.channel_username,
        join_source_type: 'CHANNEL_DISCOVERED',
      };
    } else if (tab === 0) {
      message = {
        entity_id: value.channel_id,
        notification_id: value.notification_id,
        entity_user_name: value.channel_username,
        join_source_type: 'CHANNEL_DISCOVERED',
      };
    } else {
      message = {
        entity_id: value.channel_id,
        join_source_type: 'MANUAL',
        entity_user_name: value.channel_username,
        import_all_messages: false,
      };
    }

    let response = [];
    response = await join_channel(message);
    let chan = response.dbRes;
    console.log(chan);
    let updatedChannels = [...channels];
    updatedChannels[index].jobState = chan.job_state;
    updatedChannels[index].isDisabled = chan.job_state === 'joined';
    setChannels(updatedChannels);
  };

  const handleDets = (event, value) => {
    console.log(value);
  };

  const handleChange = (event) => {
    setKey(event.target.value);
    console.log(event.target.value);
  };

  const finalSubmit = () => {
    const t = handleFin_tags();

    const message = {
      index: 0,
      keys: t,
    };
    test_c(message);
  };

  const handlePageR = async () => {};

  const test_c = async (message) => {
    let response = [];
    response = await rule(message);
    let chan = response.dbRes.data;

    // setPc(pCount);
    // setTotCount(tot);
    setDetR(chan.length);
    setPcR(Math.ceil(response.dbRes.totalCount / entriesR));
    setTotCountR(response.dbRes.totalCount);
    setDetR(entriesR);

    let test_rows = [];
    for (let i = 0; i < chan.length; i++) {
      test_rows.push(
        createData(
          chan[i].name,
          chan[i].keyword_description_note,
          chan[i].keyword_categories,
          chan[i].created_at,
          chan[i].rule_enabled,
          chan[i].search_keywords,
          chan[i]._id,
          chan[i].is_processing,
          chan[i].updated_at,
          chan[i].should_send_slack_notification,
          chan[i].targeted_keyword_rules
        )
      );
    }
    setSample_rows(test_rows);
    setLoading(false);
  };

  const handleNotifications = async (message) => {
    console.log('notification function called for message : ', message);
    let response = [];
    response = await notifications(message);
    let chan = response.dbRes.data;
    console.log('response recieved for the request of page change: ', response);
    setNotify(chan);
    // setPage(1);
    // setEntries(10)
    setPc(Math.ceil(response.dbRes.totalCount / entries));
    setTotCount(response.dbRes.totalCount);
    setDet(entries);
    setStateTester(true);
    console.log('set notify state : ', notify, 'chan: ', chan);

    ping = chan;
    console.log('notify state: ', notify);
    console.log('ping variable: ', ping);
  };

  const handleNotificationRefresh = async (message) => {
    console.log('notification function called for message  refresh: ', message);
    console.log('page refresh called : ', page);
    let response = [];
    response = await notifications(message);
    let chan = response.dbRes.data;
    console.log(
      'response recieved for the request of page change refresh : ',
      response
    );
    setNotify(chan);
    setPc(Math.ceil(response.dbRes.totalCount / entries));
    setTotCount(response.dbRes.totalCount);
    setDet(entries);
    setStateTester(true);
    console.log('set notify state : ', notify, 'chan: ', chan);

    ping = chan;
    console.log('notify state: ', notify);
    console.log('ping variable: ', ping);
  };

  React.useEffect(() => {
    if (searchedMention) {
      setTab(2);
      setSearchChannel(searchedMention);
      handleSearchMentionClick(searchedMention);
    }
    const message = {
      pageNo: 1,
      limitPerPage: 10,
    };
    handleNotifications(message);
    //   buttonRef.current.click();
    test_c(message);
  }, []);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: checked }));
  };

  const handleFilters = () =>{
    console.log(filters)
    const message = {
      pageNo: 1,
      limitPerPage: 10,
      filters: []
    };

    for (const key in filters) {
      if (filters.hasOwnProperty(key) && filters[key]) {
        // If the checkbox is checked (i.e., the value is true), add the field name to the filters array
        message.filters.push(key);
      }
    }

    console.log(message)
    test_c(message)

    setFilterPopUp(!filterPopUp)
  }



  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <div
      className='page'
      style={{
        paddingTop: '2%',
      }}
    >
      <div className='headBar'>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div className='Head1'>Discover Channels</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 600,
              color: '00ACFB',
              gap: '15px',
              alignContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <div style={{cursor:'pointer'}} onClick={()=>{
              setFilterPopUp(!filterPopUp)

            }}>
            {/* {tab === 0 && showDetails === false <img src={Filter} alt='Filter' style={{ height: '3vh' }} />} */}
            {tab === 0 && !showDetails && (
  <img src={Filter} alt='Filter' style={{ height: '3vh' }} />
)}
            </div>
           
            {tab !== 0 && tab !==2 && (
              <div
              style={{cursor:'pointer'}}
              
                
                onClick={async () => {
                  const response = await mark_key_notification_read(notify);
                  toast.success(
                    'Marked All Messages as Read',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: 'light',
                    }
                  );
                  const message = {
                    pageNo: 1,
                    limitPerPage: 10,
                  };
                  handleNotifications(message);
                }}
              >
                {showDetails  ? (
                  <span className='apply_filter' >Export All Channels</span>
                ) : (
                  <span
                  className='apply_filter'
                    
                  >
                    Mark All Read
                  </span>
                )}
              </div>
            )}
            <div
             
              onClick={() => {
                setOpenbox(!openbox);
              }}
            >
              {' '}
              <span className='apply_filter' style={{cursor:'pointer'}}>Add Monitor Rule</span>
            </div>
          </div>
        </div>
      

        {/* <button className='userBtn'>Welcome User</button> */}
      </div>
      <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          style={{ width: '17%', fontWrap: 'none', textAlign: 'left' }}
        />
        <div className='tab'>
          {openbox === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setOpenbox(false);
              }}
            >
              <Box
                className='Box'
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal' style={{ padding: '10px' }}>
                  <div className='centerHead'>Add/Edit Rule to Monitor</div>

                  <div>
                    <div
                      className=''
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        fontSize: '16px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Name</span>
                      <span style={{ color: '#E32C2C' }}>*</span>
                    </div>

                    <div className='searchBar'>
                      <input
                        type='text'
                        onChange={handleNameAdd}
                        defaultValue={name}
                        className='nameInputBox'
                        placeholder='Drugs, Data, etc'
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      className=''
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        fontSize: '16px',
                        fontWeight: 500,
                        marginTop: '10px',
                      }}
                    >
                      <span>Description</span>
                    </div>
                    <div className='searchBar'>
                      <textarea
                        className='descriptionInputBox'
                        defaultValue={description}
                        placeholder='Add a description to the rule'
                        onChange={handleDescriptionAdd}
                      ></textarea>
                      {/* <input type='text' onChange= {handleDescriptionAdd} className='descriptionInputBox' placeholder='Add a few lines to describe the monitor rule' /> */}
                    </div>
                  </div>

                  <div>
                    <div
                      className=''
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        fontSize: '16px',
                        fontWeight: 500,
                        marginTop: '15px',
                      }}
                    >
                      <span>Message Keywords</span>
                      <span style={{ color: '#E32C2C' }}>*</span>
                    </div>
                    {/* <div className='searchBar'>
                                            <button className='add_KeywordBtn' onClick={(event) => handleKeyAdd(event)}>Select keywords</button>
                                        </div> */}
                    {/* <div className='searchBar'> */}
                    {/* <input type='text' onChange={handleChange} className='keySearch' placeholder='Drugs, Data, etc' />
                                                <button onClick={(event) => handleAddKeys(event, key)} className='addKeywordBtn'>Add</button> */}
                    <MultipleValueTextInput
                      className='multiInputBar'
                      onItemAdded={(item, allItems) =>
                        setSelectedKeys(allItems)
                      }
                      onItemDeleted={(item, allItems) =>
                        setSelectedKeys(allItems)
                      }
                      submitKeys={['Enter', ',']}
                      name='item-input'
                      placeholder='Enter items; separate them with COMMA or ENTER'
                      values={selectedKeys}
                    />
                    {/* </div> */}
                  </div>
                  <div></div>
                  {/* <div>
                                                    <div className='subHeads'>
                                                        Targeted Keyword Rules
                                                    </div>
                                                    <div> 
                                                        <Select className='entryBox'
                                                            isMulti
                                                            options={tags}
                                                            value={selectedOptions}
                                                            onChange={handleSelectChange}
                                                            placeholder="Search or select options"
                                                            isSearchable
                                                            onInputChange={handleInputChange}
                                                        />
                                                    </div>
                                </div> */}
                  <div className='finalising_div'>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        sendRule(event);
                      }}
                    >
                      {id ? 'Update' : 'Create'}
                    </button>
                    <button
                      className='btnCancel'
                      onClick={(event) => {
                        setOpenbox(!openbox);
                        closeRule(event);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>
        {filterPopUp === true ? (
            <div
              className='filterBackdropModal'
              onClick={() => {
                setFilterPopUp(false);
              }}
            >
              <div
                className='filterbox'
                onClick={(e) => e.stopPropagation()}
              >
                <span style={{fontSize:'20px',fontWeight:600,color:'#223036',width:'100%',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>Filter</span>
                <div style={{margin:'10px 10px',display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center'}}>
                  <input type='checkbox' style={{marginRight:'5px',border:'2px solid #898F9A',borderRadius:'5px',height:'18px',width:"18px",alignItems:'center',}}
                    name = "allFilters"
                    checked={filters.allFilters}
                    onChange={handleCheckboxChange}
                  />
                  <span >Show All rules</span>
                </div>
                <div style={{margin:'10px 10px',display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center'}}>
                  <input type='checkbox' style={{marginRight:'5px',border:'2px solid #898F9A',borderRadius:'5px',height:'18px',width:"18px",alignItems:'center',}}
                    name = "enabled"
                    checked={filters.enabled}
                    onChange={handleCheckboxChange}
                  />
                  <span >Only enabled rules</span>
                </div>
                <div style={{margin:'10px 10px',display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center'}}>
                  <input type='checkbox' style={{marginRight:'5px',border:'2px solid #898F9A',borderRadius:'5px',height:'18px',width:"18px",alignItems:'center',}}
                    name = "disabled"
                    checked={filters.disabled}
                    onChange={handleCheckboxChange}
                  />
                  <span >Only disabled rules</span>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <div style={{borderRadius:'10px',padding:'4% 8%',color:'white',margin:'10px',fontSize:'16px',backgroundColor:'#00ACFB',fontWeight:500,cursor:'pointer'}}  onClick={()=>handleFilters()}>
                    Apply
                  </div>
                  <div style={{border:'1px solid #00ACFB',padding:'4% 8%',backgroundColor:'white',color:'#00ACFB',borderRadius:'10px',fontSize:'16px',fontWeight:500,cursor:'pointer'}} onClick={()=>setFilterPopUp(!filterPopUp)}>
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        <div className='tab'>
          {delete_pop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setFilterPopUp(false);
              }}
            >
              <Box
                className='Box'
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal'>
                  <div>
                    Are you sure you want to delete the rule{' '}
                    {to_delete_rule.name}?
                  </div>
                  <div className='finalising_div'>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        handleDeletePop(event);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className='btnCancel'
                      onClick={(event) => {
                        setTo_delete_row();
                        setFilterPopUp(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='tab'>
          {showError === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setShowError(false);
              }}
            >
              <Box
                className='Box'
                style={{ maxWidth: 'fit-content', padding: '20px' }}
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal'>
                  <div>The field cannot be left empty.</div>
                  <div className='finalising_div' style={{ marginTop: '2vh' }}>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        setShowError(false);
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className='tab'>
          {viewFormConfirmation === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setViewFormConfirmation(false);
              }}
            >
              <Box
                className='Box'
                style={{ maxWidth: 'fit-content', padding: '20px' }}
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal'>
                  <div>One or more required field left empty.</div>
                  <div className='finalising_div' style={{ marginTop: '2vh' }}>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        setViewFormConfirmation(false);
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className='tab'>
          {showJoinPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setShowJoinPop(false);
              }}
            >
              <Box
                className='Box'
                style={{ maxWidth: 'fit-content', padding: '20px' }}
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal'>
                  <div>
                    Are you sure you want to join{' '}
                    {selectedJoinChannel.channel_title} ?.
                  </div>
                  <div className='finalising_div' style={{ marginTop: '2vh' }}>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        setImportPop(true);
                        setShowJoinPop(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        setShowJoinPop(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>
        {exportNotification ? (
        <div
          className='backdropModal'
          onClick={() => {
            setExportNotification(false);
          }}
        >
          <Box
            style={{ zIndex: '3',padding:'10px' }}
            className='Box'
            sx={style}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='keywordsModal'>
              
        
             
            
            <div style={{margin:'10px 0px',borderRadius:'10px',width:'100%'}}>
                      <span style={{marginRight:'10px'}}>
                        Note:
                      </span>
                      <input style={{border:'1px solid',borderRadius:'4px',width:'100%'}} 
                      value={notes}
                      onChange={({target})=>{
                        setNotes(target.value)
                      }}/>

                    </div>
            <span style={{margin:'10px 0px',fontSize:'20px'}}>
              Export with Profile Picture?
            </span>
            
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <button style={{margin:'10px',borderRadius:'10px'}} onClick={async()=>{
                
                const response = await export_specific_discover_notification(notificationId,notes)
                setExportNotification(!exportNotification)
                toast.success('Requested! job_id: ' + response.dbRes.job_id, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              }}>
                Yes
              </button>
              <button style={{borderRadius:'10px'}} onClick={()=>{
                setExportNotification(!exportNotification)
              }}>
                No
              </button>
            </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}

        <div className='tab'>
          {showSlackPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setShowSlackPop(false);
              }}
            >
              <Box
                className='Box'
                style={{ maxWidth: 'fit-content', padding: '20px' }}
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal'>
                  <div>
                    Are you sure you want to join{' '}
                    {selectedJoinChannel.channel_title} ?.
                  </div>
                  <div className='finalising_div' style={{ marginTop: '2vh' }}>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        setShowSlackPop(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        setShowSlackPop(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className='tab'>
          {importPop === true ? (
            <div
              className='backdropModal'
              onClick={() => {
                setImportPop(false);
              }}
            >
              <Box
                className='Box'
                style={{ maxWidth: 'fit-content', padding: '20px' }}
                sx={style}
                onClick={(e) => e.stopPropagation()}
              >
                <div className='keywordsModal'>
                  <div>
                    Please select one of the following options for the messages
                    of the channel before joining.
                  </div>
                  <div className='finalising_div' style={{ marginTop: '2vh' }}>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        changeImportAllValue(
                          selectedJoinChannel,
                          selectedJoinChannelIndex
                        );
                        setImportPop(false);
                      }}
                    >
                      Import All existing
                    </button>
                    <button
                      className='btnCreate'
                      onClick={(event) => {
                        changeValue(
                          selectedJoinChannel,
                          selectedJoinChannelIndex
                        );
                        setImportPop(false);
                      }}
                    >
                      Import future messages only
                    </button>
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
        </div>
      {exportPopUp ? (
        <div
          className='backdropModal'
          onClick={() => {
            setExportPopUp(false);
          }}
        >
          <Box
            style={{ zIndex: '3',padding:'10px' }}
            className='Box'
            sx={style}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='keywordsModal'>
              
        
             
            
            <div style={{margin:'10px 0px',borderRadius:'10px',width:'100%'}}>
                      <span style={{marginRight:'10px'}}>
                        Note:
                      </span>
                      <input style={{border:'1px solid',borderRadius:'4px',width:'100%'}} 
                      value={notes}
                      onChange={({target})=>{
                        setNotes(target.value)
                      }}/>

                    </div>
           
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <button style={{margin:'10px',borderRadius:'10px'}} onClick={async()=>{
                const response = await export_discover_rule(ruleId,notes);
                toast.success(
                  'Requested! job_id: ' +
                    response.dbRes.job_id,
                  {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  }
                );
                setExportPopUp(!exportPopUp)
               
              }}>
                Yes
              </button>
              <button style={{borderRadius:'10px'}} onClick={()=>{
                setExportPopUp(!exportPopUp)
              }}>
                No
              </button>
            </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}

      <div className='tabsContainer'>
        <button
          className='tabSelectors'
          style={
            tab === 0 && showDetails === false
              ? { backgroundColor: '#EBF9FF', color: '#00ACFB' }
              : {}
          }
          onClick={(event) => {
            handleTabChange(event, 'createMonitorRule');
          }}
        >
          View Rules
        </button>
        <button
          className='tabSelectors'
          style={
            tab === 1 && showDetails === false
              ? { backgroundColor: '#EBF9FF', color: '#00ACFB' }
              : {}
          }
          onClick={(event) => {
            handleTabChange(event, 'viewMonitorRule');
            handleNotifications({ pageNo: page, limitPerPage: 10 });
          }}
        >
          Notifications
        </button>
        <button
          className='tabSelectors'
          style={
            tab === 2 && showDetails === false
              ? { backgroundColor: '#EBF9FF', color: '#00ACFB' }
              : {}
          }
          onClick={(event) => {
            handleTabChange(event, 'search Channel');
          }}
        >
          Search Channels
        </button>
        {showDetails ? (
          <>
            <button
              className='tabSelectors'
              style={{ backgroundColor: '#EBF9FF', color: '#00ACFB' }}
            >
              Discovered
            </button>
            <button
              className='tabSelectors btn btn-primary'
              style={{ backgroundColor: '#EBF9FF', color: '#00ACFB' }}
              onClick={(event) => {
                handleTabChange(event, 'viewMonitorRule');
                handleNotifications({ pageNo: page, limitPerPage: 10 });
              }}
            >
              Back
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className='pageBody'>
        {showDetails ? (
          <div>
            <div style={{ width: '100%', display: "flex" }}>
              <div style={{display:'flex',justifyContent:'start',alignItems:'center',marginLeft:'10px'}}>
                <span style={{ fontWeight: 600, fontSize: '18px' }}>Rule-{""}</span>
                <span style={{ fontWeight: 600, fontSize: '18px',color:'#00ACFB' }}>{showRuleName}</span>
              </div>
              <button
                style={{
                  width: 'fit-content',
                  marginLeft: 'auto',
                  backgroundColor: 'white',
                }}
                onClick={(event) => {
                  tab === 0
                    ? handleViewRuleChannelRefresh(event)
                    : handleNotificationClickRefresh(event);
                }}
              >
                <img
                  style={{ height: '3vh' }}
                  src={refresh_btn}
                  alt='refresh'
                />
              </button>
            </div>
            <div className='tableBox'>
              <TableContainer
                component={Paper}
                sx={{
                  height: 600,
                  boxShadow: '0px 20px 50px 0px #0000001A',
                  borderRadius: '8px 8px 0px 0px',
                }}
                style={{ textAlign: 'center' }}
              >
                <Table stickyHeader>
                  {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                  {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}

                  <TableHead sx={{ fontFamily: "'Fredoka', sans-serif" }}>
                    <TableRow>
                      {/* change width or remove it in case on any alignment issues*/}
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: "'Fredoka', sans-serif",
                          fontSize: '2vh',
                          backgroundColor: '#F7F9FB',
                          color: '#898F9A',
                          textAlign: 'center',
                          width: '100px',
                        }}
                      >
                        Channel Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: "'Fredoka', sans-serif",
                          fontSize: '2vh',
                          backgroundColor: '#F7F9FB',
                          color: '#898F9A',
                          textAlign: 'center',
                        }}
                      >
                        Matched Tags
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: "'Fredoka', sans-serif",
                          fontSize: '2vh',
                          backgroundColor: '#F7F9FB',
                          color: '#898F9A',
                          textAlign: 'center',
                        }}
                      >
                        Participants Count
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: "'Fredoka', sans-serif",
                          fontSize: '2vh',
                          backgroundColor: '#F7F9FB',
                          color: '#898F9A',
                          textAlign: 'center',
                        }}
                      >
                        Discovered Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: "'Fredoka', sans-serif",
                          fontSize: '2vh',
                          backgroundColor: '#F7F9FB',
                          color: '#898F9A',
                          textAlign: 'center',
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: "'Fredoka', sans-serif",
                          fontSize: '2vh',
                          backgroundColor: '#F7F9FB',
                          color: '#898F9A',
                          textAlign: 'center',
                        }}
                      >
                        Link
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {console.log('viewing channel rule channels: ', channels)}
                  <TableBody sx={{ height: 'max-content' }}>
                    {channels.map((row, index) => (
                      <TableRow
                        // key={row.name}
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          background: index % 2 ? '#F5F7FB' : 'FFFFFF',
                        }}
                      >
                        <TableCell
                          align='left'
                          title={row.channel_username}
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {row.channel_title}
                        </TableCell>
                        <TableCell align='center'>
                          {row.matched_tags.length >= 1 ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '4px',
                                justifyContent: 'center',
                              }}
                            >
                              <div
                                className='tags'
                                title={row.matched_tags.join('\n')}
                                style={{
                                  position: 'relative',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  padding: '10px',
                                }}
                              >
                                {row.matched_tags[0]}
                                <span className='tooltip'>
                                  {row.matched_tags.join('\n')}
                                </span>
                              </div>
                              {row.matched_tags.length > 1 ? '...' : ''}
                            </div>
                          ) : (
                            <></>
                          )}
                          <style jsx>{`
                            .tags:hover .tooltip {
                              visibility: visible;
                              opacity: 1;
                            }

                            .tags .tooltip {
                              position: absolute;
                              z-index: 1;
                              visibility: hidden;
                              opacity: 0;
                              background-color: #000;
                              color: #fff;
                              padding: 5px;
                              border-radius: 5px;
                              font-size: 12px;
                              white-space: pre-wrap;
                              text-align: left;
                              bottom: 110%;
                              left: 50%;
                              transform: translateX(-50%);
                              transition: visibility 0.3s, opacity 0.3s;
                            }
                          `}</style>
                        </TableCell>
                        <TableCell align='center'>
                          {row.channel_participants_count}
                        </TableCell>
                        <TableCell align='center'>
                          {formatDate(row.discovered_date)}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{ fontFamily: "'Fredoka', sans-serif" }}
                        >
                          {row.job_object ? (
                            <Button
                              onClick={() => showJoinConfirmation(row, index)}
                              disabled={!row.job_object.attributes.should_rerun}
                              style={
                                row.job_object.state === 'PENDING'
                                  ? {
                                      border: '1px solid #FF9458',
                                      color: '#FF9458',
                                      width: '50%',
                                    }
                                  : row.job_object.state === 'RETRYING'
                                  ? {
                                      border: '1px solid #919EAB',
                                      color: '#919EAB',
                                      width: '50%',
                                    }
                                  : row.job_object.state === 'REVOKED'
                                  ? {
                                      border: '1px solid #FF5858',
                                      color: '#FF5858',
                                      width: '50%',
                                    }
                                  : row.job_object.state === 'FAILURE'
                                  ? {
                                      border: '1px solid #FF5858',
                                      color: '#FF5858',
                                      width: '50%',
                                    }
                                  : row.job_object.state === 'STARTED'
                                  ? {
                                      border: '1px solid #898F9A',
                                      color: '#898F9A',
                                      width: '50%',
                                    }
                                  : {
                                      border: '1px solid #2CE38B',
                                      color: '#2CE38B',
                                      width: '50%',
                                    }
                              }
                            >
                              {' '}
                              {row.job_object.state}
                            </Button>
                          ) : (
                            <Button
                              onClick={() => showJoinConfirmation(row, index)}
                              disabled={row.is_joined}
                              style={
                                row.is_joined === false
                                  ? {
                                      border: '1px solid #00ACFB',
                                      width: '50%',
                                    }
                                  : {
                                      border: '1px solid #898F9A',
                                      color: '#898F9A',
                                      width: '50%',
                                    }
                              }
                            >
                              {' '}
                              {row.jobState
                                ? row.jobState
                                : row.is_joined
                                ? 'Joined'
                                : 'Join'}
                            </Button>
                          )}
                          {/* <Button onClick={() => showJoinConfirmation(row, index)} disabled={row.is_joined} style={row.is_joined === true ? { border: '1px solid #2CE38B', color:'#2CE38B', width: '100%' } : row.jobState === "PENDING" ? { border: '1px solid #FF9458', color: '#FF9458', width: '100%' }: row.jobState === 'RETRYING' ?{ border: '1px solid #919EAB', color: '#919EAB', width: '100%' }: row.jobState === 'REVOKED'? {border: '1px solid #FF5858', color: '#FF5858', width: '100%'}: row.jobState === 'FAILURE' ? {border: '1px solid #FF5858', color: '#FF5858', width: '100%'}: row.jobState === 'STARTED' ? {border: '1px solid #898F9A', color: '#898F9A', width: '100%'} :{border: '1px solid #898F9A', color: '#898F9A', width: '100%'}}> {row.jobState ? row.jobState : row.is_joined ? "Joined" : "Join"}</Button> */}

                          {/* use this in case of any anomaly in displaying the states of the job or disable or not  */}
                          {/* <Button onClick={() => showJoinConfirmation(row, index)} disabled={!row.job_object.attributes.should_rerun} style={row.job_object.attributes.should_rerun === true ? { border: '1px solid #00ACFB', width: '100%' } : { border: '1px solid #898F9A', color: '#898F9A', width: '100%' }}> {row.job_object.state}</Button> : */}
                        </TableCell>

                        <TableCell
                          align='center'
                          sx={{ fontFamily: "'Fredoka', sans-serif" }}
                        >
                          <a
                            href={`https://t.me/${row.channel_username}`}
                            target='_blank'
                            rel='noreferrer'
                            title='Telegram Link'
                          >
                            <img
                              alt='actions'
                              className='actionsIMG'
                              style={{ marginRight: '2px' }}
                              src={teleIcon}
                            />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='pagination_container'>
                <div className='nav_container'>
                  <Stack spacing={2}>
                    <Pagination
                      shape='rounded'
                      count={pcCh}
                      color='primary'
                      defaultValue={pageCh}
                      onChange={handlePageCh}
                      variant='outlined'
                      sx={{ fontFamily: "'Fredoka', sans-serif" }}
                    />
                  </Stack>
                </div>

                <div className='entries'>
                  No. of Entries:
                  <input
                    className='numberBox'
                    defaultValue={entries}
                    type='number'
                    onChange={(e) => setEntries(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        const message = {
                          pageNo: page,
                          limitPerPage:
                            e.target.value > '0' ? e.target.value : 10,
                        };
                        handleNotifications(message);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {tab === 0 ? (
              loading === true ? (
                <div
                  className='tab'
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingBlock: '15%',
                    justifyContent: 'center',
                  }}
                >
                  <img height='90px' src={loadingImg} alt='loading' />
                </div>
              ) : (
                <div className='tab'>
                  {sample_rows.length > 0 ? (
                    <div className='ruleContainer'>
                      {sample_rows.map((row) => (
                        <div className='ruleBox'>
                          <div
                            className='scrollbar'
                            style={{
                              width: '100%',
                              backgroundColor: '#f7f9fb',
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div
                                className='ruleHead'
                                title={`Last Updated at: ${formatDate(
                                  row.updated_at.$date
                                )}`}
                              >
                                {row.name}
                              </div>
                              <div
                                style={{
                                  marginLeft: 'auto',
                                  marginRight: '1%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <button
                                  style={{
                                    width: 'fit-content',
                                    backgroundColor: '#F7F9FB',
                                    color: 'red',
                                    fontSize: 'medium',
                                    marginRight: '10px',
                                  }}
                                  onClick={(event) => {
                                    handleUpdateSlack(event, row);
                                  }}
                                  title={
                                    row.slack_enabled
                                      ? 'Disable Slack notification'
                                      : 'Enable Slack notification'
                                  }
                                >
                                  <img
                                    alt='slack'
                                    style={{ height: '2vh' }}
                                    src={row.slack_enabled ? slack : grey_slack}
                                  />
                                </button>
                                <img
                                  alt='export'
                                  style={{ height: '2vh', cursor: 'pointer' }}
                                  src={ExportChannel}
                                  onClick={async () => {
                                    console.log(row);
                                    setRuleId(row.id.$oid)
                                    
                                    setExportPopUp(!exportPopUp)}}
                                />
                              </div>

                              <div>
                                <button
                                  style={{
                                    width: 'fit-content',
                                    backgroundColor: '#F7F9FB',
                                    color: 'red',
                                    fontSize: 'large',
                                  }}
                                  onClick={(event) => {
                                    handleDelete(event, row);
                                  }}
                                >
                                  <img
                                    src={trash}
                                    className='trash-icon'
                                    alt='trash'
                                    style={{ height: '2vh' }}
                                  />
                                </button>
                              </div>
                            </div>
                            <div style={{ color: '#898F9A' }}>
                              {formatDate(row.created_at.$date)}
                            </div>
                            <div style={{ color: '#898F9A' }}>
                              Last Ran {formatDate(row.updated_at.$date)}
                            </div>
                            {/* <div className='ruleDate' style={{ marginTop: '-1%' }}>
                                                Last Updated at : {formatDate(row.updated_at.$date)}
                                            </div> */}

                            {/* <div className='ruleKeys'>
                                                {
                                                    (row.selectedKeys).map((k)=>(
                                                        <div className='tags'>{k}</div>
                                                    ))
                                                }
                                            </div> */}
                            <div className=''>
                              <div className='' style={{ marginTop: '10px' }}>
                                <span style={{ fontWeight: 'bold' }}>
                                  Description:{' '}
                                </span>
                                {row.description}
                              </div>
                            </div>

                            {/* <div style={{display:'flex', justifyContent:'space-between' , marginTop:'auto', gap:'1%', height:'30%'}}> 
                                        <div className='ruleMediaBox'>
                                            <div className='ruleSubHead'>
                                                Keyword Categories :
                                            </div>
                                            <div className='ruleKeys'>
                                            {
                                                (row.category).map((k)=>( 
                                                            <div className='fileType'>{k}</div>
                                                    ))
                                                }                       
                                            </div>
                                        </div>
                                    </div> */}

                            <div className=''>
                              <div className='ruleSubHead'>Keywords:</div>
                              <div className='ruleKeys'>
                                {row.selectedKeys.map((tag) => (
                                  <div className='tags'>{tag}</div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div
                            className=''
                            style={{
                              marginBottom: '0%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingTop: '10px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <button
                                className='ruleBtn1'
                                onClick={(event) => handleEditRule(event, row)}
                                style={{ marginRight: '20px' }}
                              >
                                Edit
                              </button>
                              <button
                                className='ruleBtn2'
                                onClick={(event) => handlePauseRule(event, row)}
                                style={
                                  row.rule_enabled
                                    ? {marginRight: '20px'}
                                    : {
                                        borderColor: '#00CE3A',
                                        color: '#00CE3A',
                                        marginRight: '20px'
                                      }
                                }
                              >
                                {row.rule_enabled ? 'Disable' : 'Enable'}
                              </button>
                              <button
                                className='ruleBtn2'
                                onClick={(event) => handleRunRule(event, row)}
                                style={
                                  row.is_processing
                                    ? <img
                                    alt='loading'
                                    style={{ marginLeft: 'auto', height: '30px' }}
                                    src={loadingImg}
                                  />
                                    : {
                                        borderColor: 'green',
                                        color: 'green',
                                      }
                                }
                              >Run</button>
                            </div>

                            {row.is_processing ? (
                              <img
                                alt='loading'
                                style={{ marginLeft: 'auto', height: '30px' }}
                                src={loadingImg}
                              />
                            ) : (
                              <></>
                            )}
                            <div style={{ marginRight: '1%' }}>
                              <button
                                className='ruleBtn1'
                                onClick={(event) => {
                                  setTableSelectedId(row.id.$oid);
                                  handleViewRuleChannelClick(
                                    event,
                                    row.id.$oid
                                  );
                                  setShowRuleName(row.name)
                                }}
                                style={{
                                  backgroundColor: '#00ACFB',
                                  border: 0,
                                  color: 'white',
                                }}
                              >
                                View Channels
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className='paginateMessages'>
                        <div className='nav_container'>
                          <Stack spacing={2}>
                            <Pagination
                              shape='rounded'
                              count={pcR}
                              color='primary'
                              defaultValue={pageR}
                              onChange={(event, value) => {
                                event.preventDefault();
                                setPageR(value);
                                let message = {
                                  pageNo : value,
                                  limitPerPage: entriesR,
                                  filters: []
                                }
                                for (const key in filters) {
                                  if (filters.hasOwnProperty(key) && filters[key]) {
                                    // If the checkbox is checked (i.e., the value is true), add the field name to the filters array
                                    message.filters.push(key);
                                  }
                                }
                                test_c(message);
                              }}
                              variant='outlined'
                              sx={{ fontFamily: "'Fredoka', sans-serif" }}
                            />
                          </Stack>
                        </div>
                        <div className='entries'>
                          No. of Entries:
                          <input
                            className='numberBox'
                            defaultValue={entries}
                            type='number'
                            onChange={(e) => setEntries(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                const message = {
                                  pageNo: page,
                                  limitPerPage:
                                    e.target.value > '0' ? e.target.value : 10,
                                };
                                handleNotifications(message);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        paddingBlock: '10%',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <img height='250px' src={emptyData} alt='empty data' style = {{maxWidth: "13%"}}/>
                        <div style={{ marginTop: '10%', fontSize: 'x-large' }}>
                          No Rules to display!
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            ) : (
              <>
                {tab === 1 ? (
                  notify && notify.length > 0 ? (
                    <div className='tableBox'>
                      {/* <div style={{ marginTop: '1%', width: '100%' }}>
                        <button
                          style={{
                            width: 'fit-content',
                            marginLeft: '96%',
                            backgroundColor: 'white',
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            handleNotificationRefresh({
                              pageNo: page,
                              limitPerPage: entries,
                            });
                          }}
                        >
                          <img
                            style={{ height: '3vh' }}
                            src={refresh_btn}
                            alt='refresh'
                          />
                        </button>
                      </div> */}
                      <TableContainer
                        component={Paper}
                        sx={{
                          height: 688,
                          boxShadow: '0px 20px 50px 0px #0000001A',
                          borderRadius: '8px 8px 0px 0px',
                          overflow: 'scroll',
                          overflowX: 'hidden',
                        }}
                      >
                        <Table stickyHeader>
                          {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                          {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}

                          <TableHead
                            sx={{ fontFamily: "'Fredoka', sans-serif" }}
                          >
                            <TableRow>
                              {/* change width or remove it in case on any alignment issues*/}
                              <TableCell
                                sx={{
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  fontWeight: 600,
                                  color: '#898F9A',
                                }}
                              >
                                Rule Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  fontWeight: 600,
                                  color: '#898F9A',
                                }}
                              >
                                Channels Found
                              </TableCell>
                              <TableCell
                                sx={{
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  fontWeight: 600,
                                  color: '#898F9A',
                                }}
                              >
                                Last Updated At
                              </TableCell>
                              <TableCell
                                sx={{
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  fontWeight: 600,
                                  color: '#898F9A',
                                }}
                              >
                                View Channels
                              </TableCell>
                              <TableCell
                                sx={{
                                  alignContent: 'center',
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  fontWeight: 600,
                                  color: '#898F9A',
                                }}
                              >
                                Export
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {console.log('table head ended: ', notify)}
                          <TableBody sx={{ height: 'max-content' }}>
                            {notify.map((row, index) => (
                              <TableRow
                                // key={row.name}
                                key={index}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                  background : row.dashboard_seen === false? "#EBF9FF": ""
                                  // background: index % 2 ? '#F5F7FB' : 'FFFFFF',
                                }}
                              >
                                <TableCell align='center'>
                                  <div
                                    className=''
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor: 'transparent',
                                      color: 'black',
                                      alignItems: 'center',
                                    }}
                                    onClick={(event) => {
                                      setTableSelectedId(row._id.$oid);
                                      handleNotificationClick(
                                        event,
                                        row._id.$oid
                                      );
                                    }}
                                  >
                                    {!row.dashboard_seen && (
                                      <img
                                        src={ActiveDot}
                                        alt='active'
                                        style={{ marginRight: '10px' }}
                                      />
                                    )}
                                    <span className='content_title'>
                                      {row.rule.name}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    alignContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  {console.log(row)}
                                  {size(row.matched_channel_ids)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    alignContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  {formatDate(row.updated_at.$date)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    alignContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <img
                                    alt='view'
                                    src={ViewEye}
                                    style={{
                                      margin: 'auto',
                                      cursor: 'pointer',
                                    }}
                                    onClick={(event) => {
                                      setTableSelectedId(row._id.$oid);
                                      setDiscoveredDate(row.updated_at.$date)
                                      handleNotificationClick(
                                        event,
                                        row._id.$oid
                                      );
                                      setShowRuleName(row.rule.name)
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  sx={{
                                    alignContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <img
                                    alt='view'
                                    src={Export}
                                    style={{
                                      margin: 'auto',
                                      cursor: 'pointer',
                                    }}
                                    onClick={async () => {
                                      setExportNotificationId(row._id.$oid)
                                      setExportNotification(!exportNotification)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div
                        className='pagination_container'
                        style={{ marginLeft: '0px' }}
                      >
                        <div className='nav_container'>
                          {console.log(page)}
                          <Stack spacing={2}>
                            <Pagination
                              shape='rounded'
                              count={pc}
                              color='primary'
                              defaultValue={page}
                              page={page}
                              onChange={handlePage}
                              variant='outlined'
                              sx={{ fontFamily: "'Fredoka', sans-serif" }}
                            />
                          </Stack>
                        </div>

                        <div className='entries'>
                          No. of Entries:
                          <input
                            className='numberBox'
                            defaultValue={entries}
                            type='number'
                            onChange={(e) => setEntries(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                const message = {
                                  pageNo: page,
                                  limitPerPage:
                                    e.target.value > '0' ? e.target.value : 10,
                                };
                                handleNotifications(message);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        paddingBlock: '10%',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={noData} alt='no data' />
                    </div>
                  )
                ) : (
                  <div className='searchEngine'>
                    <div
                      className='searchBar'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <input
                        type='text'
                        style={{
                          borderRadius: '8px 0 0 8px',
                          borderRight: 'none',
                          border: '3px solid #C4CDD5',
                          backgroundColor: '#F7F9FB',
                          minWidth: '50%',
                          height: '4vh',
                        }}
                        onChange={handleSearchChannelAdd}
                        defaultValue={searchChannel}
                        className='chanelInputBox'
                        placeholder='  Enter the channels username to search in telegram'
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleSearchClick();
                          }
                        }}
                      />
                      <button
                        style={{
                          minWidth: '7%',
                          width: 'fit-content',
                          height: '4vh',
                          fontSize: 'medium',
                          borderRadius: '0 8px 8px 0',
                          borderLeft: 'none',
                        }}
                        onClick={handleSearchClick}
                      >
                        Search
                      </button>
                      {showSearchResults ? (
                        <button
                          style={{
                            width: 'fit-content',
                            backgroundColor: 'white',
                          }}
                          onClick={handleSearchRefresh}
                        >
                         
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    {searchError ? (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={error_img} alt='error' />
                        <h1>We are working on it...</h1>
                      </div>
                    ) : (
                      <div style={{ marginTop: '2vh' }}>
                        {showSearchResults === true ? (
                          <div className='tableBox'>
                            {channels.length > 0 ? (
                              <TableContainer
                                component={Paper}
                                style={{height:'700px'}}
                                sx={{
                                  
                                  boxShadow: '0px 20px 50px 0px #0000001A',
                                  borderRadius: '8px 8px 0px 0px',
                                }}
                              >
                                <Table stickyHeader>
                                  {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                                  {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}

                                  <TableHead
                                    sx={{ fontFamily: "'Fredoka', sans-serif" }}
                                  >
                                    <TableRow>
                                      {/* change width or remove it in case on any alignment issues*/}
                                      <TableCell
                                        sx={{
                                          fontWeight: 'Channel bold',
                                          fontFamily: "'Fredoka', sans-serif",
                                          fontSize: '2vh',
                                          backgroundColor: '#F7F9FB',
                                          color: '#898F9A',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Channel Name
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontWeight: 'bold',
                                          fontFamily: "'Fredoka', sans-serif",
                                          fontSize: '2vh',
                                          backgroundColor: '#F7F9FB',
                                          color: '#898F9A',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Channel Username
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontWeight: 'bold',
                                          fontFamily: "'Fredoka', sans-serif",
                                          fontSize: '2vh',
                                          backgroundColor: '#F7F9FB',
                                          color: '#898F9A',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Participants Count
                                      </TableCell>
                                      
                                      <TableCell
                                        sx={{
                                          fontWeight: 'bold',
                                          fontFamily: "'Fredoka', sans-serif",
                                          fontSize: '2vh',
                                          backgroundColor: '#F7F9FB',
                                          color: '#898F9A',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Status
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontWeight: 'bold',
                                          fontFamily: "'Fredoka', sans-serif",
                                          fontSize: '2vh',
                                          backgroundColor: '#F7F9FB',
                                          color: '#898F9A',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Link
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {console.log(
                                    'searched live channels: ',
                                    channels
                                  )}
                                  <TableBody sx={{ height: 'max-content' }}>
                                    {channels.map((row, index) => (
                                      <TableRow
                                        // key={row.name}
                                        key={index}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                          background:
                                            index % 2 ? '#F5F7FB' : 'FFFFFF',
                                        }}
                                      >
                                        <TableCell align='center' width='20%'>
                                          {row.channel_title}
                                        </TableCell>
                                        <TableCell align='center'>
                                          {row.channel_username}
                                        </TableCell>
                                        <TableCell align='center'>
                                          {row.channel_participants_count}
                                        </TableCell>
                                     

                                        <TableCell
                                          align='center'
                                          sx={{
                                            fontFamily: "'Fredoka', sans-serif",
                                          }}
                                        >
                                          {row.job_object ? (
                                            <Button
                                              onClick={() =>
                                                showJoinConfirmation(row, index)
                                              }
                                              disabled={
                                                !row.job_object.attributes
                                                  .should_rerun
                                              }
                                              style={
                                                row.job_object.attributes
                                                  .should_rerun === true
                                                  ? {
                                                      border:
                                                        '1px solid #00ACFB',
                                                      width: '100%',
                                                    }
                                                  : {
                                                      border:
                                                        '1px solid #898F9A',
                                                      color: '#898F9A',
                                                      width: '100%',
                                                    }
                                              }
                                            >
                                              {' '}
                                              {row.job_object.state}
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                showJoinConfirmation(row, index)
                                              }
                                              disabled={row.is_joined}
                                              style={
                                                row.is_joined === false
                                                  ? {
                                                      border:
                                                        '1px solid #00ACFB',
                                                      width: '100%',
                                                    }
                                                  : {
                                                      border:
                                                        '1px solid #898F9A',
                                                      color: '#898F9A',
                                                      width: '100%',
                                                    }
                                              }
                                            >
                                              {' '}
                                              {row.jobState
                                                ? row.jobState
                                                : row.is_joined
                                                ? 'Joined'
                                                : 'Join'}
                                            </Button>
                                          )}
                                          {/* <Button onClick={() => showJoinConfirmation(row, index)} disabled={row.is_joined} style={row.is_joined === false ? { border: '1px solid #00ACFB', width: '100%' } : { border: '1px solid #898F9A', color: '#898F9A', width: '100%' }}> {row.jobState ? row.jobState : row.is_joined ? "Joined" : "Join"}</Button> */}
                                        </TableCell>
                                        <TableCell
                                          align='center'
                                          sx={{
                                            fontFamily: "'Fredoka', sans-serif",
                                          }}
                                        >
                                          <a
                                            href={`https://t.me/${row.channel_username}`}
                                            target='_blank'
                                            rel='noreferrer'
                                            title='Telegram Link'
                                            style={{ margin:'auto' }}
                                          >
                                            <img
                                              alt='telephone'
                                              className='actionsIMG'
                                              style={{ margin:'auto' }}
                                              src={teleIcon}
                                            />
                                          </a>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img src={noData} alt='no data' />
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

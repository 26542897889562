import axios from 'axios'


export const user_login = async(data)=>{
  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    
  };
 
  let res = []
console.log(data);
console.log('hiting endpoint user login');
    await axios.post(`${apiUrl}/internal/auth_user`, data, config)
    .then(response=>{
      console.log('hit with response : ', response);
        res = response;
        console.log(response)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }
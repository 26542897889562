import axios from 'axios';
import instance from './interceptor';

export const mark_key_notification_read = async (data) => {
  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders,
  };

  let notifcationIds = [];
  data.forEach((item) => {
    notifcationIds.push(item._id.$oid);
  });
  let res = [];
  await instance
    .post(
      `${apiUrl}/api/mark_key_notification_read`,
      { notification_ids: notifcationIds },
      config
    )
    .then((response) => {
      res = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  console.log(res);
  return res;
};

import axios from 'axios'
import instance from './interceptor';


export const export_specific_notification = async(notificationId,notes)=>{

  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
 
  console.log(notificationId,notes);
  let res = []
  await instance.post(`${apiUrl}/api/export_specific_notification`, { "monitor_notification_id": notificationId,"notes":notes}, config)
    .then(response=>{
        res = response.data;
        console.log(response.data)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';
import './css/dashright.css';
import './css/analytics.css';
import 'react-dropdown/style.css';
import './css/discover.css';
import './css/dashleft.css';
import './css/message_discover.css';
import './css/message_table.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/notification.css';
import './css/task_page.css';
import './css/settings.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './css/login.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(); comment

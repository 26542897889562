/*
  Packages
*/
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';

/*
  Api
*/
import { test_call } from '../endpoints/endpoint1';
import { tag_update } from '../endpoints/channels_tags_update';
import { blacklisting_channel } from '../endpoints/blacklisting_channel';
import { leave_channel } from '../endpoints/leave_channel';
import { export_channels } from '../endpoints/export_channels';
import { update_multiple_channels_tags } from '../endpoints/update_tags_for multiple_channels';
import { join_channel } from '../endpoints/channel_join';
import { channel_info } from '../endpoints/channel_info';
import { get_specific_channel } from '../endpoints/get_specific_channel';

/*
  Assets
*/
import eye from '../images/eye.svg';
import teleIcon from '../images/teleIcon.svg';
import sortIcon from '../images/sort.svg';
import noData from '../images/no-search-found.svg';
import loadingImg from '../images/loading.svg';
import Pinned from '../images/pinicon.svg';
import InstructionEye from '../images/instrucitoneye.svg';
import refresh_btn from '../images/refresh.svg';
import Filter from '../images/Filter.svg';
import { pin } from '../endpoints/pin';
import Greenpin from '../images/greenpin.svg';
import { unpin } from '../endpoints/unpin';
const style_pop = {
  width: 750,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  paddingInline: 8,
  paddingTop: 4,
  paddingBottom: 4,
  zIndex: 4,
};

export const Tables = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const channel_Id = searchParams.get('channel_Id');
  const [keywords, setKeywords] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [sample_rows, setSample_rows] = React.useState([]);
  const [pc, setPc] = React.useState(1);
  const [det, setDet] = React.useState(10);
  const [totCount, setTotCount] = React.useState(4000);
  //    const buttonRef = React.useRef(null);
  const [check, setCheck] = React.useState([]);
  const [fin_tags, setFin_tags] = React.useState([]);
  const [entries, setEntries] = React.useState(10);
  const [sortField, setSortField] = React.useState('');
  const d = moment();
  const defaultFromDate = moment()
    .subtract(122, 'years')
    .startOf('year');
  const initialFromDate = defaultFromDate.format('YYYY-MM-DD');
  const defaultToDate = moment()
    .add(77, 'years')
    .endOf('year');
  const initialToDate = defaultToDate.format('YYYY-MM-DD');
  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [fromDateCopy, setFromDateCopy] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);
  const [toDateCopy, setToDateCopy] = React.useState(initialToDate);
  const [dateFlag, setDateFlag] = React.useState(0);
  const [checkboxFilter, setCheckboxFilter] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [nameSort, setNameSort] = React.useState(0);
  const [dateSort, setDateSort] = React.useState(0);
  const [unreadSort, setunreadSort] = React.useState(0);
  const [blackListPop, setBlackListPop] = React.useState(false);
  const [showEditTagsPopup, setShowEditTagsPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [key, setKey] = React.useState();
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const [selectCount, setSelectCount] = React.useState(0);
  const [channelSelectedId, setChannelSelectedId] = React.useState();
  const [blacklisted, setBlacklisted] = React.useState();
  const [
    channelSelectedUserName,
    setChannelSelectedUserName,
  ] = React.useState();
  const [sortDirection, setSortDirection] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [edit, setEdit] = React.useState(false);
  const [selectAllPopup, setSelectAllPopup] = React.useState(false);
  const [selectedMessages, setSelectedMessages] = React.useState([]);
  const [showEditTagsPopup2, setShowEditTagsPopup2] = React.useState(false);
  const [exportedPop, setExportedPop] = React.useState(false);
  const [selectedQuery, setSelectedQuery] = React.useState();
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [dateSelectedBool, setDateSelectedBool] = React.useState(false);
  const [displayDate, setDisplayDate] = React.useState('Select Date Range');
  const [exportNote, setExportNote] = React.useState('');
  const [detailsRow, setDetailsRow] = React.useState();
  const [importMessagesPop, setImportMessagesPop] = React.useState(false);
  const [inputImportValue, setInputImportValue] = React.useState('');
  const [importErrorMessage, setImportErrorMessage] = React.useState('');
  const [messageImportData, setMessageImportData] = React.useState({});
  const [blackListPopUp, setBlackListPopUp] = React.useState(false);
  const [messageDetailsLoading, setMessageDetailsLoading] = React.useState(
    false
  );
  const [instructionPopUp, setinstructionPopUp] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [filterPopUp, setFilterPopUp] = React.useState(false);
  const [pinned, setpinned] = React.useState();
  const [noTag, setNoTag] = React.useState(false);

  //function to fetch the channel information displayed in the pop up.
  const handleMessageData = async () => {
    const message = {
      query: detailsRow.username ? detailsRow.username : detailsRow.channelId,
    };

    let response = {};
    response = await channel_info(message);
    console.log('response for your query', response);
    setMessageImportData(response.dbRes.channel_info);

    // console.log('response for your query', response)
    console.log('mentionsData : ', messageImportData);
    setInputImportValue(response.dbRes.channel_info.expected_messages_count);
    setBlackListPop(false);
    setMessageDetailsLoading(false);
    setImportMessagesPop(true);
  };

  //function to force_export_messages of a channel with a given count
  const handle_channel_join = async () => {
    console.log('also the limit of last messages : ', inputImportValue);
    let message = {
      entity_id: channelSelectedId,
      entity_user_name: channelSelectedUserName,
      import_all_messages: true,
      force_export: true,
      join_source_type: null,
      maximum_messages_count: inputImportValue,
    };
    let response = [];
    response = await join_channel(message);
    console.log('trying to force import all messages :', response);
    if (response.error.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! Job State: ' + response.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  //function to force_export_messages of a channel
  const handle_channel_join_all = async () => {
    let message = {
      entity_id: channelSelectedId,
      entity_user_name: channelSelectedUserName,
      import_all_messages: true,
      force_export: true,
      join_source_type: null,
    };
    let response = [];
    response = await join_channel(message);
    console.log('trying to force import all messages :', response);
    if (response.error.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! Job State: ' + response.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const [selectionRange, setSelectionRange] = React.useState({
    starDate: moment()
      .subtract(122, 'years')
      .startOf('year'),
    endDate: moment()
      .add(77, 'years')
      .endOf('year'),
    key: 'selection',
  });

  //function to select date from the calendar
  const handleSelect = (ranges) => {
    console.log(ranges.selection);
    setSelectionRange(ranges.selection);
    setDateSelectedBool(!dateSelectedBool);
    console.log('dateSelectedBool: ', dateSelectedBool);
  };

  //function to export channels
  const handleExport = async (event) => {
    event.preventDefault();
    setExportedPop(false);
    const arr = selectedMessages;
    setSelectedKeys([]);
    // const t= handleFinalKeys();
    let message = {};
    console.log(selectedQuery);
    if (!selectedQuery) {
      message = {
        entity_ids: arr,
        notes: exportNote,
      };
    } else {
      message = {
        query: selectedQuery,
        notes: exportNote,
      };
    }
    setSelectedQuery();
    setSelectedMessages([]);
    setExportNote('');

    console.log('sending request for exporting: ', message);
    let r;
    r = await export_channels(message);
    if (r.error.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! job_id: ' + r.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  //function to handle checked or unchecked state of checkboxes
  const handleCheckboxChange = (event, row) => {
    const { checked } = event.target;
    const channelId = row.channelId;

    if (checked) {
      // Add the messageId to the selectedMessages array
      setSelectedMessages([...selectedMessages, channelId]);
    } else {
      // Remove the messageId from the selectedMessages array
      setSelectedMessages(selectedMessages.filter((id) => id !== channelId));
    }

    console.log('selectedMessages', selectedMessages);

    setEdit(true);
  };

  const handleAddKeysMultiple = (event, val) => {
    event.preventDefault();
    if (selectedKeys.indexOf(val.trim()) === -1) {
      setSelectedKeys([...selectedKeys, val.trim()]);
      setSelectCount(selectCount + 1);
    }
    setKey('');
  };

  //function to update tags for multiple selected channels
  const handleUpdateTagsMultiple = async (event) => {
    event.preventDefault();
    const tags = selectedKeys;
    // console.log(selectedRow)
    // const row = selectedRow
    setSelectedKeys([]);
    setKey();
    setSelectedRow(null);
    setShowEditTagsPopup2(false);
    const t = handleFinalKeys();

    console.log('calling hadleUpdateTagsMultiple function');

    event.preventDefault();
    setExportedPop(false);
    const arr = selectedMessages;
    setSelectedKeys([]);
    // const t= handleFinalKeys();
    let message = {};
    console.log(selectedQuery);
    if (!selectedQuery) {
      message = {
        entity_ids: arr,
        tags: tags,
      };
    } else {
      message = {
        query: selectedQuery,
        tags: tags,
      };
    }
    setSelectedQuery();
    setSelectedMessages([]);

    console.log('sending request for updating: ', message);
    let r;
    r = await update_multiple_channels_tags(message);
    if (r.error.length > 0) {
      toast.error('Oops! something went wrong.');
    } else {
      toast.success('Requested! job_id: ' + r.dbRes.job_id, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return dateObject.toLocaleString('en-US', options);
  };

  //function to select manually selected channels
  const handleSelectCurrent = (event) => {
    setSelectedMessages([]);
    // Loop over the sample_rows array
    for (let i = 0; i < sample_rows.length; i++) {
      // Add the ref_id to the selectedMessages array
      setSelectedMessages((prevSelectedMessages) => [
        ...prevSelectedMessages,
        sample_rows[i].channelId,
      ]);
    }

    console.log('selectedMessages: ', selectedMessages);
  };

  //function to select all channels of a particular query
  const handleSelectThroughAll = () => {
    const t = handleFinalKeys();
    setSelectedMessages([]);
    setSelectedQuery({
      searchText: t.join(' '),
      sortField: sortField,
      sortDirection: sortDirection,
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  const handleKeyAdd = (event) => {
    event.preventDefault();
    // setToggleAddKeys(!showEditTagsPopup);
  };

  //function to update tag of a single channel
  const handleUpdateTags = (event) => {
    event.preventDefault();
    const tags = selectedKeys;
    console.log(selectedRow);
    const row = selectedRow;
    setSelectedKeys([]);
    setKey();
    setSelectedRow(null);
    setShowEditTagsPopup(false);
    const t = handleFinalKeys();

    const message = {
      tags: tags,
      entity_id: row.channelId,
    };
    console.log('calling call function');
    call(message);

    // const m = {
    // 	"pageNo": page,
    // 	"limitPerPage": entries,
    // 	"searchText": t.join(' '),
    // 	"sortField": sortField,
    // 	"sortDirection": sortDirection,
    // 	// "flag": dateFlag,
    // 	"fromDate": fromDate,
    // 	"toDate": toDate
    // }

    // console.log('message sent to test_ c function after updating tag: ', message);
    // test_c(m);
  };

  //function to render after updating tags of a channel
  const call = async (message) => {
    console.log('in call func');
    let response = {};

    response = await tag_update(message);
    //window.location.reload();
    let chan = response.dbRes.data;
    // const { chan, pCount, tot } = response;
    const t = handleFinalKeys();

    const m = {
      pageNo: page,
      limitPerPage: entries,
      searchText: t.join(' '),
      sortField: sortField,
      sortDirection: sortDirection,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
    };

    console.log(
      'message sent to test_ c function after updating tag: ',
      message
    );
    test_c(m);
  };

  //function to whitelist or blacklist a channel
  const handleBlackList = async () => {
    if (blacklisted === 'WHITELISTED') {
      let message = {
        entity_id: channelSelectedId,
        concern_type: 'BLACKLISTED',
      };

      console.log(message);
      let response = {};
      response = await blacklisting_channel(message);
      //window.location.reload();
      let chan = response.dbRes.data;
      console.log(chan);

      const t = handleFinalKeys();
      const m = {
        pageNo: page,
        limitPerPage: entries,
        searchText: t.join(' '),
        // "flag": dateFlag,
        sortField: sortField,
        sortDirection: sortDirection,
        fromDate: fromDate,
        toDate: toDate,
      };
      console.log(
        'message sent to test_ c function after handling blacklisting ',
        m
      );
      test_c(m);
    } else {
      let message = {
        entity_id: channelSelectedId,
        concern_type: 'WHITELISTED',
      };

      console.log(message);
      let response = {};
      response = await blacklisting_channel(message);
      //window.location.reload();
      let chan = response.dbRes.data;
      console.log(chan);

      const t = handleFinalKeys();
      const m = {
        pageNo: page,
        limitPerPage: entries,
        searchText: t.join(' '),
        // "flag": dateFlag,
        sortField: sortField,
        sortDirection: sortDirection,
        fromDate: fromDate,
        toDate: toDate,
      };
      console.log(
        'message sent to test_ c function after handling whitelisting: ',
        m
      );
      test_c(m);
    }
  };

  //function to leace a channel
  const handleLeave = async () => {
    let message = {
      entity_id: channelSelectedId,
      entity_user_name: channelSelectedUserName
        ? channelSelectedUserName
        : null,
    };
    console.log(message);
    let response = {};

    response = await leave_channel(message);
    //window.location.reload();
    let chan = response;
    console.log(chan);

    const t = handleFinalKeys();
    const m = {
      pageNo: page,
      limitPerPage: entries,
      searchText: t.join(' '),
      // "flag": dateFlag,
      sortField: sortField,
      sortDirection: sortDirection,
      fromDate: fromDate,
      toDate: toDate,
    };
    console.log('message sent to test_ c function after leaving: ', m);
    test_c(m);
  };

  const handleChange = (event) => {
    setKey(event.target.value);
    console.log(event.target.value);
  };

  const handleExportChange = (event) => {
    setExportNote(event.target.value);
    console.log(event.target.value);
  };

  const handleAddKeys = (event, val) => {
    event.preventDefault();
    if (selectedKeys.indexOf(val.trim()) === -1) {
      setSelectedKeys([...selectedKeys, val.trim()]);
      setSelectCount(selectCount + 1);
    }
    setKey('');
  };

  const handleRemoveKeys = (event, val) => {
    event.preventDefault();
    console.log(val);
    setSelectedKeys(
      selectedKeys.filter((selectedKeys) => selectedKeys !== val.key)
    );
    setSelectCount(selectCount - 1);
  };

  const style = {
    width: 750,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingInline: 8,
    paddingTop: 4,
    paddingBottom: 4,
    zIndex: 3,
  };

  //function to handleSort based on channel name
  const handleNameSortChange = () => {
    let sort = 0;
    if (nameSort === 0) {
      setNameSort(1);
      sort = 1;
    } else if (nameSort === 1) {
      setNameSort(-1);
      sort = -1;
    } else {
      setNameSort(1);
      sort = 1;
    }
    setPage(1);
    setSortField('clean_text_name');
    setSortDirection(sort);
    console.log(sort);

    const t = handleFinalKeys();

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: t.join(' '),
      sortField: 'clean_text_name',
      sortDirection: sort,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
    };
    setLoading(true);
    test_c(message);
  };

  //function to sort based on unread count
  const handleUnreadSortChange = () => {
    let sort = 0;
    if (unreadSort === 0) {
      setunreadSort(1);
      sort = 1;
    } else if (unreadSort === 1) {
      setunreadSort(-1);
      sort = -1;
    } else {
      setunreadSort(1);
      sort = 1;
    }
    setSortField('unread_messages_count');
    setSortDirection(sort);
    setPage(1);
    console.log(sort);
    const t = handleFinalKeys();

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: t.join(' '),
      sortField: 'unread_messages',
      sortDirection: sort,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
    };
    setLoading(true);
    test_c(message);
  };

  //function to sort based on date of update
  const handleDateSortChange = () => {
    let sort = 0;
    if (dateSort === 0) {
      setDateSort(1);
      sort = 1;
    } else if (dateSort === 1) {
      setDateSort(-1);
      sort = -1;
    } else {
      setDateSort(1);
      sort = 1;
    }

    setSortDirection(sort);
    setSortField('updated_at');
    setPage(1);
    console.log(sort);
    const t = handleFinalKeys();

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: t.join(' '),
      sortField: 'updated_at',
      sortDirection: sort,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
    };
    setLoading(true);
    test_c(message);
  };

  const [tags, setTags] = React.useState([]);

  const handleSelectChange = (selectedOptions) => {
    setPage(1);
    setSelectedOptions(selectedOptions);

    console.log(selectedOptions);

    let arr = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    setKeywords([...arr, keywords]);

    setSortDirection(0);
    setSortField('');

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: arr.join(' '),
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
    };
    setLoading(true);
    test_c(message);
  };

  const handleInputChange = (inputValue) => {
    // Remove consecutive whitespace characters within the input value
    inputValue = inputValue.replace(/\s+/g, ' ');

    // Check if the input value is an exact match with an existing tag value
    const isDuplicate = tags.some((tag) => tag.value === inputValue);

    // Add the new option to the tags array if it is not an empty string or a duplicate
    if (inputValue.trim() !== '' && !isDuplicate) {
      const newTag = { value: inputValue.trim(), label: inputValue.trim() };
      setTags([newTag]);
    }
  };

  const handleImportInputChange = (e) => {
    const value = e.target.value;
    if (
      value === '' ||
      (Number(value) >= 0 &&
        Number(value) <= messageImportData.expected_messages_count)
    ) {
      setInputImportValue(value);
      setImportErrorMessage(''); // Clear the error message if the input is valid
    } else {
      setImportErrorMessage(
        `Number must be between 0 and ${messageImportData.expected_messages_count}`
      );
    }
  };

  const handleFromDate = (event) => {
    // event.preventDefault()
    console.log(event.target.value);
    if (event.target.value === '') {
      setFromDate(initialFromDate);
    } else setFromDate(event.target.value);
  };

  const handleToDate = (event) => {
    console.log(event.target.value);
    if (event.target.value === '') {
      setToDate(initialToDate);
    } else setToDate(event.target.value);
  };

  const handleCheck = (event) => {
    const v = event.target.value;
    if (event.target.checked) {
      if (check.indexOf(v) < 0) {
        const arr = check;
        arr.push(v);
        setCheck(arr);
      }
    } else {
      if (check.indexOf(v) >= 0) {
        const arr = check.filter((a) => a !== v);
        setCheck(arr);
      }
    }
    console.log(check);
  };

  // const handleFin_tags = () => {

  // 	const mergedArray = check.concat(keywords).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
  // 	console.log('merged arr :::::', mergedArray)
  // 	setFin_tags(mergedArray);
  // 	console.log('set_fin_tags : ', fin_tags);
  // 	return mergedArray;
  // }

  //function to put the react select selected options into an array
  const handleFinalKeys = () => {
    let arr = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      arr.push(selectedOptions[i].value);
    }

    setKeywords([...arr, keywords]);
    return arr;
  };

  //function to create data as to be displayed in the table
  function createData(
    article,
    keyword,
    name,
    date,
    id,
    _id,
    username,
    unread_messages,
    channelId,
    blackList,
    messageCount,
    nameOriginal,
    fake,
    participantCount,
    scam,
    verified,
    isChannel,
    isGroup,
    isUser,
    is_pinned,
    is_banned_or_removed,
    clients,
    channel_active_check_obj
  ) {
    const lastActive = date.$date;
    return {
      article,
      keyword,
      name,
      lastActive,
      id,
      _id,
      username,
      unread_messages,
      channelId,
      blackList,
      messageCount,
      nameOriginal,
      fake,
      participantCount,
      scam,
      verified,
      isChannel,
      isGroup,
      isUser,
      is_pinned,
      is_banned_or_removed,
      clients,
      channel_active_check_obj,
    };
  }

  const handleKeywords = (event, value) => {
    setKeywords(value);
  };

  //function handling pagination page change
  const handlePage = (event, value) => {
    setPage(value);
    console.log(fromDate);
    const t = handleFinalKeys();

    const message = {
      pageNo: value,
      limitPerPage: entries,
      searchText: t.join(' '),
      sortField: sortField,
      sortDirection: sortDirection,
      // "flag": dateFlag,
      fromDate: fromDate,
      toDate: toDate,
      no_tag: noTag,
    };

    test_c(message);
  };

  const handleDets = (event, value) => {
    console.log(value);
  };

  const handleClearDate = () => {
    setDateFlag(0);
    setFromDate(initialFromDate);
    setToDate(initialToDate);
    setDisplayDate('Select Date Range');
    setSelectionRange({
      starDate: moment()
        .subtract(122, 'years')
        .startOf('year'),
      endDate: moment()
        .add(77, 'years')
        .endOf('year'),
      key: 'selection',
    });

    // handleSelect();

    const t = handleFinalKeys();
    const message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: t.join(' '),
      fromDate: initialFromDate,
      toDate: initialToDate,
      sortField: sortField,
      sortDirection: sortDirection,
      no_tag: noTag,
    };
    setLoading(true);
    test_c(message);
  };

  //function to render page update
  const finalSubmit = () => {
    setPage(1);
    const t = handleFinalKeys();
    //const t = handleFin_tags();
    console.log(selectionRange);
    let tempFrom = '';
    if (selectionRange.hasOwnProperty('startDate')) {
      tempFrom = selectionRange.startDate;
    } else {
      tempFrom = selectionRange.starDate;
    }

    setFromDate(moment(tempFrom).format('YYYY-MM-DD'));
    setToDate(moment(selectionRange.endDate).format('YYYY-MM-DD'));

    const message = {
      pageNo: 1,
      limitPerPage: entries,
      searchText: t.join(' '),
      sortField: sortField,
      sortDirection: sortDirection,
      // "flag": dateFlag,
      fromDate: moment(tempFrom).format('YYYY-MM-DD'),
      toDate: moment(selectionRange.endDate).format('YYYY-MM-DD'),
      no_tag: noTag,
    };

    test_c(message);
  };

  const get_particular_channel = async (message) => {
    console.log(
      'here your message reached from your updated function: ',
      message
    );
    let response = {};
    response = await get_specific_channel(message);
    let chan = response.dbRes;
    // const { chan, pCount, tot } = response;

    console.log('here is the response you got for this time: ', response);
    setPc(Math.ceil(1 / entries));
    console.log(
      'updated response.totalcount/entries, entries , updated pc: ',
      1,
      entries,
      pc
    );
    setTotCount(1);
    setDet(entries);

    let test_rows = [];
    // for (let i = 0; i < chan.length; i++) {
    //   var st = '';

    test_rows.push(
      createData(
        chan.entity_description,
        chan.tags ? chan.tags : [],
        chan.clean_text_name,
        chan.created_at,
        chan.link,
        chan._id,
        chan.entity_user_name,
        chan.unread_messages,
        chan.entity_id,
        chan.concern_type,
        chan.entity_messages_count,
        chan.entity_name,
        chan.entity_attributes.entity_fake,
        chan.entity_attributes.entity_participants_count,
        chan.entity_attributes.entity_scam,
        chan.entity_verified,
        chan.is_channel,
        chan.is_group,
        chan.is_user,
        chan.is_pinned,
        chan.is_banned_or_removed,
        chan.clients,
        chan.channel_active_check_obj
      )
    );
    // }
    setSample_rows(test_rows);
    setLoading(false);
  };

  const test_c_temp = async (a, message) => {
    console.log(
      'here your message reached from your updated function: ',
      message
    );
    let response = {};
    response = await test_call(message);
    let chan = response.dbRes.data;
    // const { chan, pCount, tot } = response;

    console.log('here is the response you got for this time: ', response);
    setPc(Math.ceil(response.dbRes.totalCount / a));
    console.log(
      'updated response.totalcount/entries, entries , updated pc: ',
      response.dbRes.totalCount,
      entries,
      pc
    );
    setTotCount(response.dbRes.totalCount);
    setDet(entries);

    let test_rows = [];
    for (let i = 0; i < chan.length; i++) {
      var st = '';

      test_rows.push(
        createData(
          chan[i].entity_description,
          chan[i].tags ? chan[i].tags : [],
          chan[i].clean_text_name,
          chan[i].created_at,
          chan[i].link,
          chan[i]._id,
          chan[i].entity_user_name,
          chan[i].unread_messages,
          chan[i].entity_id,
          chan[i].concern_type,
          chan[i].entity_messages_count,
          chan[i].entity_name,
          chan[i].entity_attributes.entity_fake,
          chan[i].entity_attributes.entity_participants_count,
          chan[i].entity_attributes.entity_scam,
          chan[i].entity_verified,
          chan[i].is_channel,
          chan[i].is_group,
          chan[i].is_user,
          chan[i].is_pinned,
          chan[i].is_banned_or_removed,
          chan[i].clients,
          chan[i].channel_active_check_obj
        )
      );
    }
    setSample_rows(test_rows);
    setLoading(false);
  };

  //function to fetch channels details
  const test_c = async (message) => {
    console.log(
      'here your message reached from your updated function: ',
      message
    );
    let response = {};
    response = await test_call(message);
    let chan = response.dbRes.data;
    // const { chan, pCount, tot } = response;

    console.log('here is the response you got for this time: ', response);
    setPc(Math.ceil(response.dbRes.totalCount / entries));
    console.log(
      'updated response.totalcount/entries, entries , updated pc: ',
      response.dbRes.totalCount,
      entries,
      pc
    );
    setTotCount(response.dbRes.totalCount);
    setDet(entries);

    let test_rows = [];
    for (let i = 0; i < chan.length; i++) {
      var st = '';

      test_rows.push(
        createData(
          chan[i].entity_description,
          chan[i].tags ? chan[i].tags : [],
          chan[i].clean_text_name,
          chan[i].created_at,
          chan[i].link,
          chan[i]._id,
          chan[i].entity_user_name,
          chan[i].unread_messages,
          chan[i].entity_id,
          chan[i].concern_type,
          chan[i].entity_messages_count,
          chan[i].entity_name,
          chan[i].entity_attributes.entity_fake,
          chan[i].entity_attributes.entity_participants_count,
          chan[i].entity_attributes.entity_scam,
          chan[i].entity_verified,
          chan[i].is_channel,
          chan[i].is_group,
          chan[i].is_user,
          chan[i].is_pinned,
          chan[i].is_banned_or_removed,
          chan[i].clients,
          chan[i].channel_active_check_obj
        )
      );
    }
    setSample_rows(test_rows);
    setLoading(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      console.log(event);
      setShowCalendar(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    var message = { pageNo: 1, limitPerPage: 10 };

    if (channel_Id) {
      // setSelectedOptions({ value: channel_Id, label: channel_Id });
      // message = { pageNo: 1, limitPerPage: 10, searchText: channel_Id };
      let message = { entity_id: channel_Id };
      get_particular_channel(message);
    } else {
      // console.log("dskjhfkjdhfn")
      test_c(message);
    }
  }, []);

  const setTagValue = (value) => {
    console.log(typeof value, value);
    if (value === 'false') {
      setNoTag(false);
    } else {
      setNoTag(true);
    }
  };

  if (!Cookies.get('token')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <div
        className='dashright'
        style={{
          paddingTop: '2%',
          overflow: 'hidden',
          position: 'fixed',
          width: '100%',
          paddingLeft: '15%',
        }}
      >
        <div className='main-area'>
          <div
            className='head'
            style={{ minHeight: '10%', maxHeight: '10%', marginBottom: '20px' }}
          >
            <div className='left-head'>
              {/* <div> */}
              <div className='Head1_jobs'>Channel Gallery</div>
              <Select
                className='entryBox'
                isMulti
                options={tags}
                value={selectedOptions}
                onChange={handleSelectChange}
                placeholder='Search for channel name, username, description'
                isSearchable
                hideSelectedOptions={true}
                onInputChange={handleInputChange}
              />
            </div>

            <div className='right-head'>
              <div
                style={{
                  cursor: 'pointer',
                  width: '20%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                onClick={() => {
                  setFilterPopUp(!filterPopUp);
                }}
              >
                <img src={Filter} alt='Filter' style={{ height: '6vh' }} />
              </div>
              <div className='' style={{ display: 'flex' }}>
                <button
                  style={{ minWidth: 'max-content' }}
                  className='selectAllFilters'
                  onClick={toggleCalendar}
                >
                  {!showCalendar ? displayDate : 'Close Calendar'}
                </button>
                {showCalendar ? (
                  <div
                    style={{
                      position: 'absolute',
                      marginTop: '3%',
                      zIndex: '99',
                      marginLeft: '-5%',
                      border: '1px solid #919EAB',
                      borderRadius: '8px',
                      backgroundColor: 'white',
                      paddingBlockEnd: '1%',
                      height: 'fit-content',
                    }}
                    ref={dropdownRef}
                  >
                    <DateRangePicker
                      showDropdowns={false}
                      className='datepicker_dropdown'
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '2%',
                      }}
                    >
                      <button
                        className='filterSearch'
                        onClick={() => {
                          setDisplayDate(
                            `${moment(selectionRange.startDate).format(
                              'DD-MM-YYYY'
                            )}` +
                              ' - ' +
                              `${moment(selectionRange.endDate).format(
                                'DD-MM-YYYY'
                              )}`
                          );
                          setShowCalendar(!showCalendar);
                          finalSubmit();
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className='filterSearch'
                        style={{
                          minWidth: 'fit-content',
                          height: 'fit-content',
                        }}
                        onClick={() => {
                          setShowCalendar(!showCalendar);
                          handleClearDate();
                        }}
                      >
                        Clear Date
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* </div> */}
              {/* </div>					 */}
              {/* <div className='filterButtonDiv' style={{zIndex: '3'}}>
										
									
								</div> */}
              <button
                style={{ minWidth: 'max-content' }}
                className='selectAllFilters'
                onClick={() => {
                  if (edit === true) {
                    setSelectedMessages([]);
                    setSelectedQuery();
                  }
                  setEdit(!edit);
                }}
              >
                {edit ? 'Deselect and Close' : 'Select Channels'}
              </button>
            </div>
          </div>
          <div
            style={{
              cursor: 'pointer',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            onClick={() => {
              const t = handleFinalKeys();
              setPage(1);
              const message = {
                pageNo: page,
                limitPerPage: entries,
                searchText: t.join(' '),
                fromDate: fromDate,
                toDate: toDate,
                no_tag: noTag,
              };
              setLoading(true);
              test_c_temp(page, message);
            }}
          >
            <img src={refresh_btn} style={{ height: '3vh' }} />
          </div>
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ height: '8vh', marginTop: '30vh' }}
                src={loadingImg}
                alt='loading'
              />
            </div>
          ) : sample_rows.length > 0 ? (
            <div className='table-area'>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '0%',
                  alignItems: 'baseline',
                }}
              >
                {edit ? (
                  <div
                    style={{ display: 'flex', gap: '1.5%', marginTop: '-1%' }}
                  >
                    {channel_Id == null ? (
                      <button
                        style={{ minWidth: 'max-content', height: 'inherit' }}
                        className='selectAllFilters'
                        onClick={() => {
                          setSelectAllPopup(true);
                        }}
                      >
                        Select All
                      </button>
                    ) : (
                      <></>
                    )}
                    {selectedMessages.length > 0 || selectedQuery ? (
                      <div
                        style={{
                          display: 'flex',
                          gap: '2%',
                          height: 'inherit',
                        }}
                      >
                        {channel_Id == null ? (
                          <button
                            style={{
                              minWidth: 'max-content',
                              height: 'inherit',
                            }}
                            className='selectAllFilters'
                            onClick={() => {
                              setShowEditTagsPopup2(true);
                              setSelectedKeys([]);
                            }}
                          >
                            Add Tags
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          onClick={(event) => {
                            setExportedPop(true);
                          }}
                          className='export_button'
                          style={{ maxWidth: 'fit-content', height: 'inherit' }}
                        >
                          Export
                        </button>
                        <div
                          style={{
                            minWidth: 'max-content',
                            alignSelf: 'center',
                            marginLeft: '1%',
                            fontSize: 'medium',
                            color: '#B7B7B7',
                          }}
                        >
                          Selected Channels(
                          {selectedMessages.length > 0
                            ? selectedMessages.length
                            : totCount}
                          )
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {/* <div className="display_pages" >
							Showing &nbsp;<span>{(page - 1) * det + 1}</span>-<span>{(page - 1) * det + parseInt(entries) < totCount ? (page - 1) * det + parseInt(entries): totCount }</span>&nbsp;from&nbsp;<span>{totCount}</span>&nbsp;data
						</div> */}
              </div>
              <div className='table_container' key={Math.random()}>
                {/* <TableContainer component={Paper} sx={{ height: 320  }}> FOR SCROLL THING*/}
                <TableContainer
                  component={Paper}
                  sx={{
                    height: '75vh',
                    boxShadow: '0px 20px 50px 0px #0000001A',
                    borderRadius: '8px 8px 0px 0px',
                  }}
                  className='scrollbar'
                >
                  {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
                  {/* <Table stickyHeader size="small" aria-label="a dense table"> */}
                  <Table stickyHeader>
                    {/* <Table sx={{ height: "max-content" }} size="small" aria-label="a dense table"> */}
                    {/* <TableHead sx = {{ fontFamily: "'Fredoka', sans-serif", position : "sticky", top: '0', background: 'white' }}> */}
                    <TableHead sx={{ fontFamily: "'Fredoka', sans-serif" }}>
                      <TableRow sx={{ height: '1vh' }}>
                        {edit === true ? (
                          <TableCell
                            sx={{
                              color: '#313131',
                              fontWeight: 'bold',
                              fontFamily: "'Fredoka', sans-serif",
                              fontSize: '2vh',
                              backgroundColor: '#F7F9FB',
                              textAlign: 'left',
                              width: '1%',
                            }}
                            align='left'
                          ></TableCell>
                        ) : (
                          <></>
                        )}
                        <TableCell
                          sx={{
                            color: '#313131',
                            fontWeight: 'bold',
                            fontFamily: "'Fredoka', sans-serif",
                            fontSize: '2vh',
                            backgroundColor: '#F7F9FB',
                            textAlign: 'left',
                            width: '15%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={handleNameSortChange}
                          >
                            <div>Name</div>
                            <img
                              className='tableHeadSVG'
                              src={sortIcon}
                              alt='sort'
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: '#313131',
                            fontWeight: 'bold',
                            fontFamily: "'Fredoka', sans-serif",
                            fontSize: '2vh',
                            backgroundColor: '#F7F9FB',
                            textAlign: 'left',
                            width: '10%',
                          }}
                          align='left'
                        >
                          Tags
                        </TableCell>
                        <TableCell
                          sx={{
                            color: '#313131',
                            fontWeight: 'bold',
                            fontFamily: "'Fredoka', sans-serif",
                            fontSize: '2vh',
                            backgroundColor: '#F7F9FB',
                            textAlign: 'left',
                            width: '40%',
                          }}
                          align='left'
                        >
                          Channel Description
                        </TableCell>
                        <TableCell
                          sx={{
                            color: '#313131',
                            fontWeight: 'bold',
                            fontFamily: "'Fredoka', sans-serif",
                            fontSize: '2vh',
                            backgroundColor: '#F7F9FB',
                            textAlign: 'left',
                            width: '10%',
                          }}
                          align='left'
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={handleUnreadSortChange}
                          >
                            <div>Unread/Total</div>
                            <img
                              className='tableHeadSVG'
                              src={sortIcon}
                              alt='sort'
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: '#313131',
                            fontWeight: 'bold',
                            fontFamily: "'Fredoka', sans-serif",
                            fontSize: '2vh',
                            backgroundColor: '#F7F9FB',
                            textAlign: 'left',
                            width: '15%',
                          }}
                          align='left'
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={handleDateSortChange}
                          >
                            <div>Added At</div>
                            <img
                              className='tableHeadSVG'
                              src={sortIcon}
                              alt='sort'
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: '#313131',
                            fontWeight: 'bold',
                            fontFamily: "'Fredoka', sans-serif",
                            fontSize: '2vh',
                            backgroundColor: '#F7F9FB',
                            textAlign: 'left',
                            width: '10%',
                          }}
                          align='left'
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ width: '100%' }}>
                      {sample_rows.map((row, index) => (
                        <TableRow
                          style={{ zIndex: 1 }}
                          // key={row.name}
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            background: index % 2 ? '#F5F7FB' : 'FFFFFF',
                            height: '2vh',
                          }}
                        >
                          {edit === true ? (
                            <TableCell
                              align='left'
                              sx={{ textAlign: 'center', width: '1%' }}
                              onClick={(event) => {
                                event.preventDefault();
                                setChannelSelectedId(row.channelId);
                                setBlacklisted(row.blackList);
                                setChannelSelectedUserName(row.username);
                                setDetailsRow(row);
                                setpinned(row.is_pinned);
                                setInputImportValue('');
                                setImportErrorMessage('');
                                console.log('row from table: ', row);
                                // setBlackListPop(true);
                              }}
                            >
                              <div>
                                <input
                                  type={'checkbox'}
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                  }}
                                  checked={
                                    selectedMessages.includes(row.channelId) ||
                                    selectedQuery
                                      ? true
                                      : false
                                  }
                                  onChange={(event) =>
                                    handleCheckboxChange(event, row)
                                  }
                                />
                              </div>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          <TableCell
                            align='left'
                            sx={{
                              fontFamily: "'Fredoka', sans-serif",
                              fontWeight: 'bold',
                              fontSize: 'medium',
                              textAlign: 'start',
                              width: '20%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setChannelSelectedId(row.channelId);
                              setBlacklisted(row.blackList);
                              setChannelSelectedUserName(row.username);
                              setDetailsRow(row);
                              setInputImportValue('');
                              setImportErrorMessage('');
                              console.log('row from table: ', row);
                              setBlackListPop(true);
                            }}
                          >
                            <Tooltip title={row.username}>
                              <Link
                                style={{ fontFamily: 'Roboto' }}
                                className='channel_link'
                                to={`/messages?channelId=${row.channelId}`}
                              >
                                <div
                                  style={{
                                    // whiteSpace: "nowrap",
                                    textOverflow: 'ellipsis',
                                    width: '300px',
                                    display: 'flex',
                                    overflow: 'hidden',
                                    alignItems: 'center',
                                  }}
                                >
                                  {row?.is_pinned ? (
                                    <img
                                      src={Pinned}
                                      style={{ margin: '0px 10px' }}
                                      onClick={async () => {
                                        let payload = [];
                                        payload.push(row._id.$oid);
                                        const response = await pin(payload);
                                        console.log(response);
                                      }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  {row.name.trim() === ''
                                    ? row.nameOriginal
                                    : row.name}
                                </div>
                                {row?.is_banned_or_removed === true ? (
                                  <div style={{ color: 'red' }}>
                                    <span>(BANNED / REMOVED)</span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Link>
                            </Tooltip>
                            {row?.channel_active_check_obj ? (
                              <div
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  background: '#00ff00',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                }}
                                title={
                                  'last checked at ' +
                                  formatDate(
                                    row.channel_active_check_obj.checked_at
                                      .$date
                                  )
                                }
                              ></div>
                            ) : (
                              <></>
                            )}
                          </TableCell>

                          {/* <TableCell align="center" sx={{ fontFamily: "'Fredoka', sans-serif" }}>
												{row.keyword.length >= 1 ? (
													<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center"
													}}
													>
													<div className="tags">
														{row.keyword[0]}
													</div>
													<button
														onClick={() => {
														console.log("sent");
														setSelectedRow(row);
														setShowEditTagsPopup(true);
														setSelectedKeys(row.keyword);
														}}
														style={{
														width: "fit-content",
														fontSize: "small",
														height: "min-content",
														fontWeight: "bolder",
														borderRadius: "20px",
														backgroundColor: "white",
														color: "#00ACFB",
														border: "1px solid #00ACFB",
														}}
													>
														+
													</button>
													</div>
												) : (
													<>
													<button
														onClick={() => {
														console.log("sent");
														setSelectedRow(row);
														setShowEditTagsPopup(true);
														setSelectedKeys(row.keyword);
														}}
														style={{
														width: "fit-content",
														fontSize: "small",
														height: "min-content",
														fontWeight: "bolder",
														borderRadius: "20px",
														backgroundColor: "white",
														color: "#00ACFB",
														border: "1px solid #00ACFB",
														}}
													>
														+
													</button>
													</>
												)}
												</TableCell> */}

                          <TableCell
                            align='center'
                            sx={{
                              fontFamily: "'Fredoka', sans-serif",
                              width: '10%',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setChannelSelectedId(row.channelId);
                              setBlacklisted(row.blackList);
                              setChannelSelectedUserName(row.username);
                              setDetailsRow(row);
                              setInputImportValue('');
                              setImportErrorMessage('');
                              console.log('row from table: ', row);
                            }}
                          >
                            {row.keyword && row.keyword.length >= 1 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '4px',
                                }}
                              >
                                <div
                                  className='tags'
                                  title={row.keyword.join('\n')}
                                  style={{ position: 'relative' }}
                                >
                                  {row.keyword[0]}
                                  <span className='tooltip'>
                                    {row.keyword.join('\n')}
                                  </span>
                                </div>
                                {row.keyword.length > 1 ? '...' : ''}
                                <button
                                  onClick={() => {
                                    console.log('sent');
                                    setSelectedRow(row);
                                    setShowEditTagsPopup(true);
                                    setSelectedKeys(row.keyword);
                                  }}
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    fontSize: 'small',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    fontWeight: 'bolder',
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    color: '#00ACFB',
                                    border: '1px solid #00ACFB',
                                    padding: '1%',
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    console.log('sent');
                                    setSelectedRow(row);
                                    setShowEditTagsPopup(true);
                                    setSelectedKeys(row.keyword);
                                  }}
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    fontSize: 'small',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    fontWeight: 'bolder',
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    color: '#00ACFB',
                                    border: '1px solid #00ACFB',
                                    padding: '1%',
                                  }}
                                >
                                  +
                                </button>
                              </>
                            )}
                            <style jsx>{`
                              .tags:hover .tooltip {
                                visibility: visible;
                                opacity: 1;
                              }

                              .tags .tooltip {
                                position: absolute;
                                z-index: 1;
                                visibility: hidden;
                                opacity: 0;
                                background-color: #000;
                                color: #fff;
                                padding: 5px;
                                border-radius: 5px;
                                font-size: 12px;
                                white-space: pre-wrap;
                                text-align: left;
                                bottom: 110%;
                                left: 50%;
                                transform: translateX(-50%);
                                transition: visibility 0.3s, opacity 0.3s;
                              }
                            `}</style>
                          </TableCell>

                          <TableCell
                            align='left'
                            component='th'
                            scope='row'
                            sx={{
                              fontFamily: "'Fredoka', sans-serif",
                              '&:hover': {
                                borderLeft: '4px solid #3B89FE',
                              },
                              width: '40%',
                              wordBreak: 'break-word',
                            }}
                            title={row.article}
                            onClick={(event) => {
                              event.preventDefault();
                              setChannelSelectedId(row.channelId);
                              setBlacklisted(row.blackList);
                              setChannelSelectedUserName(row.username);
                              setDetailsRow(row);
                              setInputImportValue('');
                              setImportErrorMessage('');
                              console.log('row from table: ', row);
                              setBlackListPop(true);
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '600px',
                                display: 'block',
                                overflow: 'hidden',
                              }}
                            >
                              {row.article}
                            </div>
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              fontFamily: "'Fredoka', sans-serif",
                              width: '10%',
                              color:
                                row.unread_messages === 0
                                  ? '#2CE38B'
                                  : '#00ACFB',
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setChannelSelectedId(row.channelId);
                              setBlacklisted(row.blackList);
                              setChannelSelectedUserName(row.username);
                              setDetailsRow(row);
                              setInputImportValue('');
                              setImportErrorMessage('');
                              console.log('row from table: ', row);
                              setBlackListPop(true);
                            }}
                          >
                            {row.unread_messages === 0
                              ? 'All Read'
                              : row.unread_messages}
                            <span
                              style={{ color: 'black', fontWeight: 'normal' }}
                            >
                              /{row.messageCount}
                            </span>
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              fontFamily: "'Fredoka', sans-serif",
                              width: '20%',
                              textAlign: 'center',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setChannelSelectedId(row.channelId);
                              setBlacklisted(row.blackList);
                              setChannelSelectedUserName(row.username);
                              setDetailsRow(row);
                              setInputImportValue('');
                              setImportErrorMessage('');
                              console.log('row from table: ', row);
                              setBlackListPop(true);
                            }}
                          >
                            {/* <div>{row.region.slice(0,10)}</div><div>{row.region.slice(11,16)}</div> */}{' '}
                            <div>{formatDate(row.lastActive)}</div>
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              fontFamily: "'Fredoka', sans-serif",
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <a
                              href={`https://t.me/${row.username}`}
                              target='_blank'
                              rel='noreferrer'
                              title='Telegram Link'
                            >
                              <img
                                className='actionsIMG'
                                style={{ marginRight: '2px' }}
                                src={teleIcon}
                                alt='open'
                              />
                            </a>
                            <a
                              value={row._id}
                              // href={`http://localhost:3000/details/${row._id}`}
                              target='_blank'
                              style={{ marginLeft: '2px' }}
                              title='Entity Details, Leave or Whitelist/Blacklist channel'
                              rel='noreferrer'
                              onClick={(event) => {
                                event.preventDefault();
                                setChannelSelectedId(row.channelId);
                                setBlacklisted(row.blackList);
                                setChannelSelectedUserName(row.username);
                                setDetailsRow(row);
                                setInputImportValue('');
                                setImportErrorMessage('');
                                console.log('row from table: ', row);
                                setBlackListPop(true);
                              }}
                            >
                              <img
                                className='actionsIMG'
                                src={eye}
                                alt='actions'
                              />
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div
                  className='pagination_container'
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className='display_pages' style={{ marginLeft: '0px' }}>
                    Showing &nbsp;<span>{(page - 1) * det + 1}</span>-
                    <span>
                      {(page - 1) * det + parseInt(entries) < totCount
                        ? (page - 1) * det + parseInt(entries)
                        : totCount}
                    </span>
                    &nbsp;from&nbsp;<span>{totCount.toLocaleString()}</span>
                    &nbsp;Channels
                  </div>
                  <div className='nav_container'>
                    {console.log(pc)}
                    <Stack spacing={2}>
                      <Pagination
                        count={pc}
                        color='primary'
                        shape='rounded'
                        value={page}
                        page={page}
                        onChange={handlePage}
                        variant='outlined'
                        sx={{ fontFamily: "'Fredoka', sans-serif" }}
                      />
                    </Stack>
                  </div>
                  <div className='entries'>
                    <span style={{ marginRight: '8px' }}>
                      Number of Entries:
                    </span>
                    <select
                      style={{
                        backgroundColor: '#F7F9FB',
                        padding: '3px',
                        border: '2px solid #B7B7B7',
                        borderRadius: '5px',
                        outline: 'none',
                        color: '#B7B7B7',
                      }}
                      defaultValue={entries}
                      onChange={(e) => {
                        setEntries(e.target.value);
                        const t = handleFinalKeys();
                        setPage(1);
                        const message = {
                          pageNo: 1,
                          limitPerPage: e.target.value,
                          searchText: t.join(' '),
                          fromDate: fromDate,
                          toDate: toDate,
                          no_tag: noTag,
                        };
                        setLoading(true);
                        test_c_temp(e.target.value, message);
                        // test_c(message);
                      }}
                    >
                      <option value='10' style={{ color: 'black' }}>
                        10
                      </option>
                      <option value='20' style={{ color: 'black' }}>
                        20
                      </option>
                      <option value='60' style={{ color: 'black' }}>
                        60
                      </option>
                      <option value='80' style={{ color: 'black' }}>
                        80
                      </option>
                      <option value='100' style={{ color: 'black' }}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={loading ? loadingImg : noData} alt='' />
            </div>
          )}
        </div>
      </div>
      {showEditTagsPopup === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setShowEditTagsPopup(false);
          }}
        >
          <div
            className='TagsPopupMultiple'
            onClick={(e) => e.stopPropagation()}
          >
            <Box className='Box' sx={style_pop} id='keywordsAdd'>
              <div className='keywordsModal'>
                <div className='centerHead'>Edit Tags</div>
                <div className='subHeads'>Enter Tags</div>
                <div className='searchBar'>
                  <input
                    type='text'
                    value={key}
                    onChange={handleChange}
                    className='keySearch'
                    placeholder='Drugs, Data, etc'
                    onKeyPress={(event) =>
                      event.key === 'Enter' && handleAddKeys(event, key)
                    }
                  />
                  <button
                    onClick={(event) => handleAddKeys(event, key)}
                    className='addKeywordBtn'
                  >
                    Add{' '}
                  </button>
                </div>
                <div className='allKeys'>
                  <div className='subHeads'>Tags</div>
                  <div>Click on the tag boxes to remove the selected tags</div>
                  <div className='keyList'>
                    {selectedKeys &&
                      selectedKeys.map((key) => (
                        <div
                          onClick={(event) => handleRemoveKeys(event, { key })}
                          className='keys'
                        >
                          {key}
                        </div>
                      ))}
                  </div>
                </div>
                <div className='btnContainer'>
                  <button
                    onClick={(event) => {
                      handleUpdateTags(event);
                      setShowEditTagsPopup(false);
                    }}
                    className='modalBtnConfirm'
                  >
                    Confirm
                  </button>
                  <button
                    onClick={(event) => {
                      setShowEditTagsPopup(false);
                    }}
                    className='modalBtnConfirm'
                  >
                    Close
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <></>
      )}
      {blackListPopUp === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setBlackListPopUp(false);
          }}
        >
          <div
            className=''
            style={{ borderRadius: '10px', padding: 0 }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box className='' sx={style_pop} id='keywordsAdd'>
              <div>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#223036',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Are you sure you want to blacklist this channel?
                </span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      cursor: 'pointer',
                      margin: '0px 10px',
                      width: 'fit',
                      color: 'white',
                      backgroundColor: '#00ACFB',
                      borderRadius: '10px',
                      padding: '1% 4%',
                    }}
                    onClick={() => {
                      handleBlackList();
                      setBlackListPopUp(!blackListPopUp);
                    }}
                  >
                    Yes
                  </div>
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      cursor: 'pointer',
                      margin: '0px 10px',
                      width: 'fit',
                      backgroundColor: 'white',
                      border: '1px solid #00ACFB',
                      borderRadius: '10px',
                      color: '#00ACFB',
                      padding: '1% 4%',
                    }}
                    onClick={() => {
                      setBlackListPopUp(!blackListPopUp);
                    }}
                  >
                    No
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <></>
      )}
      {filterPopUp === true ? (
        <div
          className='filterBackdropModalChannels'
          onClick={() => {
            setFilterPopUp(false);
          }}
        >
          <div className='filterbox' onClick={(e) => e.stopPropagation()}>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 600,
                color: '#223036',
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Filter
            </span>
            <div
              style={{
                margin: '10px 10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <input
                type='checkbox'
                checked={noTag}
                style={{
                  marginRight: '5px',
                  border: '2px solid #898F9A',
                  borderRadius: '5px',
                  height: '18px',
                  width: '18px',
                  alignItems: 'center',
                }}
                onClick={({ target }) => {
                  setNoTag(!noTag);
                  localStorage.setItem('checked', !noTag);
                }}
              />
              <span>Show Channels with 0 tags</span>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  border: '2px solid #00ACFB',
                  borderRadius: '10px',
                  padding: '2% 7%',
                  color: 'white',
                  margin: '10px',
                  fontSize: '16px',
                  backgroundColor: '#00ACFB',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setFilterPopUp(!filterPopUp);
                  finalSubmit();
                }}
              >
                Apply
              </div>
              <div
                style={{
                  border: '1px solid #00ACFB',
                  padding: '2% 7%',
                  backgroundColor: 'white',
                  color: '#00ACFB',
                  borderRadius: '10px',
                  fontSize: '16px',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                onClick={() => setFilterPopUp(!filterPopUp)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showEditTagsPopup2 === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setShowEditTagsPopup2(false);
          }}
        >
          <div
            className='TagsPopupMultiple'
            onClick={(e) => e.stopPropagation()}
          >
            <Box className='Box' sx={style_pop} id='keywordsAdd'>
              <div className='keywordsModal'>
                <div className='centerHead'>
                  Edit Tags for the selected channels
                </div>
                <div className='subHeads'>Enter Tags</div>
                {selectedMessages.length > 10 ||
                (selectedQuery && totCount > 10) ? (
                  <div
                    style={{
                      paddingTop: '1%',
                      color: '#E9D502',
                      fontSize: 'small',
                    }}
                  >
                    Warning: You are about to add tags to{' '}
                    {selectedMessages.length > 0
                      ? selectedMessages.length
                      : selectedQuery
                      ? totCount
                      : 0}{' '}
                    channels, and it cant be reversed in bulk!{' '}
                  </div>
                ) : (
                  <></>
                )}
                <div className='searchBar'>
                  <input
                    type='text'
                    value={key}
                    onChange={handleChange}
                    className='keySearch'
                    placeholder='Drugs, Data, etc'
                    onKeyPress={(event) =>
                      event.key === 'Enter' && handleAddKeysMultiple(event, key)
                    }
                  />
                  <button
                    onClick={(event) => handleAddKeysMultiple(event, key)}
                    className='addKeywordBtn'
                  >
                    Add
                  </button>
                </div>
                <div className='allKeys'>
                  <div className='subHeads'>Tags</div>
                  <div>Click on the tag boxes to remove the selected tags</div>
                  <div className='keyList'>
                    {selectedKeys.map((key) => (
                      <div
                        onClick={(event) => handleRemoveKeys(event, { key })}
                        className='keys'
                      >
                        {key}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='btnContainer'>
                  <button
                    onClick={(event) => {
                      handleUpdateTagsMultiple(event);
                      setShowEditTagsPopup2(false);
                    }}
                    className='modalBtnConfirm'
                  >
                    Confirm
                  </button>
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      setShowEditTagsPopup2(false);
                    }}
                    className='modalBtnConfirm'
                  >
                    Close
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <></>
      )}
      {exportedPop === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setExportedPop(false);
          }}
        >
          <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
            <Box className='Box' sx={style} id='keywordsAdd'>
              <div className='keywordsModal'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '3%',
                    justifyContent: 'space-between',
                  }}
                >
                  <h5>
                    Are you sure you want to export the selected{' '}
                    {selectedMessages.length > 0
                      ? selectedMessages.length
                      : totCount}{' '}
                    channels?
                  </h5>
                  <button
                    style={{
                      maxWidth: 'fit-content',
                      background: 'none',
                      color: '#000000',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setExportedPop(false);
                    }}
                  >
                    x
                  </button>
                </div>
                <div style={{ width: '100%', paddingBottom: '4%' }}>
                  <input
                    style={{ width: '100%', height: '4vh' }}
                    type='text'
                    value={exportNote}
                    onChange={handleExportChange}
                    className='keySearch'
                    placeholder='Add note to your exported file (Optional)'
                    onKeyPress={(event) =>
                      event.key === 'Enter' && handleExport(event)
                    }
                  />
                </div>
                <div className='btnContainer'>
                  <button
                    style={{
                      minWidth: 'fit-content',
                      fontSize: 'large',
                      height: 'fit-content',
                      paddingBlock: '1%',
                    }}
                    onClick={(event) => {
                      setExportedPop(false);
                      handleExport(event);
                    }}
                    className='modalBtnConfirm'
                  >
                    Yes
                  </button>
                  <button
                    style={{
                      minWidth: 'fit-content',
                      fontSize: 'large',
                      height: 'fit-content',
                      paddingBlock: '1%',
                    }}
                    onClick={(event) => {
                      setExportedPop(false);
                      setExportNote('');
                    }}
                    className='modalBtnConfirm'
                  >
                    No
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <></>
      )}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ width: '17%', fontWrap: 'none', textAlign: 'left' }}
      />
      {selectAllPopup === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setSelectAllPopup(false);
          }}
        >
          <div className='TagsPopup' onClick={(e) => e.stopPropagation()}>
            <Box className='Box' sx={style} id='keywordsAdd'>
              <div className='keywordsModal'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '3%',
                    justifyContent: 'space-between',
                  }}
                >
                  <h5>Please select one of the following option</h5>
                  <button
                    style={{
                      maxWidth: 'fit-content',
                      background: 'none',
                      color: '#000000',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectAllPopup(false);
                    }}
                  >
                    x
                  </button>
                </div>

                <div className='btnContainer'>
                  <button
                    style={{
                      minWidth: 'fit-content',
                      fontSize: 'large',
                      height: 'fit-content',
                      paddingBlock: '1%',
                    }}
                    onClick={(event) => {
                      setSelectAllPopup(false);
                      handleSelectCurrent(event);
                    }}
                    className='modalBtnConfirm'
                  >
                    Current Page
                  </button>
                  <button
                    style={{
                      minWidth: 'fit-content',
                      fontSize: 'large',
                      height: 'fit-content',
                      paddingBlock: '1%',
                    }}
                    onClick={(event) => {
                      setSelectAllPopup(false);
                      handleSelectThroughAll(event);
                    }}
                    className='modalBtnConfirm'
                  >
                    All Pages
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <></>
      )}

      {importMessagesPop === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setShowEditTagsPopup(false);
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()}
            className='Box'
            style={{ width: '35%', padding: '20px' }}
            sx={style_pop}
          >
            <div className='keywordsModal'>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: 'x-large',
                }}
              >
                {detailsRow.nameOriginal}
              </div>
              <div style={{ display: 'flex' }}>
                {messageImportData &&
                messageImportData.expected_messages_count ? (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '1%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={InstructionEye}
                      style={{ marginRight: '5px' }}
                      onMouseEnter={() => setinstructionPopUp(true)}
                      onMouseLeave={() => setinstructionPopUp(false)}
                    />
                    <span
                      style={{
                        fontSize: 'medium',
                        fontWeight: 'bold',
                        marginRight: '0.5%',
                        fontFamily: "'Roboto','sans-serif'",
                        color: '#223036',
                      }}
                    >
                      All Messages:{' '}
                    </span>
                    <span
                      style={{
                        fontSize: 'medium',
                        fontFamily: "'Roboto','sans-serif'",
                      }}
                    >
                      {messageImportData.expected_messages_count}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {/* {detailsRow.unread_messages ?
								<div style={{width:'100%',marginTop:'1%'}}>
									<span style={{fontSize:'medium', fontWeight:'bold', marginRight:'0.5%', fontFamily:"'Roboto','sans-serif'", color:'#223036'}}>Unread Messages: </span>
									<span style={{ fontSize:'medium', fontFamily:"'Roboto','sans-serif'"}}>{detailsRow.unread_messages}</span>
								</div>:<></>} */}
                {detailsRow.messageCount > -1 ? (
                  <div style={{ width: '100%', marginTop: '1%' }}>
                    <span
                      style={{
                        fontSize: 'medium',
                        fontWeight: 'bold',
                        marginRight: '0.5%',
                        fontFamily: "'Roboto','sans-serif'",
                        color: '#223036',
                      }}
                    >
                      Existing Total Messages:{' '}
                    </span>
                    <span
                      style={{
                        fontSize: 'medium',
                        fontFamily: "'Roboto','sans-serif'",
                      }}
                    >
                      {detailsRow.messageCount}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {instructionPopUp === true ? (
                <div
                  style={{
                    width: 'fit',
                    position: 'absolute',
                    backgroundColor: '#F7F9FB',
                    padding: '1%',
                    borderRadius: '10px',
                    fontSize: '12px',
                    boxShadow: '0px 0px 20px 6px #00000024',
                  }}
                  onClick={() => {
                    setinstructionPopUp(false);
                  }}
                >
                  <div
                    className=''
                    style={{ borderRadius: '10px', padding: 0, width: 'fit' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div style={{ width: '200px', textAlign: 'center' }}>
                      The expected message count corresponds to total number of
                      events, including updates on profile picture and
                      description and joining events. However, the actual
                      imported message count may be lower.
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {messageImportData &&
              messageImportData.expected_messages_count &&
              messageImportData.expected_messages_count > 100000 ? (
                <div
                  style={{
                    fontSize: 'small',
                    width: '100%',
                    textAlign: 'center',
                    color: '#ffcc00',
                    marginTop: '2%',
                    marginBottom: '-2%',
                  }}
                >
                  (It may take a bit if importing more than 1,00,000 messages)
                </div>
              ) : (
                <></>
              )}
              {messageImportData &&
              messageImportData.expected_messages_count ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginTop: '2%',
                    gap: '2%',
                    justifyContent: 'center',
                  }}
                >
                  <div>Import Last :</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0px',
                      width: '50%',
                    }}
                  >
                    <input
                      type='number'
                      value={inputImportValue}
                      onChange={handleImportInputChange}
                      style={{
                        border: '1px solid black',
                        padding: '5px',
                        outline: 'none',
                      }}
                    />
                    {importErrorMessage && (
                      <div style={{ color: 'red' }}>{importErrorMessage}</div>
                    )}
                  </div>
                  <div>messages.</div>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '3%',
                  }}
                >
                  Import all messages from the channel?
                </div>
              )}
              <div
                className='finalising_div'
                style={{ marginTop: '2vh', gap: '3%' }}
              >
                {messageImportData &&
                messageImportData.expected_messages_count ? (
                  <button
                    className='btnCreate'
                    onClick={(event) => {
                      setBlackListPop(false);
                      setInputImportValue('');
                      setImportMessagesPop(false);
                      handle_channel_join();
                      setMessageImportData({});
                    }}
                  >
                    Import Messages
                  </button>
                ) : (
                  <button
                    className='btnCreate'
                    onClick={(event) => {
                      setBlackListPop(false);
                      setInputImportValue('');
                      setImportMessagesPop(false);
                      handle_channel_join_all();
                      setMessageImportData({});
                    }}
                  >
                    Import Messages
                  </button>
                )}
                <button
                  className='btnCancel'
                  onClick={(event) => {
                    setInputImportValue('');
                    setImportMessagesPop(false);
                    setMessageImportData({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}

      {blackListPop === true ? (
        <div
          className='backdropModal'
          onClick={() => {
            setBlackListPop(false);
          }}
          key={Math.random()}
        >
          <Box
            onClick={(e) => e.stopPropagation()}
            className='Box'
            style={{ width: '46%', padding: '20px' }}
            sx={style_pop}
          >
            <div className='keywordsModal'>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: 'x-large',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '300px',
                      display: 'block',
                      overflow: 'hidden',
                    }}
                  >
                    {detailsRow.nameOriginal}
                  </div>

                  {detailsRow.username ? '' : '(PRIVATE)'}
                  {!detailsRow.is_pinned ? (
                    <img
                      src={Greenpin}
                      title='Click to Pin the Channel'
                      style={{ margin: '0px 10px', cursor: 'pointer' }}
                      onClick={async () => {
                        let payload = [];
                        payload.push(detailsRow.channelId);
                        const response = await pin(payload);
                        console.log(response);
                        setpinned(true);
                        toast.success('Entity Pinned Successfully', {
                          position: 'top-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: 'light',
                        });

                        const t = handleFinalKeys();
                        const message = {
                          pageNo: page,
                          limitPerPage: entries,
                          searchText: t.join(' '),
                          sortField: sortField,
                          sortDirection: sortDirection,
                          // "flag": dateFlag,
                          fromDate: fromDate,
                          toDate: toDate,
                          no_tag: noTag,
                        };

                        test_c(message);
                        setBlackListPop(false);
                      }}
                    />
                  ) : (
                    <img
                      src={Pinned}
                      title='Pinned Channel'
                      style={{ margin: '0px 10px', cursor: 'pointer' }}
                      onClick={async () => {
                        let payload = [];
                        payload.push(detailsRow.channelId);
                        const response = await unpin(payload);
                        console.log(response);
                        setDetailsRow(detailsRow);
                        setpinned(false);
                        toast.success('Entity Unpinned Successfully', {
                          position: 'top-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: 'light',
                        });

                        const t = handleFinalKeys();
                        const message = {
                          pageNo: page,
                          limitPerPage: entries,
                          searchText: t.join(' '),
                          sortField: sortField,
                          sortDirection: sortDirection,
                          // "flag": dateFlag,
                          fromDate: fromDate,
                          toDate: toDate,
                          no_tag: noTag,
                        };

                        test_c(message);
                        setBlackListPop(false);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <button
                    style={{
                      width: '20%',
                      border: '1px solid #00ACFB',
                      margin: '0px 10px',
                      backgroundColor: 'white',
                      color: '#00ACFB',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontWeight: 500,
                      cursor: 'pointer',
                    }}
                    onClick={(event) => {
                      setBlackListPopUp(!blackListPopUp);
                      setBlackListPop(false);
                    }}
                  >
                    {blacklisted === 'WHITELISTED' ? 'Blacklist' : 'Whitelist'}
                  </button>
                  <button
                    style={{
                      width: '40%',
                      border: '1px solid #00ACFB',
                      backgroundColor: 'white',
                      color: '#00ACFB',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontWeight: 500,
                      cursor: 'pointer',
                    }}
                    disabled={messageDetailsLoading}
                    onClick={(event) => {
                      setMessageDetailsLoading(true);
                      handleMessageData();
                    }}
                  >
                    {messageDetailsLoading ? 'Loading...' : 'Import Messages'}
                  </button>
                </div>
              </div>
              {detailsRow?.is_banned_or_removed === true ? (
                <div style={{ color: 'red' }}>
                  <span>(BANNED / REMOVED)</span>
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: '',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',

                    flexDirection: 'row',
                    alignItems: 'start',
                    alignContent: 'start',
                    margin: '5px 0px',
                    marginRight: '60px',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginRight: '65px',
                      }}
                    >
                      Created on
                    </span>
                  </div>
                  <span style={{ marginRight: '15px' }}>:</span>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {formatDate(detailsRow.lastActive)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',

                    flexDirection: 'row',
                    alignItems: 'start',
                    alignContent: 'start',
                    margin: '5px 0px',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginRight: '20px',
                      }}
                    >
                      Participants Count
                    </span>
                  </div>
                  <span style={{ marginRight: '10px' }}>:</span>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {detailsRow.participantCount}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',

                  flexDirection: 'row',
                  alignItems: 'start',
                  alignContent: 'start',
                  margin: '5px 0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      marginRight: '70px',
                    }}
                  >
                    Username
                  </span>
                </div>
                <span style={{ marginRight: '15px' }}>:</span>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {detailsRow.username}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',

                  flexDirection: 'row',
                  alignItems: 'start',
                  alignContent: 'start',
                  margin: '5px 0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      marginRight: '20px',
                    }}
                  >
                    Joined by Clients
                  </span>
                </div>
                <span style={{ marginRight: '15px' }}>:</span>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {detailsRow?.clients ?? 'No clients available'}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: '',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',

                    flexDirection: 'row',
                    alignItems: 'start',
                    alignContent: 'start',
                    margin: '5px 0px',
                    marginRight: '60px',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginRight: '85px',
                      }}
                    >
                      Entity ID
                    </span>
                  </div>
                  <span style={{ marginRight: '15px' }}>:</span>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {detailsRow.channelId}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',

                    flexDirection: 'row',
                    alignItems: 'start',
                    alignContent: 'start',
                    margin: '5px 0px',
                    justifyContent: 'start',
                    marginLeft: '110px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginRight: '70px',
                      }}
                    >
                      Entity Type
                    </span>
                  </div>
                  <span style={{ marginRight: '15px' }}>:</span>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {detailsRow.isChannel
                      ? 'Channel'
                      : detailsRow.isGroup
                      ? 'Group'
                      : detailsRow.isUser
                      ? 'User'
                      : 'Not Clear'}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',

                  flexDirection: 'row',
                  alignItems: 'start',
                  alignContent: 'start',
                  margin: '5px 0px',
                  justifyContent: 'start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      marginRight: '63px',
                    }}
                  >
                    Description
                  </span>
                </div>
                <span style={{ marginRight: '15px' }}>:</span>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {detailsRow.article}
                </p>
              </div>

              {(detailsRow.keyword && detailsRow.keyword.length) > 0 ? (
                <div style={{ width: '100%', marginTop: '1%' }}>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontWeight: 'bold',
                      marginRight: '0.5%',
                      fontFamily: "'Roboto','sans-serif'",
                      color: '#223036',
                      marginRight: '108px',
                    }}
                  >
                    Tags{' '}
                  </span>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontFamily: "'Roboto','sans-serif'",
                    }}
                  >
                    {' '}
                    :
                    {detailsRow.keyword &&
                      detailsRow.keyword.map((key) => (
                        <span className='keys' style={{ cursor: 'default' }}>
                          {key}
                        </span>
                      ))}
                  </span>
                </div>
              ) : (
                <></>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: '',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',

                    flexDirection: 'row',
                    alignItems: 'start',
                    alignContent: 'start',
                    margin: '5px 0px',
                    marginRight: '60px',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginRight: '35px',
                      }}
                    >
                      Total Messages
                    </span>
                  </div>
                  <span style={{ marginRight: '15px' }}>:</span>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {detailsRow.messageCount}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',

                    flexDirection: 'row',
                    alignItems: 'start',
                    alignContent: 'start',
                    margin: '5px 0px',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginRight: '28px',
                        marginLeft: '170px',
                      }}
                    >
                      Unread Messages
                    </span>
                  </div>
                  <span style={{ marginRight: '10px' }}>:</span>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {detailsRow.unread_messages}
                  </p>
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontWeight: 'bold',
                      marginRight: '0.5%',
                      fontFamily: "'Roboto','sans-serif'",
                      color: '#223036',
                    }}
                  >
                    Fake:{' '}
                  </span>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontFamily: "'Roboto','sans-serif'",
                    }}
                  >
                    {detailsRow.fake ? 'Yes' : 'No'}
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontWeight: 'bold',
                      marginRight: '0.5%',
                      fontFamily: "'Roboto','sans-serif'",
                      color: '#223036',
                    }}
                  >
                    Scam:{' '}
                  </span>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontFamily: "'Roboto','sans-serif'",
                    }}
                  >
                    {detailsRow.scam ? 'Yes' : 'No'}
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontWeight: 'bold',
                      marginRight: '0.5%',
                      fontFamily: "'Roboto','sans-serif'",
                      color: '#223036',
                    }}
                  >
                    Verified:{' '}
                  </span>
                  <span
                    style={{
                      fontSize: 'medium',
                      fontFamily: "'Roboto','sans-serif'",
                    }}
                  >
                    {detailsRow.verified ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
              <div
                className='finalising_div'
                style={{ marginTop: '2vh', gap: '3%' }}
              >
                <button
                  className='btnCreate'
                  onClick={(event) => {
                    setBlackListPop(false);
                    handleLeave();
                  }}
                >
                  Leave Channel
                </button>

                <button
                  className='btnCancel'
                  onClick={(event) => {
                    setBlackListPop(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
const top100Films = [
  'nike',
  'puma',
  'woodland',
  'gucci',
  'versace',
  'Combo lists',
  'Combolists',
  'ransomware',
  'Config logs',
  'hacking instructions',
  'Cracked tools',
  'Data leaks',
  'DDOS attack information',
  'Download files of leaked data',
  'Download files with crypto logs',
  'Download leaked files',
  'Free cloud logs',
  'link',
  'db',
];

import React from 'react';
import { Dashleft } from '../components/dashleft';
import { Analytics } from '../components/analytics';
import { AnalyticsNew } from '../components/analyticsNew';

export const Home = () => {
  return (
    <div className="Home">
      <Dashleft id={0} />
      <AnalyticsNew />
    </div>
  );
};

import axios from 'axios'
import instance from './interceptor';


export const messages_from_monitor_notification = async(data)=>{

  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders
  };
  console.log(data);
 
  let res = []
    await instance.post(`${apiUrl}/api/messages_from_monitor_notification`,data, config)
    .then(response=>{
        res = response.data;
        console.log(response.data)
    })
    .catch(error=>{
      console.log(error)
    })
    console.log(res);
    return res;

  }
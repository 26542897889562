import React from 'react';
import { Dashleft } from '../components/dashleft';
import { MessageTable } from '../components/messages_table';

export const Messages = () => {
  return (
    <div className='dashboard'>
      <Dashleft id={2} />
      <MessageTable />
    </div>
  );
};

import React from 'react';
import { Dashboard } from './pages/dashboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Messages } from './pages/message';
import { Login } from './pages/login.js';
import { Home } from './pages/Home.js';
import { Discover } from './pages/discover';
import { MessageMonitor } from './pages/message_monitor';
import { Notification } from './pages/notification';
import { Setting } from './pages/settings';

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/discover' element={<Discover />} />
        <Route path='/channels' element={<Dashboard />} />
        {/* <Route path="/details/:id" element={<Channels />} /> */}
        <Route exact path='/messages/' element={<Messages />} />
        <Route path='/message_monitor' element={<MessageMonitor />} />
        <Route path='/notifications' element={<Notification />} />
        <Route path='/settings' element={<Setting />} />
        {/* <Route path="/message_details/:id" element={<MessageDet />} /> */}
        <Route path='/' element={<Login />} />
      </Routes>
    </Router>
  );
};

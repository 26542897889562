import axios from 'axios';
import instance from './interceptor';

export const message_call = async (data) => {
  const apiHeaders = JSON.parse(process.env.REACT_APP_API_HEADERS);
  const apiUrl = process.env.REACT_APP_API_URL;

  const config = {
    headers: apiHeaders,
  };

  let res = [];
  console.log(data);
  console.log('hiting endpoint3');
  await instance
    .post(`${apiUrl}/api/messages`, data, config)
    .then((response) => {
      console.log('hit');
      res = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  console.log(res);
  return res;
};

// import axios from 'axios'
// export const message_call = async(data)=>{

//   const config = {
//     headers:{
//       'Access-Control-Allow-Origin': '*',
//       'Content_Type': 'application/json'
//     }
//   }
//   let res = []
//   console.log(data);
//   if(data.tags && (data.tags).length !== 0){
//     await axios.post('http://localhost:5000/messages/filters', data, config)
//     .then(response=>{
//         res = response.data;
//         //console.log(response.data)
//     })
//     .catch(error=>{
//       console.log(error)
//     })
//   }else{
//     await axios.post('http://localhost:5000/messages', data, config)
//       .then(response=>{
//           res = response.data;
//          // console.log(response.data)
//       })
//       .catch(error=>{
//         console.log(error)
//       })
//     }
//     return res;

//   }
